import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

/* Components */
import MetricsView from './Metrics.view';
const getMetrics = window.firebase.functions().httpsCallable('getTodaysMetrics');

function MetricsContainer(props) {
  const {
    match: {
      params: {
        location_id,
      },
    },
  } = props;
  // REDUX STATE
  const state = useSelector(state => ({
    user: state.user,
  }));

  // STATE 
  const [ rawLocationsData, setRawLocationsData ] = useState([]);
  const [ locations, setLocations ] = useState([]);
  const [ displayLocationTable, toggleDisplayLocationTable ] = useState(false);
  const [ historicalData, setHistoricalData ] = useState([]);
  const [ chosenLocation, setChosenLocation ] = useState('');
  const [ chosenLocationId, setChosenLocationId ] = useState('');
  const [ chosenDate, setChosenDate ] = useState(moment());
  const [ displayTodayTable, setDisplayTodayTable ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  

  // FUNCTIONS
  const findCapacityNumbers = (locations) => {
    let underCapacity = 0;
    let atCapacity = 0;
    for (const location of locations) {
      if (location.population < location.capacity) {
        underCapacity++;
      } else if (location.population === location.capacity) {
        atCapacity++;
      }
    }
    return {
      underCapacity,
      atCapacity,
    };
  };

  const doLogsExist = async (locationId) => {
    return await window.firebase.firestore()
      .collection(`populationLogs/${locationId}/logs`)
      .get()
      .then(async (snapshot) => {
        // snapshot.exists is broken on subcollections I think; thats why I am checking length of the snapshot 
        if (snapshot.docs.length) {
          return true;
        }
        return false;
      })
      .catch(e => console.log(e));
  };

  const getTodaysData = async (location) => {
    setLoading(true);
    setRawLocationsData([]);
    await getMetrics(location.location_id).then((res) => {
      console.log('res:', res);
      const updatedLocation = {
        ...location,
        ...res.data,
      };
      console.log('updatedLocation:', updatedLocation);
      setLoading(false);
      setRawLocationsData(rawLocationsData => [ ...rawLocationsData, updatedLocation ]);
    })
      .catch((e) => {
        console.log('error getting metrics:', e);
        setLoading(false);
        setRawLocationsData([]);
        setDisplayTodayTable(false);
      });
  };

  const handleDateChange = (newDate) => {
    console.log('date', newDate);
    setChosenDate(newDate);
    return handleHistoricalTableOpen(chosenLocationId, chosenLocation, newDate);
  };

  const handleHistoricalTableOpen = async (locationId, locationName, date = moment()) => {
    setLoading(true);
    setChosenLocation(locationName);
    setChosenLocationId(locationId);
    toggleDisplayLocationTable(true);
    const weeklyPlusData = await getHistoricalPlusAmounts(locationId, date);
    const weeklyQueueData = await getHistoricalQueueAmounts(locationId, date);
    const weeklyQueueArchiveData = await getHistoricalQueueArchiveAmounts(locationId, date);
    // console.log('*****', weeklyPlusData, weeklyQueueData, weeklyQueueArchiveData);
    // https://stackoverflow.com/questions/19480008/javascript-merging-objects-by-id
    const placeholder = {};
    weeklyPlusData.concat(weeklyQueueArchiveData.length === 0 ? weeklyQueueData : weeklyQueueArchiveData).forEach((obj) => {
      placeholder[obj.day] = Object.assign(placeholder[obj.day] || {}, obj);
    });
    const mergedWeeklyData = Object.keys(placeholder).map((key) => {
      return placeholder[key];
    });
    // display days in order
    const sortedData = mergedWeeklyData.sort((a, b) => {
      return a.day.split(' ')[2] - b.day.split(' ')[2];
    });
    setHistoricalData(sortedData);
    setLoading(false);
  };

  const getHistoricalPlusAmounts = async (locationId, date) => {
    // const today = moment();
    console.log('date *****', date);
    return await window.firebase.firestore().collection(`populationLogs/${locationId}/logs`)
      .get()
      .then(async (snapshot) => {
        const rawLogs = [];
        for (const log of snapshot.docs) {
          const rawTimestamp = log.data().timestamp ? log.data().timestamp.toDate() : log.data().created_timestamp.toDate();
          if (moment(rawTimestamp).isSame(date, 'week') && log.data().amount > 0) {
            const dayData = {
              id: log.id,
              amount: log.data().amount,
              day: moment(rawTimestamp).valueOf(),
              date: rawTimestamp,
            };
            rawLogs.push(dayData);
          }
        }
        const weeklyPlusData = rawLogs.reduce((acc, curr) => {
          const daysOfWeek = [ ...new Set(acc.map(log => log.day)) ];
          if (!daysOfWeek.includes(moment(curr.day).format('dddd MMM DD'))) {
            acc.push({
              day: moment(curr.day).format('dddd MMM DD'),
              totalInByPlus: curr.amount,
            });
          } else {
            const objIndex = acc.findIndex((obj => obj.day === moment(curr.day).format('dddd MMM DD')));
            acc[objIndex].totalInByPlus += curr.amount;
          }
          return acc;
        }, []);

        // console.log('weeklyPlusData', weeklyPlusData);
        return weeklyPlusData;
      })
      .catch(e => console.log('error getting log:', e));
  };

  const getHistoricalQueueAmounts = async (locationId, date) => {
    // const today = moment();
    return await window.firebase.firestore().collection(`queues/${locationId}/guests`)
      .get()
      .then((snapshot) => {
        const rawLogs = [];
        for (const log of snapshot.docs) {
          const checkedInAt = log.data().checked_in;
          if (moment(checkedInAt).isSame(date, 'week')) {
            // totalJoinedIn += log.data().party_size;
            const dayData = {
              id: log.id,
              amount: log.data().party_size,
              day: log.data().checked_in,
              date: moment(log.data().checked_in).format('dddd MMM DD'),
            };
            rawLogs.push(dayData);
          }
        }
        const weeklyQueueData = rawLogs.reduce((acc, curr) => {
          const daysOfWeek = [ ...new Set(acc.map(log => log.day)) ];
          if (!daysOfWeek.includes(moment(curr.day).format('dddd MMM DD'))) {
            acc.push({
              day: moment(curr.day).format('dddd MMM DD'),
              totalInByQueue: curr.amount,
            });
          } else {
            const objIndex = acc.findIndex((obj => obj.day === moment(curr.day).format('dddd MMM DD')));
            acc[objIndex].totalInByQueue += curr.amount;
          }
          return acc;
        }, []);

        // console.log('weeklyQueueData', weeklyQueueData);
        return weeklyQueueData;
        // return totalJoinedIn;
      })
      .catch(e => console.log('error getting log:', e));
  };
  const getHistoricalQueueArchiveAmounts = async (locationId, date) => {
    // const today = moment();
    // const today = moment('10/05/2020', 'MM/DD/YYYY');
    return await window.firebase.firestore().collection(`queuesArchive/${locationId}/guests`)
      .get()
      .then((snapshot) => {
        const rawLogs = [];
        for (const log of snapshot.docs) {
          const checkedInAt = log.data().checked_in;
          if (moment(checkedInAt).isSame(date, 'week')) {
            const dayData = {
              id: log.id,
              amount: log.data().party_size,
              day: log.data().checked_in,
              date: moment(log.data().checked_in).format('dddd MMM DD'),
            };
            rawLogs.push(dayData);
          }
        }
        const weeklyQueuesArchiveData = rawLogs.reduce((acc, curr) => {
          const daysOfWeek = [ ...new Set(acc.map(log => log.day)) ];
          if (!daysOfWeek.includes(moment(curr.day).format('dddd MMM DD'))) {
            acc.push({
              day: moment(curr.day).format('dddd MMM DD'),
              totalInByQueue: curr.amount,
            });
          } else {
            const objIndex = acc.findIndex((obj => obj.day === moment(curr.day).format('dddd MMM DD')));
            acc[objIndex].totalInByQueue += curr.amount;
          }
          return acc;
        }, []);
        return weeklyQueuesArchiveData;
      })
      .catch(e => console.log('error getting log:', e));
  };

  // LIFECYCLE METHODS
  useEffect(() => {
    console.log('**** user', state.user);
    const getAssociatedLocations = async () => {
      const currentUserRole = state.user.user_role;
      const locations = [];
      return await window.firebase.firestore().collection('locations')
        .get()
        .then(async (snapshot) => {
          switch (currentUserRole) {
            case 'brand_manager':
              for (const doc of snapshot.docs) {
                if (doc.data().brand_id === state.user.brand_id) {
                  locations.push(doc.data());
                }
              }
              break;
            // case 'client':
            //   for (const doc of snapshot.docs) {
            //     if (doc.data().brand_id === state.user.brand_id) {
            //       locations.push(doc.data());
            //     }
            //   }
            //   break;
            case 'regional_manager':
              for (const doc of snapshot.docs) {
                if (doc.data().region === state.user.region_id) {
                  locations.push(doc.data());
                }
              }
              break;
            case 'admin':
              for (const doc of snapshot.docs) {
                locations.push(doc.data());
              }
              break;
            default:
              for (const doc of snapshot.docs) {
                if (doc.id === state.user.location_id) {
                  locations.push(doc.data());
                }
              }
              break;
          }
          console.log('locations', locations);
          setLocations(locations);
        })
        .catch(e => console.log('error getting locations', e));
    };

    getAssociatedLocations();
  }, [ ]);

  return (
    <MetricsView
      // data
      capacityNumbers={findCapacityNumbers(locations)}
      rawLocationsData={rawLocationsData}
      chosenDate={chosenDate}
      displayLocationTable={displayLocationTable}
      historicalData={historicalData}
      chosenLocation={chosenLocation}
      locations={locations}
      displayTodayTable={displayTodayTable}
      loading={loading}
      userRole={state.user.user_role}
      selectedLocation={location_id}
      // methods
      handleDateChange={handleDateChange}
      toggleDisplayLocationTable={toggleDisplayLocationTable}
      handleHistoricalTableOpen={handleHistoricalTableOpen}
      getTodaysData={getTodaysData}
      setDisplayTodayTable={setDisplayTodayTable}
    />
  );
}

export default MetricsContainer;
