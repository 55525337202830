import React from 'react';
import PropTypes from 'prop-types';

import style from './_Admin.module.scss';

import {
  Layout,
  Icon,
} from 'antd';

function AdminHeader(props) {
  const {
    sidebarCollapsed,
    setSidebarCollapsed,
  } = props;


  function renderMenuClose() {
    if (!sidebarCollapsed) return (
      <div className={style.menu_open}>
                &nbsp;
      </div>
    );
    return (
      <div className={`${style.menu_open} ld_cursor_pointer`} onClick={() => setSidebarCollapsed(false)}>
        <Icon type="menu-unfold" />
      </div>
    );
  }

  return (
    <Layout.Header className={style.header}>

      {renderMenuClose()}

    </Layout.Header>
  );
}

AdminHeader.propTypes = {
  sidebarCollapsed: PropTypes.bool.isRequired,
  setSidebarCollapsed: PropTypes.func.isRequired,
};

export default AdminHeader;


