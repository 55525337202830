import React from 'react';

import JolHeaderLogo from 'common/jolHeaderLogo';
import style from './_Privacy.module.scss';

function PrivacyContainer() {
  return (
    <div className={style.containerWrapper} style={{ minHeight: window.innerHeight }}>
      <div className={style.container}>

        <div className={style.header}>
          {/* <JolHeaderLogo fill='black' />
           */}
          <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
        </div>

        <div className={style.mainContent}>
          <h1 style={{ textAlign: 'center' }}>Privacy Policy</h1>
          <p style={{ textAlign: 'center' }} >Last updated: June 16, 2020</p>
          <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
          <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.&nbsp;</p>
          <h1>Interpretation and Definitions</h1>
          <h2>Interpretation</h2>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <h2>Definitions</h2>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
            <li>
              <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Latitude Software Services, Inc. d/b/a Latitude Digital, Po Box 698, Erie, CO 80516.</p>
            </li>
            <li>
              <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
            </li>
            <li>
              <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
            </li>
            <li>
              <p><strong>Website</strong> refers to JoinOurLine, accessible from <a href="https://joinourline.com" target="_blank" rel="external nofollow noopener">https://joinourline.com</a></p>
            </li>
            <li>
              <p><strong>Service</strong> refers to the Website.</p>
            </li>
            <li>
              <p><strong>Country</strong> refers to: Colorado, United States</p>
            </li>
            <li>
              <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
            </li>
            <li>
              <p><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
            </li>
            <li>
              <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
            </li>
            <li>
              <p><strong>Cookies</strong> are small amounts of data that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
            </li>
            <li>
              <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
            </li>
            <li>
              <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
            </li>
          </ul>
          <h1>Collecting and Using Your Personal Data</h1>
          <h2>Types of Data Collected</h2>
          <h3>Personal Data</h3>
          <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information <strong>may</strong> include, but is not limited to:</p>
          <ul>
            <li>
              <p>Name</p>
            </li>
            <li>
              <p>Phone number</p>
            </li>
            <li>Once You are checked-in or Your time has expired, we delete all data.</li>
          </ul>
          <h3>Tracking Technologies and Cookies</h3>
          <p>We use Cookies and similar tracking technologies to connect You to Your activity on Our Service, we do not store information. Tracking technologies used are beacons, tags, and scripts to collect and track information. &nbsp;Once You are checked-in or Your time has expired, we delete all data.</p>
          <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.</p>
          <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.&nbsp;</p>
          <p>We use both session and persistent Cookies for the purposes set out below:</p>
          <ul>
            <li>
              <p><strong>Necessary / Essential Cookies</strong></p>
              <p>Type: Session Cookies</p>
              <p>Administered by: N/A</p>
              <p>Purpose: We do not use Session Cookies</p>
            </li>
            <li>
              <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: N/A</p>
              <p>Purpose: We do not use session cookies and do not require notice acceptance.</p>
            </li>
            <li>
              <p><strong>Functionality Cookies</strong></p>
              <p>Type: Persistent Cookies in local storage</p>
              <p>Administered by: Us</p>
              <p>Purpose: This storage allows us to keep Your place in line if You close Your browser and come back to the page.</p>
            </li>
          </ul>
          <h2>Use of Your Personal Data</h2>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li><strong>To contact You:</strong> To contact You via SMS to alert You that it is Your turn to enter. &nbsp;Once You are checked-in or Your time has expired, we delete all data.</li>
            <li><strong>NO OTHER PERSONAL DATA IS USED.</strong></li>
            <li><strong>NO PERSONAL DATA IS EVER SAVED.</strong></li>
          </ul>
          <h2>Retention of Your Personal Data</h2>
          <p>The Company will NOT retain Your Personal Data.</p>
          <h2>Transfer of Your Personal Data</h2>
          <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be temporarily transferred to &mdash; and maintained on &mdash; computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
          <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
          <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information. &nbsp;All data is destroyed as soon as You are checked-in or timed out.</p>
          <p>SMS providers may retain logs for compliance purposes.</p>
          <h2>Disclosure of Your Personal Data</h2>
          <h3>Business Transactions</h3>
          <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data will NOT be transferred. &nbsp;It is never retained.</p>
          <h3>Law enforcement</h3>
          <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). Once You are checked-in or Your time has expired, we delete all data.</p>
          <h3>Other legal requirements</h3>
          <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
            <li>Once You are checked-in or Your time has expired, we delete all data.</li>
          </ul>
          <h2>Security of Your Personal Data</h2>
          <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
          <h1>Your California Privacy Rights (California's Shine the Light law)</h1>
          <p>Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.</p>
          <p>If You'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below.</p>
          <p>We will always respond that we have nothing stored. &nbsp;Once You are checked-in or Your time has expired, we delete all data.</p>
          <h1>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</h1>
          <p>California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.</p>
          <p>To request removal of such data, and if You are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.</p>
          <p>Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</p>
          <h1>Links to Other Websites</h1>
          <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
          <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
          <h1>Changes to this Privacy Policy</h1>
          <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
          <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
          <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
          <h1>Contact Us</h1>
          <p>If You have any questions about this Privacy Policy, You can contact us:</p>
          <ul>
            <li>
              <p>By email: <a target='_blank' href='mailto:helpdesk@latitudedigital.com'>helpdesk@latitudedigital.com</a></p>
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
}

export default PrivacyContainer;
