import React from 'react';
import moment from 'moment-timezone';
import { Button } from '@progress/kendo-react-buttons';
import { Input, NumericTextBox, Switch } from '@progress/kendo-react-inputs';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Icon, Tooltip } from 'antd';

/* data management */
import PropTypes from 'prop-types';

/* components */
import JolHeaderLogo from 'common/jolHeaderLogo';
import InputWrapper from 'components/InputWrapper';
import style from './_AddLocation.module.scss';


function AddLocationView(props) {
  const {
    /* data & state */
    mapVisible,
    toggleMapVisible,
    loading,
    currentLocationId,
    locationIdExists,
    locationOpenTime,
    /* utils */
    form,
    /* methods */
    onSubmit,
    setLocalDirty,
    fields,
    processing,
    isDoorAdmin,
    renderError,
    updateLocationData,
    generateLocationId,
    setLocationOpenTime,
  } = props;

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  if (mapVisible) {
    return (
      <section className={style.container}>
        <section className={style.header_container}>
          <Button
            className={style.goback_btn}
            onClick={() => toggleMapVisible(false)}
          >
            Go Back
          </Button>
          <div className={style.header}>
            <JolHeaderLogo fill='black' className={style.header_logo}/>
          </div>
        </section>
        <section className={style.map_section} style={mapVisible ? null : { display: 'none' }}>
          <h4 className={style.map_header}>One More Step:</h4>
          <p className={style.map_text}>Please drag the marker to pinpoint your location on the map to calculate more accurate coordinates.</p>
          <div id='map-div' className={style.map} style={{ width: '400px', height: '350px' }}></div>
          <Button className={style.submit_map} onClick={() => updateLocationData()}>Submit Location</Button>
        </section>
      </section>
    );
  }

  return (
    <div className={style.container}>
      <section className={style.header_container}>
        <div className={style.header}>
          <JolHeaderLogo fill='black' className={style.header_logo}/>
        </div>
      </section>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={style.form_container}
      >
        <h3 className={style.welcome_text}>Welcome!<br />
        Please follow the steps below to create your location.<br />
        Once submitted, you will be taken to your Dashboard where you can begin using JOL.<br/>
        You can also edit the details of your location at any time from the Dashboard. </h3>
        <p style={{ width: '80%', marginLeft: '10%', textAlign: 'center', fontWeight: '300', color: 'black' }}>Please note that some values have been filled in for you as defaults, feel free to change them. <br /> * Also be sure to click on the '?' next to each input to view the description of that property.</p>
        <div className={style.formWrapper}>
          <div className={style.column}>
            <h3 className={style.column_heading}>Basic Information</h3>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The name of your location. ex: Main Street Grocery' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <InputWrapper
                component={<Input />}
                name="name"
                label="Location Name"
                form={form}
                defaultValue={fields.name}
                onChange={(e) => {
                  setLocalDirty(true);
                  generateLocationId(e.target.value);
                }}
              />
            </div>
            <div className={style.locationid_preview_container}>
              <Tooltip trigger='click' title="This preview displays how your location's name will be turned into your unique Location URL -  which guests can use to join your line directly. This URL cannot be changed once set.">
                <Icon type="question-circle" style={{ fontSize: '1.5rem', color: 'blue', marginRight: '.5rem' }}/>
              </Tooltip>
              <p className={style.locationid_text} >Your URL: <strong style={{ margin: 0, padding: 0, color: 'blue' }}>{`joinourline.com/join/${currentLocationId}`}</strong></p>
            </div>
            {locationIdExists ? <p className={style.locationid_error} ><u>{currentLocationId}</u>{` is taken, please choose a different name.`}</p> : null}
            <InputWrapper
              component={<Input />}
              name="address1"
              label="Address"
              form={form}
              defaultValue={fields.address ? fields.address.address1 : null}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="address2"
              label="Address Line 2"
              form={form}
              defaultValue={fields.address ? fields.address.address2 : null}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="city"
              label="City"
              form={form}
              defaultValue={fields.address ? fields.address.city : null}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="state"
              label="State"
              form={form}
              defaultValue={fields.address ? fields.address.state : null}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="zip_code"
              label="Zip Code"
              form={form}
              defaultValue={fields.address ? fields.address.zip_code : null}
              onChange={() => {
                setLocalDirty(true);
              }}
            />
          </div>

          <div className={style.column}>
            <h3 className={style.column_heading}>Location Information</h3>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' placement='top' title='The value in meters representing how far away your guests can be from your location before they are allowed to enter the line. Max: 1610m'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label
                className={style.grid_label}
                htmlFor={'distance'}
                style={{ display: isDoorAdmin ? 'none' : 'inherit' }}
              >
                Distance in meters (max: 1610m/1 mile)
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="distance"
                  defaultValue={805}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('distance');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('distance')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The total capacity of your business' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'capacity'}>
                Capacity
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="capacity"
                  defaultValue={fields.capacity}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('capacity');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('capacity')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Capacity-margin is an optional feature. When set to 0 the feature is disabled completely. When enabled (by adding a number greater than 0) the feature allows a location to call customers up immediately, without them having to join the line, until the number of people in the store is equal or greater to the store capacity minus capacity margin. So if a location has a capacity of 50, and a margin capacity of 10, customers will see a “Come on in” message until 40 people are in the store.' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'capacity_margin'}>
                Capacity Margin
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="capacity_margin"
                  defaultValue={0}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('capacity_margin');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('capacity_margin')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The maximum number of guests who can join the line.' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'line_limit'}>
                Line Limit
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="line_limit"
                  defaultValue={500}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('line_limit');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('line_limit')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The maximum number of people who can join in one party (representing one group waiting in line).' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'party_limit'}>
                Party Limit
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="party_limit"
                  defaultValue={4}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('party_limit');
                  }}
                  className={style.full_width}
                  min={1}
                  max={fields.capacity}
                />
                {renderError('party_limit')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The amount of time a guest has to enter the location once they are told it is their turn to come in before they are kicked from the line.' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'wait_limit'}>
                Wait Timeout
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="wait_limit"
                  defaultValue={10}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('wait_limit');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('wait_limit')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Toggle if door minders will be required to enter the phone number of a guest who is added to the line manually.' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'manual_add'}>
                Phone Required <br /> on Manual Add
                <Switch
                  ref={form.register}
                  form={form}
                  name="phoneRequiredManual"
                  label={'Phone Required on Manual Add'}
                  defaultChecked={fields.phoneRequiredManual}
                  value={fields.phoneRequiredManual}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('phoneRequiredManual');
                  }}
                />
                {renderError('phoneRequired')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Toggle if guests will be required to enter their name before joining the line.' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'name'}>
                Name Required
                <Switch
                  ref={form.register}
                  form={form}
                  name="nameRequired"
                  label={'Name Required'}
                  defaultChecked={fields.nameRequired}
                  value={fields.nameRequired}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('nameRequired');
                  }}
                />
                {renderError('phoneRequired')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Toggle if guests will be required to enter their phone number before joining the line.' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'phone'}>
                Phone Required
                <Switch
                  ref={form.register}
                  form={form}
                  name="phoneRequired"
                  label={'Phone Required'}
                  defaultChecked={fields.phoneRequired}
                  value={fields.phoneRequired}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('phoneRequired');
                  }}
                />
                {renderError('phoneRequired')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Toggle if guests will be required to fill out a CDC Covid-19 symptom screening before joining the line.' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '2rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'name'} style={{ textAlign: 'center' }}>
                Covid Screening <br /> Required
                <Switch
                  ref={form.register}
                  form={form}
                  name="covidScreenRequired"
                  label={'Covid Screen Required'}
                  defaultChecked={fields.covidScreenRequired}
                  value={fields.covidScreenRequired}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('covidScreenRequired');
                  }}
                />
                {renderError('covidScreenRequired')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The time of day your location opens for business' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'location_open_time'}>
                Location Open Time (**Using Eastern Time, not location time)
                <TimePicker
                  ref={form.register}
                  name="location_open_time"
                  format={'hh:mm a'}
                  form={form}
                  defaultValue={new Date(moment().hour(6).minute(0))}
                  className={style.full_width}
                  onChange={(e) => {
                    setLocalDirty(true);
                    setLocationOpenTime(e.target.value)
                    form.clearError('location_open_time');
                  }}
                />
                {renderError('location_open_time')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The time of day you want to start allowing guests to join your line' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'line_start_time'}>
                Line Start Time
                <TimePicker
                  ref={form.register}
                  name="line_start_time"
                  format={'hh:mm a'}
                  form={form}
                  defaultValue={locationOpenTime}
                  value={locationOpenTime}
                  className={style.full_width}
                  onChange={(e) => {
                    setLocationOpenTime(e.target.value);
                    setLocalDirty(true);
                    form.clearError('line_start_time');
                  }}
                />
                {renderError('line_start_time')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The time of day when you want to stop guests from being able to enter the line' placement='top'>
                <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'line_end_time'}>
                Line End Time
                <TimePicker
                  ref={form.register}
                  name="line_end_time"
                  format={'hh:mm a'}
                  form={form}
                  defaultValue={new Date(moment().hour(21).minute(0))}
                  className={style.full_width}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('line_end_time');
                  }}
                />
                {renderError('line_end_time')}
              </label>
            </div>
          </div>
        </div>
        <Button primary={true} disabled={processing} className={style.submit_btn}>
					Create Location
        </Button>
      </form>
    </div>
  );
}

// AddLocationView.propTypes = {
//   /* data */
//   loading: PropTypes.bool.isRequired,

//   /* utils */
//   form: PropTypes.object.isRequired,

//   /* methods */
//   onSubmit: PropTypes.func.isRequired,
// };

export default AddLocationView;
