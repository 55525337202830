import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

// import { useSelector } from 'react-redux';

/* Components */
import EditBrandView from './EditBrand.view';
import validationSchema from './validationSchema';
import defaults from 'formDefaults/brand.defaults';
import style from './_EditBrand.module.scss';

function EditBrandContainer(props) {
  /* data */
  const {
    match: {
      params: {
        brand_id,
      },
    },
  } = props;

  const [ processing, setProcessing ] = useState(false);
  const [ brandError, setBrandError ] = useState(null);
  const [ newBrand, setNewBrand ] = useState(false);
  const [ brand, setBrand ] = useState({});
  const [ fields, setFields ] = useState({});
  const [ localDirty, setLocalDirty ] = useState(false);
  const [ displayDeleteBrand, setDisplayDeleteBrand ] = useState(false);

  const brandsRef = window.firebase.firestore().doc(`brands/${brand_id}`);

  /* local form state */
  const form = useForm({
    validationSchema,
  });

  useEffect(() => {
    const brandsRefOff = brandsRef.onSnapshot((snapshot) => {
      const data = snapshot.data();

      if (!snapshot.data()) {
        setNewBrand(true);
        return setFields({
          ...fields,
          ...defaults,
        });
      }

      setBrand({
        ...brand,
        ...data,
        content: data.content ? JSON.stringify(data.content) : null,
        styles: data.styles ? JSON.stringify(data.styles) : null,
      });

      setFields({
        ...fields,
        ...defaults,
        ...data,
        content: data.content ? JSON.stringify(data.content) : null,
        styles: data.styles ? JSON.stringify(data.styles) : null,
      });
    });

    return () => {
      brandsRefOff();
    };
  }, [  ]);

  /* container methods */
  const methods = {
    async onSubmit() {
      const formData = form.watch();
      const dirty = form.formState.dirty || localDirty;
      if (!dirty) {
        return props.history.replace('/admin/brands');
      }

      const thisBrand = {
        ...defaults,
        ...brand,
        ...formData,
        content: formData.content ? JSON.parse(formData.content) : null,
        styles: formData.styles ? JSON.parse(formData.styles) : null,
      };

      await window.firebase.firestore().doc(`brands/${formData.brand_id}`)
        .get()
        .then((snapshot) => {
          if (snapshot.exists && newBrand) {
            setProcessing(false);
            return setBrandError('This brand ID already exists. Each brand ID must be unique.');
          }

          window.firebase.firestore().doc(`brands/${formData.brand_id}`)
            .set(thisBrand)
            .then(() => {
              setProcessing(false);
              console.log(`updated brand ${formData.brand_id}`);
              return props.history.push(`/admin/brands`);
            })
            .catch((e) => {
              setProcessing(false);
              console.log('joinLine - update brand error:', e);
            });
        });
    },
    async deleteBrand(brandId) {
      setProcessing(true);
      setDisplayDeleteBrand(false);
      return await window.firebase.firestore().doc(`brands/${brandId}`)
        .delete()
        .then((res) => {
          console.log('response from deleting brand:', res);
          return props.history.replace('/admin/brands');
        })
        .catch((e) => {
          console.log('error deleting brand', e);
        });
    },

    renderError(name) {
      if (form.errors[name]) {
        return (
          <span className={style.field_error}>{form.errors[name].message}</span>
        );
      }
      return (
        <span className={style.field_error}>&nbsp;</span>
      );
    },
  };


  return (
    <EditBrandView
      /* data */
      fields={fields}
      form={form}
      history={props.history}
      newBrand={newBrand}
      processing={processing}
      brandError={brandError}
      displayDeleteBrand={displayDeleteBrand}

      /* methods */
      onSubmit={methods.onSubmit}
      renderError={methods.renderError}
      setLocalDirty={setLocalDirty}
      setDisplayDeleteBrand={setDisplayDeleteBrand}
      deleteBrand={methods.deleteBrand}
    />
  );
}

export default EditBrandContainer;
