import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';

import { signOut, clearLocation } from 'state/actions';

import createDispatchedActions from 'utils/createDispatchedActions';
import JolHeaderLogo from 'common/jolHeaderLogo';
import style from '../SignIn/_SignIn.module.scss';

function SignOutContainer(props) {
  /* redux actions, wrapped in dispatch */
  const actions = createDispatchedActions(signOut);
  const dispatch = useDispatch();

  let redirectTimeout;

  /* lifecycle methods */
  useEffect( // on mount
    () => {
      dispatch(clearLocation());
      actions.signOut();

      redirectTimeout = setTimeout(() => {
        props.history.replace('/sign-in');
      }, 10000);

      return () => {
        clearTimeout(redirectTimeout);
      };
    },
    []
  );

  return (
    <div className={style.container} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className={style.header}>
        <JolHeaderLogo fill='black' />
      </div>
      <p style={{ fontSize: '1.5rem', marginBottom: '0' }}>Thanks for signing up with Join Our Line!</p>
      <p style={{ fontSize: '1.5rem' }}>Please sign in to confirm your registration.</p>
      <p>You will be automatically redirected to login in 10 seconds.</p>

      <div className={style.submit_button}>
        <Button
          primary={true}
          style={{ width: '100%' }}
          onClick={() => { props.history.replace('/sign-in'); }}
        >
          Click to Sign In
        </Button>
      </div>
    </div>
  );
}

export default SignOutContainer;
