// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._CovidScreenFailed-module__covid_fail_main___7bPJ6{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-align:center;align-items:center}._CovidScreenFailed-module__covid_fail_main___7bPJ6 ._CovidScreenFailed-module__logoContainer___1HrjA{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;margin:10px;max-width:100%;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;min-height:60px;background-color:#129A7F;padding-left:16px;padding-left:1rem;padding-right:16px;padding-right:1rem;padding-top:16px;padding-top:1rem;border-radius:10px}._CovidScreenFailed-module__covid_fail_main___7bPJ6 ._CovidScreenFailed-module__header___HoLKT{font-size:24px;font-size:1.5rem;text-align:center;width:60%;color:red}._CovidScreenFailed-module__covid_fail_main___7bPJ6 ._CovidScreenFailed-module__reset_btn___H1qWz{background-color:#129A7F;color:white;font-weight:500;font-size:24px;font-size:1.5rem;padding:8px;padding:.5rem;margin-top:32px;margin-top:2rem;border-radius:5%;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"covid_fail_main": "_CovidScreenFailed-module__covid_fail_main___7bPJ6",
	"logoContainer": "_CovidScreenFailed-module__logoContainer___1HrjA",
	"header": "_CovidScreenFailed-module__header___HoLKT",
	"reset_btn": "_CovidScreenFailed-module__reset_btn___H1qWz"
};
module.exports = exports;
