import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

/* data management */
import validationSchema from './validationSchema';

/* Components */
import EditLocationView from './EditLocation.view';
import defaults from 'formDefaults/location.defaults';
import StringHelper from 'common/stringHelper';
import snapshotToArray from 'common/snapshotToArray';
import style from '../EditBrand/_EditBrand.module.scss';

function EditLocationContainer(props) {
  /* redux state */
  const state = useSelector(state => ({
    user: state.user,
  }));

  /* data */
  const {
    match: {
      params: {
        location_id,
      },
    },
  } = props;

  /* local state */
  const [ processing, setProcessing ] = useState(false);
  const [ newLocation, setNewLocation ] = useState(false);
  const [ fields, setFields ] = useState({});
  const [ location, setLocation ] = useState({});
  const [ locationError, setLocationError ] = useState(null);
  const [ localDirty, setLocalDirty ] = useState(false);
  const [ brands, setBrands ] = useState([]);
  const [ displayDeleteLocation, setDisplayDeleteLocation ] = useState(false);
  const brandsRef = window.firebase.firestore().collection(`brands`);
  const locationRef = window.firebase.firestore().doc(`locations/${location_id}`);

  const strings = new StringHelper('en').getDefaultStrings();
  const languages = [ 'en', 'es' ];

  /* local form state */
  const form = useForm({
    validationSchema,
  });

  /* on mount */
  useEffect(() => {
    const locationRefOff = locationRef.onSnapshot((snapshot) => {
      if (!snapshot.data()) {
        setNewLocation(true);
        return setFields({
          ...defaults,
        });
      }

      const locationValues = snapshot.data();
      const hasAddress = locationValues && locationValues.address;
      const hasOpenTime = locationValues && locationValues.location_open_time;
      const hasStartTime = locationValues && locationValues.line_start_time;
      const hasEndTime = locationValues && locationValues.line_end_time;

      setLocation({
        ...location,
        ...locationValues,
        content: locationValues.content ? JSON.stringify(locationValues.content) : null,
        styles: locationValues.styles ? JSON.stringify(locationValues.styles) : null,
        location_open_time: hasOpenTime
          ? new Date(null, null, null, locationValues.location_open_time.hour, locationValues.location_open_time.minute, 0)
          : new Date(null, null, null, 6, 30, 0),
        line_start_time: hasStartTime
          ? new Date(null, null, null, locationValues.line_start_time.hour, locationValues.line_start_time.minute, 0)
          : new Date(null, null, null, 6, 30, 0),
        line_end_time: hasEndTime
          ? new Date(null, null, null, locationValues.line_end_time.hour, locationValues.line_end_time.minute, 0)
          : new Date(null, null, null, 21, 0, 0),
      });

      const stringFields = {};
      Object.keys(strings).forEach(key => {
        for (const lang of languages) {
          stringFields[`${key}-${lang}`] = locationValues.content && locationValues.content[key] ? locationValues.content[key][lang] : '';
        }
      });

      setFields({
        ...locationValues,
        ...stringFields,
        address1: hasAddress ? hasAddress.address1 : null,
        address2: hasAddress ? hasAddress.address2 : null,
        city: hasAddress ? hasAddress.city : null,
        state: hasAddress ? hasAddress.state : null,
        zip_code: hasAddress ? hasAddress.zip_code : null,
        content: locationValues.content ? JSON.stringify(locationValues.content) : null,
        styles: locationValues.styles ? JSON.stringify(locationValues.styles) : null,
        location_open_time: hasOpenTime
          ? new Date(null, null, null, locationValues.location_open_time.hour, locationValues.location_open_time.minute, 0)
          : null,
        line_start_time: hasStartTime
          ? new Date(null, null, null, locationValues.line_start_time.hour, locationValues.line_start_time.minute, 0)
          : null,
        line_end_time: hasEndTime
          ? new Date(null, null, null, locationValues.line_end_time.hour, locationValues.line_end_time.minute, 0)
          : null,
      });
    });

    const brandsRefOff = brandsRef.onSnapshot((snapshot) => {
      const brands = [];
      for (const brand of snapshot.docs) {
        if (brand.data().brand_id) {
          brands.push(brand.data());
        }
      }
      setBrands(brands);
    });

    return () => {
      locationRefOff();
      brandsRefOff();
    };
  }, [  ]);

  /* container methods */
  const methods = {
    async onSubmit() {
      setLocationError(null);
      setProcessing(true);

      const formData = form.watch();
      const dirty = form.formState.dirty || localDirty;
      if (!dirty) {
        if (state.user.user_role === 'door_admin') {
          return props.history.push(`/admin/dashboard`);
        }

        return props.history.replace('/admin/locations');
      }

      const stringsToOmit = [];
      Object.keys(strings).forEach(key => {
        for (const lang of languages) {
          stringsToOmit.push(`${key}-${lang}`);
        }
      });

      const thisLocation = {
        /* do not send individual address fields to Firebase - omit from data, and send as part of the address object */
        ...omit(defaults, [ 'address1', 'address2', 'city', 'state', 'zip_code', ...stringsToOmit ]),
        ...location,
        ...omit(formData, [ 'address1', 'address2', 'city', 'state', 'zip_code', ...stringsToOmit ]),
        location_id: formData.location_id,
        capacity_margin: formData.capacity_margin || null,
        brand_id: formData.brand_id.brand_id,
        content: formData.content ? JSON.parse(formData.content) : null,
        styles: formData.styles ? JSON.parse(formData.styles) : null,
        address: {
          address1: formData.address1 || null,
          address2: formData.address2 || null,
          city: formData.city || null,
          state: formData.state || null,
          zip_code: formData.zip_code || null,
        },
        location_open_time: {
          hour: formData.location_open_time ? formData.location_open_time.getHours() : null,
          minute: formData.location_open_time ? formData.location_open_time.getMinutes() : null,
        },
        line_start_time: {
          hour: formData.line_start_time.getHours(),
          minute: formData.line_start_time.getMinutes(),
        },
        line_end_time: {
          hour: formData.line_end_time.getHours(),
          minute: formData.line_end_time.getMinutes(),
        },
        distance: formData.distance || null,
        population: formData.population || 0,
        line_limit: formData.line_limit || null,
        party_limit: formData.party_limit || null,
        wait_limit: formData.wait_limit || null,
        phoneRequiredManual: formData.phoneRequiredManual || false,
        phoneRequired: formData.phoneRequired || false,
        nameRequired: formData.nameRequired || false,
        emailRequired: formData.emailRequired || false,
      };

      await window.firebase.firestore().doc(`locations/${formData.location_id}`)
        .get()
        .then((snapshot) => {
          if (snapshot.exists && newLocation) {
            setProcessing(false);
            return setLocationError('This location ID already exists. Each location ID must be unique.');
          }

          if (newLocation) {
            window.firebase.firestore().doc(`locations/${formData.location_id}`)
              .set(thisLocation)
              .then(async () => {
                await window.firebase.firestore().doc(`populationLogs/${formData.location_id}`)
                  .set({
                    location_id: formData.location_id,
                  });
                console.log(`updated location ${formData.location_id}`);
                setProcessing(false);
                setLocationError(null);
                if (state.user.user_role === 'door_admin') {
                  return props.history.push(`/admin/dashboard`);
                }
                return props.history.push(`/admin/locations`);
              })
              .catch((e) => {
                setProcessing(false);
                console.log('joinLine - update location error:', e);
              });
          } else {
            window.firebase.firestore().doc(`locations/${formData.location_id}`)
              .update(thisLocation)
              .then(() => {
                console.log(`updated location ${formData.location_id}`);
                setProcessing(false);
                setLocationError(null);
                if (state.user.user_role === 'door_admin') {
                  return props.history.push(`/admin/dashboard`);
                }
                return props.history.push(`/admin/locations`);
              })
              .catch((e) => {
                setProcessing(false);
                console.log('joinLine - update location error:', e);
              });
          }
        });
    },
    async deleteLocation(locationId) {
      setProcessing(true);
      setDisplayDeleteLocation(false);
      return await window.firebase.firestore().doc(`locations/${locationId}`)
        .delete()
        .then((res) => {
          console.log('response from deleting location:', res);
          return props.history.replace('/admin/locations');
        })
        .catch((e) => {
          console.log('error deleting location', e);
        });
    },

    renderError(name) {
      if (form.errors[name]) {
        return (
          <span className={style.field_error}>{form.errors[name].message}</span>
        );
      }
      return (
        <span className={style.field_error}>&nbsp;</span>
      );
    },

  };

  const isDoorAdmin = state.user.user_role === 'door_admin';

  return (
    <EditLocationView
      /* data */
      fields={fields}
      form={form}
      history={props.history}
      newLocation={newLocation}
      brands={brands}
      locationError={locationError}
      processing={processing}
      isDoorAdmin={isDoorAdmin}
      userRole={state.user.user_role}
      displayDeleteLocation={displayDeleteLocation}
      /* methods */
      onSubmit={methods.onSubmit}
      setLocalDirty={setLocalDirty}
      renderError={methods.renderError}
      setDisplayDeleteLocation={setDisplayDeleteLocation}
      deleteLocation={methods.deleteLocation}
    />
  );
}

export default EditLocationContainer;
