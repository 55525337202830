import React from 'react';

import LocationsTable from './LocationsTable.component';
import style from './_Locations.module.scss';
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';

function LocationsView(props) {
  const {
    locations,
    handleNavigate,
    history,
    currentUserRole,
    toggleLocationDisabled,
  } = props;

  class disabledLocationCell extends React.Component {
    render() {
      return (
        <td style={{ textAlign: 'center' }}>
          <p style={this.props.dataItem.disabled ? { color: '#ff6161', marginBottom: 0, fontWeight: 700 } : { color: '#129A7F', marginBottom: 0, fontWeight: 700 }}>{this.props.dataItem.disabled ? 'DISABLED' : 'ENABLED'}</p>
          <Switch
            defaultChecked={!this.props.dataItem.disabled}
            value={!this.props.dataItem.disabled}
            onChange={() => {
              toggleLocationDisabled(this.props.dataItem.location_id, this.props.dataItem.disabled);
            }}
          />
          <p style={this.props.dataItem.disabled ? { color: '#000000', paddingTop: '.5rem', marginBottom: 0 } : { color: '#000000', paddingTop: '.5rem', marginBottom: 0 }}>{this.props.dataItem.disabled ? 'Click To Enable' : 'Click To Disable'}</p>
        </td>
      );
    }
  }

  return (
    <div className={style.container}>
      <div className={style.header_container}>
        {currentUserRole === 'admin' ? 
          <Button
            icon="plus"
            primary={false}
            onClick={() => history.push(`/admin/locations/new`)}
          >
            Add New Location
          </Button>
          :
          null
        }

      </div>
      <LocationsTable
        locations={locations}
        handleNavigate={handleNavigate}
        disabledLocationCell={disabledLocationCell}
      />
    </div>
  );
}

LocationsView.propTypes = {
};

export default LocationsView;
