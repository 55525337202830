import React from 'react';
import { Button } from '@progress/kendo-react-buttons';

import BrandsTable from './BrandsTable.component';
import style from './_Brands.module.scss';

function BrandsView(props) {
  const {
    brands,
    handleNavigate,
    history,
  } = props;

  return (
    <div className={style.container}>
      <div className={style.header_container}>
        <Button
          icon="plus"
          primary={false}
          onClick={() => history.push(`/admin/brands/new`)}
        >
          Add New Brand
        </Button>

      </div>
      <BrandsTable
        brands={brands}
        handleNavigate={handleNavigate}
      />
    </div>
  );
}

BrandsView.propTypes = {
};

export default BrandsView;
