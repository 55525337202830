import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Alert } from 'antd';

import JolHeaderLogo from 'common/jolHeaderLogo';
import style from './_Error.module.scss';
import moment from 'moment';
import { Link } from 'react-router-dom';

function ErrorContainer(props) {
  return (
    <div className={style.containerWrapper} style={{ minHeight: window.innerHeight }}>
      <div className={style.container}>
        <div className={style.header}>
          {/* <JolHeaderLogo fill='black' /> */}
          <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
        </div>
        <div className={style.mainContent}>
          <Alert
            message={'Oops!'}
            description={`We can't find the page you're looking for!`}
            type="error"
            showIcon
            style={{ margin: '40px 0', textAlign: 'left', width: '100%' }}
          />
          <Button
            primary={true}
            onClick={() => { props.history.replace('/sign-in'); }}
          >
          Businesses - Sign In Here
          </Button>

          <Button
            primary={true}
            onClick={() => { props.history.replace('/'); }}
          >
          Get in Line
          </Button>

        </div>
      </div>

      <div className={style.footerContent}>
        <p>Businesses:<br/> click <a href={`http://about.joinourline.com`} target='_blank' className={`ld_cursor_pointer`}>
          here
        </a> to learn more</p>

        <div className={style.privacy_footer_content}>
          &copy; {moment().year()} Latitude Digital&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/privacy'} target={'_blank'} className={`ld_cursor_pointer`}>Privacy Policy</Link>
        </div>

      </div>
    </div>
  );
}

export default ErrorContainer;
