import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Icon } from 'antd';
import moment from 'moment';

export default class BrandsTable extends Component {
  constructor (props) {
    super(props);
  }

  cellRender = (td, props) => {
    if (td && props.field === 'Edit') {
      return (
        <td>
          <Icon
            type="edit"
            onClick={() => this.props.handleNavigate(props.dataItem.brand_id)}
          />
        </td>
      );
    }

    return td;
  };

  render() {
    const {
      brands,
    } = this.props;

    return (
      <Grid
        resizable={true}
        data={brands}
        cellRender={this.cellRender}
      >
        <Column
          field="name"
          title="Brand"
        />
        <Column
          field="line_limit"
          title="Line Limit"
        />
        <Column
          field="logo"
          title="Logo"
        />
        <Column
          field="Edit"
        />
      </Grid>

    );
  }
}
