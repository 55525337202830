import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
import { Icon } from 'antd';

export default class LocationsTable extends Component {
  constructor (props) {
    super(props);

    this.state = {
      sort: [
        { field: 'name', dir: 'asc' },
      ],
    };
  }


  _parseAddress = (addressObject) => {
    const {
      address1,
      address2,
      city,
      state,
      zip_code,
    } = addressObject;

    if (address2) {
      return `${address1}, ${address2}, ${city} ${state} ${zip_code}`;
    }

    return `${address1}, ${city} ${state} ${zip_code}`;
  };

  _renderDataFromObject = (row, field) => {
    if (field === 'line_start_time' || field === 'line_end_time') {
      return (
        <td>
          {moment(row[field]).format('h:mm A')}
        </td>
      );
    }

    if (field === 'address') {
      return (
        <td>
          {this._parseAddress(row[field])}
        </td>
      );
    }

    return null;
  };

  cellRender = (td, props) => {
    if (td && td.props.children && (props.field === 'line_start_time' || props.field === 'line_end_time' || props.field === 'address')) {
      return this._renderDataFromObject(props.dataItem, props.field);
    }

    if (td && props.field === 'Edit') {
      return (
        <td>
          <Icon
            type="edit"
            onClick={() => this.props.handleNavigate(props.dataItem.location_id)}
          />
        </td>
      );
    }

    return td;
  };

  render() {
    const {
      locations,
      disabledLocationCell,
    } = this.props;

    const {
      sort,
    } = this.state;

    return (
      <Grid
        resizable={true}
        cellRender={this.cellRender}
        data={orderBy(locations, sort)}
      >
        <Column
          field="name"
          title="Location"
        />
        <Column
          field="brand_name"
          title="Brand"
        />
        <Column
          field="location_id"
          title="Location ID"
        />
        <Column
          field="region"
          title="Region"
        />
        <Column
          field="address"
          title="Address"
        />
        {/* <Column
          field="capacity"
          title="Capacity"
        />
        <Column
          field="population"
          title="Current"
        /> */}
        {/* <Column
          field="line_start_time"
          title="Open"
        />
        <Column
          field="line_end_time"
          title="Close"
        /> */}
        <Column
          field="Edit"
        />
        <Column
          title='Location Status'
          filterable={false}
          cell={disabledLocationCell}
        />
      </Grid>

    );
  }
}
