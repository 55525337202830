import React from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Icon, Modal } from 'antd';

import CustomButton from 'common/Button';
import style from './_Join.module.scss';

function formatPhone(text, phone) {
  let newValue = text.substring(0, 12);
  const oldValue = phone || '';
  const lastCharacter = newValue.split('').pop();
  const aNumberKey = parseInt(lastCharacter, 10);

  if (newValue.length < oldValue.length) return newValue;

  if (isNaN(aNumberKey)) return oldValue;

  switch (newValue.length) {
    case 4: newValue = `${oldValue}-${aNumberKey}`;
      break;
    case 8: newValue = `${oldValue}-${aNumberKey}`;
      break;
    case 14: return oldValue;
  }

  return newValue;
}

function JoinView(props) {
  const {
    stringHelper,
    loading,
    connectionError,
    submitting,
    guestWaitingCount,
    joinLine,
    partySize,
    subtractGuest,
    addGuest,
    startTime,
    endTime,
    location,
    brandDetails,
    styleHelper,
    lineLimit,
    maxParty,
    nameRequired,
    name,
    handleSetName,
    phoneRequired,
    phone,
    handleSetPhone,
    withinCapMargin,
    customQuestions,
    handleSetCustom,
    emailRequired,
    handleSetEmail,
    email,
    showCovidScreen,
    setCovidScreenAnswers,
    covidScreenAnswers,
    evaluateCovidScreen,
    disabled,
    locationIsOpen,
    locationOpenTime,
  } = props;

  console.log('location ****', location);

  function renderContent() {
    if (connectionError) {
      return (
        <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
          <b>{stringHelper.createString('connection-error')}</b>
        </div>
      );
    }

    if (loading) {
      return (
        <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
          <Icon
            spin
            type="loading"
            style={{ fontSize: 100, color: '#002E6D' }}
          />
        </div>
      );
    }

    if (disabled) {
      return (
        <div style={{ width: '100%', textAlign: 'center', paddingTop: '5rem' }}>
          <p style={{ fontSize: '1rem', fontWeight: 700 }}>This location is not currently active.</p>
        </div>
      );
    }

    if (moment().isBefore(moment().set(startTime)) || moment().isAfter(moment().set(endTime))) {
      return (
        <div className={style.flexContainerSmall}>
          <p className={style.guestsAhead}>
            {stringHelper.createDivWithString({}, 'join-time-error', moment(startTime).format('h:mm A'), moment(endTime).format('h:mm A'))}
          </p>
        </div>
      );
    }

    if (withinCapMargin && !showCovidScreen && locationIsOpen) {
      return (
        <div className={style.goInContainer}>
          <p className={style.goInText}>There is currently no line at this location:</p>
          <h2 className={style.goInH2}>Come in!</h2>
        </div>
      );
    }

    /* we want to ensure that up to 5 people can join the line when it opens back up */
    if (guestWaitingCount > (lineLimit - maxParty)) {
      return (
        <div className={style.flexContainerSmall}>
          <p className={style.guestsAhead}>
            {stringHelper.createDivWithString({}, 'join-line-full-error')}
          </p>
        </div>
      );
    }

    const generateCustomQuestions = () => {
      if (!customQuestions) {
        return;
      }
      return customQuestions.map((questionObj) => {
        if (questionObj.display === 'input') {
          return (
            <div key={questionObj.key}>
              <b>{questionObj.question}</b><br />
              <Input
                name={questionObj.key}
                // value={name.value}
                required={questionObj.required}
                onChange={(e) => handleSetCustom({ [questionObj.question]: e.value })}
                style={{ width: '100%', height: '50px', margin: '10px 0' }}
                validationMessage={'This field is required'}
              />
            </div>
          );
        }
      });
    };
    
    return (
      <div className={style.flexContainer}>
        <h2>{location.name}</h2>
        <p className={style.guestsAhead}>
          { guestWaitingCount === 1 ?
            stringHelper.createDivWithString({}, 'join-party-waiting-singular', guestWaitingCount) :
            stringHelper.createDivWithString({}, 'join-party-waiting-plural', guestWaitingCount)
          }
        </p>
        <p>Do you want to join our line?</p>
        <form onSubmit={debounce(
          (event) => {
            event.preventDefault();
            joinLine();
          },
          3000,
          {
            leading: true,
            trailing: false,
          }
        )}>

          {
            nameRequired &&
            <div>
              <b>{stringHelper.createString('join-name-input')}</b><br />
              <Input
                name={'name'}
                value={name.value}
                required={true}
                onChange={(e) => handleSetName({ value: e.value })}
                style={{ width: '100%', height: '50px', margin: '10px 0' }}
                validationMessage={stringHelper.createString('join-name-error')}
              />
            </div>
          }

          {
            phoneRequired &&
            <div>
              <b>{stringHelper.createString('join-phone-input')}</b><br />
              <Input
                name={'phone'}
                value={phone.value}
                required={true}
                onChange={(e) => handleSetPhone({ value: formatPhone(e.value, phone.value) })}
                style={{ width: '100%', height: '50px', margin: '10px 0' }}
                pattern={'^[0-9]{3}-[0-9]{3}-[0-9]{4}$|^[0-9]{10}$'}
                validationMessage={stringHelper.createString('join-phone-error')}
              />
            </div>
          }

          { 
            emailRequired &&
            <div>
              <b>{stringHelper.createString('join-email-input')}</b><br />
              <Input
                name={'email'}
                value={email.value}
                required={true}
                onChange={(e) => handleSetEmail({ value: e.value })}
                style={{ width: '100%', height: '50px', margin: '10px 0' }}
                type='email'
                validationMessage={stringHelper.createString('join-email-error')}
              />
            </div>
          }

          { customQuestions && customQuestions.length > 0 ? generateCustomQuestions() : null}

          { (maxParty > 1) && 
            <div className={style.counterContainer}>
              <p><b>{stringHelper.createString('join-guests-input')}</b></p>
              <Button
                onClick = {
                  (event) => {
                    event.preventDefault();
                    subtractGuest();
                  }
                }
                disabled={partySize <= 1}
                icon='minus'
              />
              <span>{partySize}</span>
              <Button
                onClick = {
                  (event) => {
                    event.preventDefault();
                    addGuest();
                  }
                }
                disabled={partySize >= maxParty}
                icon='plus'
              />
            </div>
          }
          { locationIsOpen ? null :
            <p>{`This location does not open until ${moment(moment().set(locationOpenTime)).format('h:mm A')} ET, but you can still secure your place in line. The queue will begin moving once the location opens.`}</p>
          }
          <CustomButton
            type={'submit'}
            style={{ borderColor: styleHelper.createStyle('primaryColor'), backgroundColor: styleHelper.createStyle('primaryColor') }}
            primary={true}
            disabled={submitting}
            className={style.join_btn}
            title={stringHelper.createString('join-button')}
          />
        </form>
      </div>
    );
  }

  function renderCovidScreen() {
    const symptoms = [ 'fever or chills', 'cough', 'shortness of breath or difficuly breathing', 'fatigue', 'muscle or body aches', 'headache', 'new loss of taste or smell', 'sore throat', 'congestion or runny nose', 'nausea or vomiting', 'diarrhea' ];
    const symptomListItems = symptoms.map((symptom) => {
      return (
        <li className={style.symptom_list_item} key={symptom} >{symptom}</li>
      );
    });
    return (
      <div className={style.covid_screen_container}>
        <div className={style.covid_question_container}>
          <p className={style.covid_question_text}>Have you experienced any of the following symptoms in the past 48 hours?</p>
          <ul className={style.covid_symptom_list}>
            {symptomListItems}
          </ul>
          <div className={style.radio_group}>
            <label>
              <input type='radio' value='yes' name='symptoms' onClick={((e) => setCovidScreenAnswers({
                ...covidScreenAnswers,
                [e.target.name]: true,
              }))}
              /> Yes
            </label>
            <label>
              <input type='radio' value='no' name='symptoms' defaultChecked onClick={((e) => setCovidScreenAnswers({
                ...covidScreenAnswers,
                [e.target.name]: false,
              }))}/> No
            </label>
          </div>
        </div>
        <div className={style.covid_question_container}>
          <p className={style.covid_question_text}>Within the past 14 days,have you been in close physical contact (6 feet or closer for
          at least 15 minutes) with a person who is known to have laboratory-confirmed
          COVID-19 or with anyone who has any symptoms consistent with COVID-19? 
          </p>
          <div className={style.radio_group}>
            <label>
              <input type='radio' value='yes' name='physicalContact' onClick={((e) => setCovidScreenAnswers({
                ...covidScreenAnswers,
                [e.target.name]: true,
              }))}
              /> Yes
            </label>
            <label>
              <input type='radio' value='no' name='physicalContact' defaultChecked onClick={((e) => setCovidScreenAnswers({
                ...covidScreenAnswers,
                [e.target.name]: false,
              }))}/> No
            </label>
          </div>
        </div>
        <div className={style.covid_question_container}>
          <p className={style.covid_question_text}>Are you isolating or quarantining because you may have been exposed to a person with
            COVID-19 or are worried that you may be sick with COVID-19? 
          </p>
          <div className={style.radio_group}>
            <label>
              <input type='radio' value='yes' name='isolating' onClick={((e) => setCovidScreenAnswers({
                ...covidScreenAnswers,
                [e.target.name]: true,
              }))}
              /> Yes
            </label>
            <label>
              <input type='radio' value='no' name='isolating' defaultChecked onClick={((e) => setCovidScreenAnswers({
                ...covidScreenAnswers,
                [e.target.name]: false,
              }))}/> No
            </label>
          </div>
        </div>
        <div className={style.covid_question_container}>
          <p className={style.covid_question_text}>Are you currently waiting on the results of a COVID-19 test?
          </p>
          <div className={style.radio_group}>
            <label>
              <input type='radio' value='yes' name='waitingOnTest' onClick={((e) => setCovidScreenAnswers({
                ...covidScreenAnswers,
                [e.target.name]: true,
              }))}
              /> Yes
            </label>
            <label>
              <input type='radio' value='no' name='waitingOnTest' defaultChecked onClick={((e) => setCovidScreenAnswers({
                ...covidScreenAnswers,
                [e.target.name]: false,
              }))}/> No
            </label>
          </div>
        </div>
      </div>
    );
  }
  const cnclBtnProps = {
    disabled: true,
  };
  return (
    <div className={style.container}>
      <div className={style.logoContainer} style={{ backgroundColor: styleHelper.createStyle('defaultLogoColor') }}>
        {
          brandDetails.logo ? 
            <img src={brandDetails.logo} style={{ maxWidth: '256px' }} />
            : 
            <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
        }
      </div>
      <Modal
        title={'This location requires that you answer the following CDC Covid-19 symptom screening before joining the line:'}
        visible={showCovidScreen}
        closable={false}
        maskClosable={false}
        cancelButtonProps={cnclBtnProps}
        cancelText={' '}
        okText={'Submit'}
        onOk={() => evaluateCovidScreen()}
      >
        {renderCovidScreen()}
      </Modal>
      {renderContent()}
    </div>
  );
}

export default JoinView;
