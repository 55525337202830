import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Icon } from 'antd';

export default class BrandsTable extends Component {
  constructor (props) {
    super(props);
  }

  cellRender = (td, props) => {
    if (td && props.field === 'Edit') {
      return (
        <td>
          <Icon
            type="edit"
            onClick={() => this.props.handleNavigate(props.dataItem.uid)}
          />
        </td>
      );
    }

    return td;
  };

  render() {
    const {
      users,
    } = this.props;

    return (
      <Grid
        resizable={true}
        data={users}
        cellRender={this.cellRender}
      >
        <Column
          field="email"
          title="Email"
        />
        {/* <Column
          field="password"
          title="Password"
        /> */}
        <Column
          field="customClaims.user_role"
          title="User Role"
        />
        <Column
          field="customClaims.location_id"
          title="Location"
        />
        <Column
          field="customClaims.region_id"
          title="Region"
        />
        <Column
          field="Edit"
        />
      </Grid>

    );
  }
}
