import React from 'react';
import style from './_InputWrapper.module.scss';
import _ from 'lodash';

/* data management */
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

/* components */
import { RHFInput } from 'react-hook-form-input';

/**
 * InputWrapper
 * - abstracts setup for react-hook-form input
 * @props {
 *   form __ output data & methods from react-hook-form
 *   name __ name of the field for react-hook-form
 *   component __ the external input component to use -- react-hook-form-input
 *   containerClassName __ class name(s) for field/error parent element
 * }
 */
function InputWrapper(props) {
  const {
    form,
    name,
    hidden,
    onChange,
    component = '',
    containerClassName = '',
    setValue,
    setCoords,
    dataItem,
    containerStyles,
  } = props;

  /* props that aren't manually passed to RHFInput */
  const implicitProps = omit(props, [
    'form',
    'component',
    'containerClassName',
    'hidden',
    'setValue',
    'setCoords',
    'dataItem',
  ]);

  function renderError() {
    if (form.errors[name]) {
      return (
        <span className={style.field_error}>{form.errors[name].message}</span>
      );
    }
    return (
      <span className={style.field_error}>&nbsp;</span>
    );
  }

  if (hidden) {
    return null;
  }

  if (!_.isEmpty(form.errors)) {
    console.log('ERRORS --', form.errors);
  }

  return (
    <div className={containerClassName} style={{ ...containerStyles }}>
      <RHFInput
        {...implicitProps} // add this first so the following props can't be overwritten
        as={component}
        register={form.register}
        unregister={form.unregister}
        setValue={form.setValue}
        onChange={(event) => {
          onChange && onChange(event);
          form.clearError(name);
          if (setValue) {
            // html is what Editor uses, not value
            setValue(event.html || event.value);
          }
          if (setCoords) {
            setCoords(event.value);
          }
        }}
      />
      {!dataItem && renderError()}
    </div>
  );
}

InputWrapper.propTypes = {
  component: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  mode: PropTypes.string,
  containerClassName: PropTypes.string,
  hidden: PropTypes.bool,
  onChange: PropTypes.func,
  form: PropTypes.shape({
    register: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    triggerValidation: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
  }).isRequired,
};

InputWrapper.defaultProps = {
  mode: 'onBlur',
};

export default InputWrapper;
