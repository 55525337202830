import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

/* Components */
import LocationsView from './Locations.view';
import snapshotToArray from 'common/snapshotToArray';

function LocationsContainer(props) {
  const [ locations, setLocations ] = useState([]);
  const [ brands, setBrands ] = useState([]);
  let locationsRef = window.firebase.firestore().collection(`locations`);
  const brandsRef = window.firebase.firestore().collection(`brands`);

  const state = useSelector(state => ({
    user: state.user,
  }));

  useEffect(() => {
    console.log('current user in locations container:', state.user);
    switch (state.user.user_role) {
      case 'brand_manager':
        locationsRef = window.firebase.firestore().collection('locations')
          .where('brand_id', '==', state.user.brand_id);
        break;
      case 'regional_manager':
        locationsRef = window.firebase.firestore().collection('locations')
          .where('region', '==', state.user.region_id);
        break;
      default:
        break;
    }
    const locationsRefOff = locationsRef.onSnapshot((snapshot) => {
      setLocations(snapshotToArray(snapshot));
    });

    const brandsRefOff = brandsRef.onSnapshot((snapshot) => {
      setBrands(snapshotToArray(snapshot));
    });

    return () => {
      locationsRefOff();
      brandsRefOff();
    };
  }, [  ]);


  /* container methods */
  const methods = {
    handleNavigate(param) {
      if (state.user.user_role === 'admin') {
        return props.history.push(`/admin/locations/${param}`);
      }
      return props.history.push(`/admin/client_edit_location/${param}`);
    },

    handleCreateNewLocation() {
      props.history.push(`/admin/location`);
    },
    toggleLocationDisabled(locationId, currentDisabledState) {
      return window.firebase.firestore().doc(`locations/${locationId}`)
        .update({
          disabled: !currentDisabledState,
        });
    },
  };

  function createLocationObjects() {
    locations.map((location) => {
      const foundMatchingBrand = find(brands, { brand_id: location.brand_id });
      if (foundMatchingBrand) {
        location.brand_name = foundMatchingBrand.name;
      }
    });
    return locations;
  }

  return (
    <LocationsView
      locations={createLocationObjects()}
      handleNavigate={methods.handleNavigate}
      history={props.history}
      currentUserRole={state.user.user_role}
      toggleLocationDisabled={methods.toggleLocationDisabled}
    />
  );
}

export default LocationsContainer;
