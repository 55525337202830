import React from 'react';
import BatteryTipsView from './BatteryTips.view';

function BatteryTipsContainer() {
  return (
    <BatteryTipsView
    />
  );
}

export default BatteryTipsContainer;
