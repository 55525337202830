import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import CustomButton from 'common/Button';
import { Icon } from 'antd';
import isEmpty from 'lodash/isEmpty';
import StringsForm from 'common/stringsForm';
import style from './_CustomMessaging.module.scss';
import JolHeaderLogo from 'common/jolHeaderLogo';
import StringHelper from 'common/stringHelper';



function CustomMessagingView(props) {
  const {
    /* data */
    fields,
    setLocalDirty,
    newLocation,
    history,
    brands,
    locationError,
    processing,
    isDoorAdmin,
    userRole,
    currentCustomTextValue,
    currentCustomTextKey,
    locationName,
    atTop,
    /* utils */
    form,

    /* methods */
    onSubmit,
    renderError,
    customTextTrigger,
    setCurrentCustomTextKey,
  } = props;
  // console.log(fields.content);
  // const contentObj = fields.content ? JSON.parse(JSON.stringify(fields.content)) : null ;
  // console.log('content here:', contentObj);
  // console.log('keys and fields', currentCustomTextKey, currentCustomTextValue);

  // if (contentObj && currentCustomTextKey && contentObj[currentCustomTextKey]) {
  //   console.log('yes ****', contentObj[currentCustomTextKey]);
  // } else {
  //   console.log('no%%%');
  // }

  // if (contentObj) {
  //   console.log('content obj:', contentObj["join-party-waiting-singular"]);
  // }

  const stringHelper = new StringHelper('en', null, null);
  const strings = new StringHelper('en', {}).getDefaultStrings();
  const joinScreenKeys = [ 'join-party-waiting-singular', 'join-party-waiting-plural', 'join-name-input', 'join-name-error', 'join-phone-input', 'join-phone-error', 'join-email-input', 'join-email-error', 'join-guests-input', 'join-button' ];
  const landingErrorKeys = [ 'join-time-error', 'join-line-full-error', 'checkin-expired' ];
  const waitingScreenKeys = [ 'waiting-instructions', 'waiting-instructions-details', 'waiting-place-in-line', 'waiting-priority-disclaimer', 'waiting-social-distancing' ];
  const checkinScreenKeys = [ 'checkin-instructions-header', 'checkin-instructions', 'checkin-expire-instructions', 'checkin-social-distancing' ];
  const covidFailedScreenKey = 'covid-failed-message';
  // default values won't rerender on update -- need to set defaults from Firebase first
  if (isEmpty(fields)) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  const renderJoinScreen = () => {
    return (
      <div className={style.joinContainer}>
        <h2>{locationName}</h2>
        {/* { contentObj['join-party-waiting-singular'].en && currentCustomTextValue !== '' ?
          <div className={style.guestsAhead} dangerouslySetInnerHTML={{ __html: currentCustomTextValue }}>
          </div>
          :  
        } */}
        {currentCustomTextKey === 'join-party-waiting-singular' || currentCustomTextKey === 'join-party-waiting-plural' ?
          <div className={style.guestsAhead} dangerouslySetInnerHTML={{ __html: currentCustomTextValue }}>
            {/* {currentCustomTextValue} */}
          </div>
          :
          <p className={style.guestsAhead}>
            {stringHelper.createDivWithString({}, 'join-party-waiting-plural', 5)}
            {/* {contentObj && contentObj['join-party-waiting-singular']['en'] ? 'yes' : 'no'} */}
          </p>
        }
        <p>Do you want to join our line?</p>
        <div className={style.fakediv}>
          <div style={{ width: '100%' }}>
            <b>{currentCustomTextKey === 'join-name-input' ? currentCustomTextValue : 'Name:'}</b><br />
            <Input
              name={'name'}
              style={{ width: '100%', marginBottom: '.5rem' }}
            />
            <p style={{ color: 'red' }}>{currentCustomTextKey === 'join-name-error' ? currentCustomTextValue : null}</p>
          </div>

          <div>
            <b>{currentCustomTextKey === 'join-phone-input' ? currentCustomTextValue : 'Mobile Phone number so we can text you when youre next:'}</b><br />
            <Input
              name={'phone'}
              style={{ width: '100%',  marginBottom: '.5rem' }}
            />
            <p style={{ color: 'red' }}>{currentCustomTextKey === 'join-phone-error' ? currentCustomTextValue : null}</p>
          </div>
          <div style={{ width: '100%' }}>
            <b>{currentCustomTextKey === 'join-email-input' ? currentCustomTextValue : 'Email:'}</b><br />
            <Input
              name={'email'}
              style={{ width: '100%', marginBottom: '.5rem' }}
            />
            <p style={{ color: 'red' }}>{currentCustomTextKey === 'join-email-error' ? currentCustomTextValue : null}</p>
          </div>
          <div className={style.counterContainer}>
            <p><b>{currentCustomTextKey === 'join-guests-input' ? currentCustomTextValue : stringHelper.createString('join-guests-input')}</b></p>
            <Button
              icon='minus'
            />
            <span>{4}</span>
            <Button
              icon='plus'
            />
          </div>
          <Button className={style.fakeJoinBtn} onClick={(e) => e.preventDefault()}>{currentCustomTextKey === 'join-button' ? currentCustomTextValue : 'Join the Line'}</Button>
        </div>
      </div>
    );
  };

  const renderWaitingScreen = () => {
    return (
      <div className={style.waitingContainer}>
        <p className={style.alert}>
          <b>{currentCustomTextKey === 'waiting-instructions' ? currentCustomTextValue : stringHelper.createString('waiting-instructions')}</b><br/>
          <div className={style.subtext}>{currentCustomTextKey === 'waiting-instructions-details' ? currentCustomTextValue : stringHelper.createString('waiting-instructions-details')}</div>
        </p>
        <p className={style.place}>{currentCustomTextKey === 'waiting-place-in-line' ? currentCustomTextValue : stringHelper.createString('waiting-place-in-line', '6th')}</p>
        <p className={style.subtext}>
          {currentCustomTextKey === 'waiting-priority-disclaimer' ? currentCustomTextValue : stringHelper.createString('waiting-priority-disclaimer')}
        </p>

        {currentCustomTextKey === 'waiting-social-distancing' ?
          <div className={style.socialDistance} dangerouslySetInnerHTML={{ __html: currentCustomTextValue }} />
          :
          stringHelper.createDivWithString(
            { className: style.socialDistance },
            'waiting-social-distancing'
          )}
      </div>
    );
  };

  const renderCheckinScreen = () => {
    return (
      <div className={style.checkinContainer}>
        <p className={style.prompt}>
          {currentCustomTextKey === 'checkin-instructions-header' ? currentCustomTextValue : "It's your turn!"}
          <br />
          {currentCustomTextKey === 'checkin-instructions' ? currentCustomTextValue : ' Please show your device to the door attendant.'}
        </p>
        <p className={style.number}>{6}</p>
        <p>{currentCustomTextKey === 'checkin-expire-instructions' ? currentCustomTextValue : stringHelper.createString('checkin-expire-instructions', '2:45 PM')}</p>
        {currentCustomTextKey === 'checkin-social-distancing' ?
          <div className={style.socialDistance} dangerouslySetInnerHTML={{ __html: currentCustomTextValue }} />
          :
          stringHelper.createDivWithString(
            { className: style.socialDistance },
            'checkin-social-distancing'
          )}

      </div>
    );
  };

  const renderLandingScreen = () => {
    return (
      <p style={{ width: '80%', marginLeft: '10%', marginBottom: '15rem', fontSize: '1.5rem', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: currentCustomTextValue }}></p>
    );
  };

  const renderCovidFailedScreen = () => {
    return (
      <div>
        <h1 style={{ color: 'red', textAlign: 'center', width: '60%', marginLeft: '20%', fontSize: '1.5rem', marginTop: '2rem' }} >
          {currentCustomTextKey === 'covid-failed-message' ? currentCustomTextValue : stringHelper.createString('covid-failed-message')}
        </h1>
        <Button style={{ width: '40%', backgroundColor: 'blue', color: 'white', padding: '.5rem', marginLeft: '30%', marginBottom: '1rem', fontWeight: '500' }}>Go Home</Button>
      </div>
    );
  };

  return (
    <div className={style.container}>
      <div className={style.header_container}>
        <h1 style={{ textAlign: 'center', fontSize: '1.5rem' }}>Update Custom Messaging</h1>
        <p style={{ width: '80%', marginLeft: '10%' }}>To further customize Join Our Line, you can choose to change the default messaging throughout the app. For each section, click on the '?' icon within each expanded input to see a description of that text. If you would like to change one, expand the input by clicking on it and type in your custom text. Select 'Set to Default' to view the standard text for that input.</p>
        <p style={{ width: '80%', marginLeft: '10%', textAlign: 'center', fontWeight: '500' }}>The live preview <b>(either on the right hand side or bottom of your screen)</b> will show you what the current custom text you are editing will look like. <br />
        Please Note: Any text within curly-brackets denotes a variable value that will be replaced with the correct value during use.
        </p>
      </div>

      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={style.formWrapper}>
          <div className={style.column} style={{ display: isDoorAdmin ? 'none' : 'inherit' }}>
            <label htmlFor={'content'}>
              <button style={currentCustomTextKey === '' ? { display: 'none' } : null} className={style.reset_btn} onClick={(e) => { setCurrentCustomTextKey(''); e.preventDefault(); }}>Reset Preview</button>
              <StringsForm
                name="content"
                form={form}
                style={style}
                fields={fields}
                customTextTrigger={customTextTrigger}
                setCurrentCustomTextKey={setCurrentCustomTextKey}
                onChange={(e) => {
                  setLocalDirty(true);
                }}
              />
              {renderError('content')}
            </label>
          </div>
          <div className={style.livePreview} style={currentCustomTextKey !== '' && !atTop ? { bottom: '4rem' } : { top: '18rem' }}>
            <h2 style={{ textAlign: 'center' }}>Custom Text Live-Preview</h2>
            <p className={style.previewLabel}>{currentCustomTextKey !== '' ? `Previewing: ${strings[currentCustomTextKey].name}` : null}</p>
            <div className={style.livePreviewContainer} style={currentCustomTextKey !== '' ? null : null}>
              <div className={style.logoContainer} style={{ backgroundColor: '#129A7F' }}>
                {/* <JolHeaderLogo /> */}
                <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
              </div>
              {currentCustomTextKey === '' ? <p style={{ textAlign: 'center', width: '80%', marginLeft: '10%', fontWeight: '500', fontSize: '1.5rem' }}>Please select an option from the left-hand column to view a live preview</p> : null}
              {joinScreenKeys.includes(currentCustomTextKey) ? renderJoinScreen() : null}
              {landingErrorKeys.includes(currentCustomTextKey) ? renderLandingScreen() : null}
              {waitingScreenKeys.includes(currentCustomTextKey) ? renderWaitingScreen() : null}
              {checkinScreenKeys.includes(currentCustomTextKey) ? renderCheckinScreen() : null}
              {covidFailedScreenKey === currentCustomTextKey ? renderCovidFailedScreen() : null}
              <div className={style.footerContent}>
                <div className={style.privacy_footer_content}>
                  &copy; {moment().year()} Latitude Digital&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/privacy'} target={'_blank'} className={`ld_cursor_pointer`}>Privacy Policy</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {locationError ? (
          <div className={style.field_error} style={{ textAlign: 'left' }}>
            {locationError}
          </div>
        ) : null}

        {processing ? (
          <div style={{ width: '100%', textAlign: 'center', paddingTop: 100 }}>
            <Icon spin type="loading" style={{ fontSize: 20, color: '#002E6D' }} />
          </div>
        ) : null}

        <Button className={style.save_btn} primary={true} disabled={processing}>
					Save Changes
        </Button>
      </form>
    </div>
  );
}

CustomMessagingView.propTypes = {
};

export default CustomMessagingView;
