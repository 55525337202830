import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import snapshotToArray from 'common/snapshotToArray';
import sumBy from 'lodash/sumBy';

/* Components */
import MetricsSnapshotView from './MetricsSnapshot.view';
import { process } from '@progress/kendo-data-query';
function MetricsSnapshotContainer() {
  // REDUX STATE
  const state = useSelector(state => ({
    user: state.user,
  }));

  // STATE 
  const [ gridData, setGridData ] = useState({});
  const [ dataState, setDataState ] = useState({});
  const [ rawLocationsData, setRawLocationsData ] = useState([]);
  const [ displayMonthlyData, toggleDisplayMonthlyData ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ selectedDate, setSelectedDate ] = useState('');
  const [ selectedLocation, setSelectedLocation ] = useState('');
  const [ total, setTotal ] = useState(0);

  // FUNCTIONS
  const formatGridData = (rawData) => {
    return rawData.map((location) => {
      return {
        ...location,
        percent_occupied: Math.round((location.population / location.capacity) * 100),
        populationUpdated: location.populationUpdated ? moment(location.populationUpdated.toDate()).valueOf() : 0,
      };
    });
  };

  const handleDataStateChange = (event) => {
    setDataState(event.dataState);
    setGridData(process(rawLocationsData, event.dataState));
  };

  const handleDateChange = (event) => {
    if (event) {
      setSelectedDate(moment(event).format('MM-DD-YYYY'));
    } else {
      setSelectedDate(null);
    }
    getMonthlyData(selectedLocation, moment(event).format('MM-DD-YYYY'));
  };

  const handleMonthlyDataClick = (locationId) => {
    setTotal(0);
    setSelectedDate('');
    toggleDisplayMonthlyData(true);
    setSelectedLocation(locationId);
  };

  const getMonthlyData = (locationId, date) => {
    const getQueueAmountByMonth = async (locationId, selectedDate) => {
      return await window.firebase.firestore().collection(`queues/${locationId}/guests`)
        .where('checked_in', '>', 0)
        .get()
        .then(async (snapshot) => {
          if (snapshot.docs.length < 1) {
            // check queue archive
            console.log('no queue amount for this store, checking archive.');
            return 0;
          }
          let totalQueueAmount = 0;
          for (const log of snapshot.docs) {
            const logData = log.data();
            const formattedTimestamp = moment(logData.checked_in).format('MM-DD-YYYY');
            if (moment(formattedTimestamp, 'MM-DD-YYYY').isSame(selectedDate, 'month')) {
              totalQueueAmount += logData.party_size ? logData.party_size : 1;
            }
          }
          console.log('queue total:', totalQueueAmount);
          return totalQueueAmount;
        });
    }; 

    const getArchiveAmountByMonth = async (locationId, selectedDate) => {
      return await window.firebase.firestore().collection(`queuesArchive/${locationId}/guests`)
        .where('checked_in', '>', 0)
        .get()
        .then(async (snapshot) => {
          if (snapshot.docs.length < 1) {
            console.log('no queue archive amount for this store.');
            return 0;
          }
          let totalQueueArchiveAmount = 0;
          for (const log of snapshot.docs) {
            const logData = log.data();
            const formattedTimestamp = moment(logData.checked_in).format('MM-DD-YYYY');
            if (moment(formattedTimestamp, 'MM-DD-YYYY').isSame(selectedDate, 'month')) {
              totalQueueArchiveAmount += logData.party_size ? logData.party_size : 1;
            }
          }
          console.log('queue archive total', totalQueueArchiveAmount);
          return totalQueueArchiveAmount;
        });
    };
    const getPlusAmountByMonth = async (locationId, selectedDate) => {
      return await window.firebase.firestore().collection(`populationLogs/${locationId}/logs`)
        .where('amount', '>', 0)
        .get()
        .then(async (snapshot) => {
          if (snapshot.docs.length < 1) {
            console.log('no data for this location');
            setTotal(0);
            setLoading(false);
            return 0;
          }
          let totalPlusAmount = 0;
          for (const log of snapshot.docs) {
            const logData = log.data();
            const rawTimestamp = logData.timestamp ? new Date(logData.timestamp.toDate()) : new Date(logData.created_timestamp.toDate());
            const formattedTime = moment(rawTimestamp).format('MM-DD-YYYY')
            if (moment(formattedTime, 'MM-DD-YYYY').isSame(selectedDate, 'month')) {
              totalPlusAmount += logData.amount;
            }
          }
          console.log('plus total:', totalPlusAmount);
          const queueAmount = await getQueueAmountByMonth(locationId, selectedDate)
          const archiveAmount = await getArchiveAmountByMonth(locationId, selectedDate)
          const finalTotal = totalPlusAmount + queueAmount + archiveAmount;
          setLoading(false);
          setTotal(finalTotal);
          setLoading(false);
          return;
        })
        .catch(e => console.log('ERROR:', e));
    };
    setLoading(true);
    return getPlusAmountByMonth(locationId, date);
  };

  // LIFECYCLE METHODS
  useEffect(() => {
    setSelectedDate(selectedDate);
    const userRole = state.user.user_role;
    const findCurrentQueue = async (locationId) => {
      return await window.firebase.firestore().collection(`queues/${locationId}/guests`)
        .orderBy('priority', 'desc')
        .orderBy('queue_id')
        .get()
        .then((snapshot) => {
          const queue = snapshotToArray(snapshot);
          // start counting stuff
          const tempPeopleInQueue = [];
          // loop queue so we can see what's up
          for (let i = 0; i < queue.length; i++) {
            const thisGuest = queue[i];
            // ignore guests who entered within cap margin
            if (!thisGuest.entered_within_margin) {
              // get line count
              if (!thisGuest.checked_in && !thisGuest.checked_out && !thisGuest.allowed) {
                tempPeopleInQueue.push(thisGuest);
              }
            }
          }
          return tempPeopleInQueue;
        });
    };
    const locationsRef = window.firebase.firestore().collection('locations')
      .onSnapshot(async (snapshot) => {
        const locationsArray = [];
        for (const location of snapshot.docs) {
          const locationData = location.data();
          if (userRole === 'brand_manager' || userRole === 'admin') {
            if (state.user.brand_id ? locationData.brand_id === state.user.brand_id : true) {
              let currentQueue = 0;
              // to load faster, only check currentQueue if the % occupied > 95%
              if ((locationData.population / locationData.capacity) > .95) {
                currentQueue = await findCurrentQueue(location.id);
              }
              locationData.waiting = typeof currentQueue === 'object' ? sumBy(currentQueue, 'party_size') : 0;
              locationsArray.push(locationData);
            }
          } else if (userRole === 'regional_manager') {
            if (locationData.region === state.user.region_id) {
              let currentQueue = 0;
              // to load faster, only check currentQueue if the % occupied > 95%
              if ((locationData.population / locationData.capacity) > .95) {
                currentQueue = await findCurrentQueue(location.id);
              }
              locationData.waiting = typeof currentQueue === 'object' ? sumBy(currentQueue, 'party_size') : 0;
              locationsArray.push(locationData);
            }
          } else if (userRole === 'store_manager' || userRole === 'client') {
            if (locationData.location_id === state.user.location_id) {
              let currentQueue = 0;
              // to load faster, only check currentQueue if the % occupied > 95%
              if ((locationData.population / locationData.capacity) > .95) {
                currentQueue = await findCurrentQueue(location.id);
              }
              locationData.waiting = typeof currentQueue === 'object' ? sumBy(currentQueue, 'party_size') : 0;
              locationsArray.push(locationData);
            }

          }
        }
        setRawLocationsData(formatGridData(locationsArray));
        setGridData(process(formatGridData(locationsArray), dataState));
      });
    return () => {
      locationsRef();
    };
  }, [ dataState ]);

  return (
    <MetricsSnapshotView
      gridData={gridData}
      dataState={dataState}
      setDataState={setDataState}
      setDataState={setDataState}
      handleDataStateChange={handleDataStateChange}
      displayMonthlyData={displayMonthlyData}
      toggleDisplayMonthlyData={toggleDisplayMonthlyData}
      loading={loading}
      setSelectedDate={setSelectedDate}
      selectedDate={selectedDate}
      handleDateChange={handleDateChange}
      selectedLocation={selectedLocation}
      handleMonthlyDataClick={handleMonthlyDataClick}
      total={total}
    />
  );
}

export default MetricsSnapshotContainer;
