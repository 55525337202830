import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  emailConfirmation: yup
    .string()
    .email()
    .required()
    .oneOf([ yup.ref('email'), null ], 'emails must match'),
  password: yup
    .string()
    .min(6)
    .required(),
  passwordConfirmation: yup
    .string()
    // .min(6)
    .required()
    .oneOf([ yup.ref('password'), null ], 'passwords must match'),
});

export default validationSchema;
