const types = {
  /* General */
  DESTROY_ALL: null,
  SET_LOADING: null,

  /* User/Auth */
  SET_AUTH: null,
  SET_UNAUTH: null,
  SET_USER: null,

  /* Notifications */
  SET_SUCCESS: null,
  SET_ERROR: null,
  SET_WARNING: null,
  CLEAR_SUCCESS: null,
  CLEAR_ERROR: null,
  CLEAR_WARNING: null,

  /* Location */
  SET_LOCATION: null,
  CLEAR_LOCATION: null,

};

/* mirror keys/values */
Object.keys(types).forEach(type => {
  types[type] = type;
});

export default types;
