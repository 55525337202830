import React, { useEffect, useState } from 'react';

/* components */

function NewLocation() {

  /* on mount */
  useEffect(() => {

  }, [ ]);

 
  return (
    <div style={{ width: '80%', marginLeft: '10%', textAlign: 'center' }}>
      <h1 style={{ width: '80%', marginLeft: '10%', fontSize: '2rem', textAlign: 'center' }}>
        Want to request an additional location? <br/> Give us a call at (248)-787-6898
      </h1>
      <h1><a className="mailto" href="mailto:tony@latitudedigital.com">Or send us an email: tony@latitudedigital.com</a></h1>
    </div>
  );
}

export default NewLocation;
