import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import mergeWith from 'lodash/mergeWith';
import jstz from 'jstz';

/* components */
import { setReduxLocation } from 'state/actions';
import JoinView from './Join.view';
import snapshotToArray from 'common/snapshotToArray';
import StringHelper from 'common/stringHelper';
import StyleHelper from 'common/styleHelper';
import DataHelper from 'common/dataHelper';
import style from './_Join.module.scss';
import moment from 'moment';

const mergeNonNull = (objValue, srcValue) => srcValue === null ? objValue : undefined;


function JoinContainer(props) {
  /* redux state */
  const state = useSelector(state => ({
    location: state.location,
  }));

  const dispatch = useDispatch();

  const [ startTime, setStartTime ] = useState({
    hour: 0,
    minute: 0,
  });
  const [ endTime, setEndTime ] = useState({
    hour: 0,
    minute: 0,
  });
  const [ waitLimit, setWaitLimit ] = useState(5);
  const [ connectionError, setConnectionError ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  const [ phone, setPhone ] = useState({
    value: '',
    error: '',
  });
  const [ name, setName ] = useState({
    value: '',
    error: '',
  });
  const [ email, setEmail ] = useState({
    value: '',
    error: '',
  });
  const [ partySize, setPartySize ] = useState(1);
  const [ guestWaitingCount, setGuestWaitingCount ] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [ nextPosition, setNextPosition ] = useState(1);
  const [ withinCapMargin, setWithinCapMargin ] = useState(false);
  const [ customValues, setCustomValues ] = useState({});
  const [ showCovidScreen, setShowCovidScreen ] = useState(false);
  const [ covidScreenAnswers, setCovidScreenAnswers ] = useState({
    symptoms: false,
    physicalContact: false,
    isolating: false,
    waitingOnTest: false,
  });
  const [ screenPassed, setScreenPassed ] = useState(false);
  const [ disabled, setDisabled ] = useState(false);
  const [ locationIsOpen, setLocationIsOpen ] = useState(true);
  const [ locationOpenTime, setLocationOpenTime ] = useState({
    hour: 0,
    minute: 0,
  });

  function handleSetPhone(newValues) {
    return setPhone({
      ...phone,
      ...newValues,
    });
  }

  function handleSetName(newValues) {
    return setName({
      ...name,
      ...newValues,
    });
  }

  function handleSetEmail(newValues) {
    return setEmail({
      ...email,
      ...newValues,
    });
  }

  function handleSetCustom(newValues) {
    return setCustomValues({
      ...customValues,
      ...newValues,
    });
  }
  
  const { location_id } = useParams();

  useEffect(() => {
    if (!state.location.location_id) {
      // look up the location if they came here directly
      setLoading(true);
      window.firebase.firestore().doc(`locations/${location_id}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            // location_id doesn't exist, send them home
            return props.history.replace(`/`);
          }
          
          const location = doc.data();
          setDisabled(doc.data().disabled || false);
          if (location.covidScreenRequired && !screenPassed) {
            setShowCovidScreen(true);
            dispatch(setReduxLocation(location));
            setLoading(false);
            return props.history.push(`/join/${location.location_id}`);
          }
          // if the store has at least {capacity_margin} spots left, let them right in.
          if (location.capacity_margin && ((location.capacity - location.population) > location.capacity_margin)) {
            setWithinCapMargin(true);
            const doJoinLine = window.firebase.functions().httpsCallable('joinLine');
            window.firebase.auth().signInAnonymously()
              .then(() => {
                window.firebase.firestore().collection('metadata')
                  .doc(window.firebase.auth().currentUser.uid)
                  .set({
                    location_id,
                  });

                doJoinLine({
                  'firebase_uid': window.firebase.auth().currentUser.uid,
                  location_id,
                  'joined': new Date().getTime(),
                  'allowed': Date.now(),
                  'checked_in': Date.now(),
                  'entered_within_margin': true,
                })
                  .then(() => {
                    console.log('guest joined line', window.firebase.auth().currentUser.uid);
                  })
                  .catch((e) => {
                    setSubmitting(false);
                    console.log('joinLine - join transaction error:', e);
                  });
              })
              .catch((e) => {
                setSubmitting(false);
                console.log('joinLine - firebase.auth().signInAnonymously() error:', e);
              });
          } else {
            setWithinCapMargin(false);
          }
          
          dispatch(setReduxLocation(location));

          setLoading(false);

          return props.history.push(`/join/${location.location_id}`);
        })
        .catch(e => {
          setConnectionError(true);
          // setLoading(false);
          console.log(e);
        });
    }
    window.firebase.firestore().doc(`locations/${location_id}`)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          // location_id doesn't exist, send them home
          return props.history.replace(`/`);
        }
        const location = doc.data();
        setDisabled(doc.data().disabled || false)
        if (location.covidScreenRequired && !screenPassed) {
          setShowCovidScreen(true);
          dispatch(setReduxLocation(location));
          setLoading(false);
          return props.history.push(`/join/${location.location_id}`);
        }
        // if the store has at least {capacity_margin} spots left, let them right in.
        if (location.capacity_margin && ((location.capacity - location.population) > location.capacity_margin)) {
          setWithinCapMargin(true);
          const doJoinLine = window.firebase.functions().httpsCallable('joinLine');
          window.firebase.auth().signInAnonymously()
            .then(() => {
              window.firebase.firestore().collection('metadata')
                .doc(window.firebase.auth().currentUser.uid)
                .set({
                  location_id,
                });

              doJoinLine({
                'firebase_uid': window.firebase.auth().currentUser.uid,
                location_id,
                'joined': new Date().getTime(),
                'allowed': Date.now(),
                'checked_in': Date.now(),
                'entered_within_margin': true,
              })
                .then(() => {
                  console.log('guest joined line', window.firebase.auth().currentUser.uid);
                })
                .catch((e) => {
                  setSubmitting(false);
                  console.log('joinLine - join transaction error:', e);
                });
            })
            .catch((e) => {
              setSubmitting(false);
              console.log('joinLine - firebase.auth().signInAnonymously() error:', e);
            });
        } else {
          setWithinCapMargin(false);
        }
      })
      .catch(e => {
        setConnectionError(true);
        // setLoading(false);
        console.log(e);
      });

    const locationRef = window.firebase.firestore().doc(`locations/${location_id}`);
    const queueRef = window.firebase.firestore().collection(`queues/${location_id}/guests`)
      .orderBy('priority', 'desc')
      .orderBy('queue_id');

    const locationRefOff = locationRef.onSnapshot((snapshot) => {
      setDisabled(snapshot.data().disabled || false);
      setStartTime({
        ...startTime,
        ...snapshot.data().line_start_time,
      });

      setEndTime({
        ...endTime,
        ...snapshot.data().line_end_time,
      });

      if (moment()
        .isBefore(moment().tz('America/New_York')
          .set(snapshot.data().location_open_time))) {
        setLocationIsOpen(false);
        setLocationOpenTime({
          ...snapshot.data().location_open_time,
        });
      }

      setWaitLimit(snapshot.data().wait_limit || 5);
    });

    const queueRefOff = queueRef.onSnapshot((snapshot) => {
      const queue = snapshotToArray(snapshot);
      // start counting stuff
      let tempPeopleInQueue = 0;
      let tempNextPosition = 1;

      // loop queue so we can see what's up
      for (let i = 0; i < queue.length; i++) {
        const thisGuest = queue[i];

        // get line count
        if (!thisGuest.checked_in && !thisGuest.checked_out) {
          tempPeopleInQueue += thisGuest.party_size;
        }

        // find next queue_id
        if (tempNextPosition <= thisGuest.queue_id) {
          tempNextPosition = thisGuest.queue_id + 1;
        }
      }

      // save counts to state
      setGuestWaitingCount(tempPeopleInQueue);
      setNextPosition(tempNextPosition);

      // do some business logic here
      console.log(`Queue data received.`);
      console.log(`People waiting: ${guestWaitingCount}`);
    });

    return () => {
      queueRefOff();
      locationRefOff();
    };
  }, [ showCovidScreen ]);

  function joinLine() {
    setSubmitting(true);
    setLoading(true);
    const doJoinLine = window.firebase.functions().httpsCallable('joinLine');

    window.firebase.auth().signInAnonymously()
      .then(() => {
        window.firebase.firestore().collection('metadata')
          .doc(window.firebase.auth().currentUser.uid)
          .set({
            location_id,
          });

        doJoinLine({
          'firebase_uid': window.firebase.auth().currentUser.uid,
          location_id,
          'party_size': partySize,
          'joined': new Date().getTime(),
          'allowed': null,
          'checked_in': null,
          'checked_out': null,
          // 'queue_id': thisLocation.next_ticket || 0,
          'priority': 0,
          'notes': null,
          'name': name.value,
          'phone_number': phone.value,
          'email': email.value,
          'custom_data': customValues,
          'tz': jstz.determine().name(),
          'wait_limit': waitLimit,
        })
          .then(() => {
            setLoading(false);
            console.log('guest joined line');
            return props.history.push(`/waiting/${location_id}/${window.firebase.auth().currentUser.uid}`);
          })
          .catch((e) => {
            setSubmitting(false);
            setLoading(false);
            console.log('joinLine - join transaction error:', e);
          });
      })
      .catch((e) => {
        setSubmitting(false);
        setLoading(false);
        console.log('joinLine - firebase.auth().signInAnonymously() error:', e);
      });
  }

  function addGuest() {
    return setPartySize(partySize + 1);
  }

  function subtractGuest() {
    setPartySize(partySize - 1);
  }

  function getData(key) {
    const dataHelper = new DataHelper(state.location, state.location.brandDetails);
    return dataHelper.createData(key);
  }

  function evaluateCovidScreen() {
    const answers = Object.values(covidScreenAnswers);
    if (answers.includes(true)) {
      // redirect to sad-path covid screen page
      return props.history.replace('/covid-fail');
    } 
    // let them join like normal 
    setShowCovidScreen(false);
    setScreenPassed(true);
  }

  return (
    <div className={style.containerWrapper} style={{ minHeight: window.innerHeight, overflow: scroll }}>
      <JoinView
        stringHelper={new StringHelper('en', state.location.content, state.location.brandDetails.content)}
        loading={loading}
        connectionError={connectionError}
        submitting={submitting}
        guestWaitingCount={guestWaitingCount}
        joinLine={joinLine}
        partySize={partySize}
        addGuest={addGuest}
        subtractGuest={subtractGuest}
        startTime={startTime}
        endTime={endTime}
        location={state.location}
        brandDetails={mergeWith(state.location.brandDetails, { styles: state.location.styles }, mergeNonNull)}
        styleHelper={new StyleHelper(state.location.brandDetails.styles, state.location.styles)}
        lineLimit={getData('line_limit')}
        phoneRequired={getData('phoneRequired')}
        nameRequired={getData('nameRequired')}
        emailRequired={getData('emailRequired')}
        email={email}
        handleSetEmail={handleSetEmail}
        customQuestions={getData('customQuestions')}
        maxParty={getData('party_limit') || 5}
        name={name}
        handleSetName={handleSetName}
        handleSetCustom={handleSetCustom}
        phone={phone}
        handleSetPhone={handleSetPhone}
        withinCapMargin={withinCapMargin}
        showCovidScreen={showCovidScreen}
        setShowCovidScreen={setShowCovidScreen}
        covidScreenAnswers={covidScreenAnswers}
        setCovidScreenAnswers={setCovidScreenAnswers}
        evaluateCovidScreen={evaluateCovidScreen}
        disabled={disabled}
        locationIsOpen={locationIsOpen}
        locationOpenTime={locationOpenTime}
      />

      <div className={style.footerContent}>
        <div className={style.privacy_footer_content}>
          &copy; {moment().year()} Latitude Digital&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/privacy'} target={'_blank'} className={`ld_cursor_pointer`}>Privacy Policy</Link>
        </div>
      </div>

    </div>
  );
}

export default JoinContainer;
