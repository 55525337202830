import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Link, Redirect } from 'react-router-dom';


/* data management */
import PropTypes from 'prop-types';

/* components */
import JolHeaderLogo from 'common/jolHeaderLogo';
import InputWrapper from 'components/InputWrapper';
import style from './_SignIn.module.scss';

import {
  Icon,
  Input,
} from 'antd';

/**
 * SignIn view
 * @props {
 *   loading __ "true" when waiting for authentication response
 *   form __ output data & methods from react-hook-form
 *   onSubmit __ handles authentication request/response
 * }
 */
function SignInView(props) {
  const {
    /* data */
    loading,
    alertVisible,
    emailMessage,
    redirectToReferrer,
    isClient,
    currentUserRole,
    connectionError,

    /* utils */
    form,
    formData,

    /* methods */
    onSubmit,
    sendPasswordResetEmail,
  } = props;
  
  if (redirectToReferrer) {
    if (isClient) {
      return <Redirect to={`/add-location`}/>;
    } else if (!isClient && (currentUserRole !== 'brand_manager' && currentUserRole !== 'regional_manager')) {
      return <Redirect to={`/admin`}/>;
    } else if (currentUserRole === 'regional_manager' || currentUserRole === 'brand_manager') {
      return <Redirect to={`/admin/metrics_snapshot`}/>;
    }
    return <Redirect to={`/admin/locations`}/>;
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        {/* <JolHeaderLogo /> */}
        <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
      </div>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={style.form_container}
      >

        <InputWrapper
          name="email"
          placeholder="Email"
          component={<Input type={'email'} />}
          form={form}
          containerClassName={style.form_column}
        />

        <InputWrapper
          name="password"
          placeholder="Password"
          component={<Input
            type={'password'}
          />}
          form={form}
          containerClassName={style.form_column}
        />

        {
          alertVisible && !connectionError
            ?  <div className={style.error}>
              <p>Unable to log in.<br/>
              Please make sure you have an active Join Our Line account, or call <a href={'tel:7207982211'} target={'_blank'}>(720) 798-2211</a> for support.</p>
            </div>
            : null
        }
        {
          alertVisible && connectionError
            ?  <div className={style.error}>
              <p>Connection error.<br/>
              Please make sure your device is connected to the internet, or call <a href={'tel:7207982211'} target={'_blank'}>(720) 798-2211</a> for support.</p>
            </div>
            : null
        }


        <div className={style.submit_button}>
          {
            loading ?
              <Icon
                spin
                type="loading"
                style={{ fontSize: 20, color: '#007bff' }}
              />
              :  <Button
                disabled={loading}
                primary={true}
                style={{ width: '100%', backgroundColor: '#129A7F', border: 'none' }}
              >
                Sign In
              </Button>
          }

        </div>

      </form>

      {
        emailMessage
          ? <p>{emailMessage}</p>
          : <p>Forgot your password? Click <a href='#' onClick={() => sendPasswordResetEmail(formData.email)} style={{ color: '#129A7F' }}>here</a> to reset it.</p>
      }

      {/* <p className={style.signup_btn}>
        <Link to='/sign-up' style={{ color: '#129A7F' }}>New to JOL? Click here to sign up.</Link>
      </p> */}
      <p>Need Technical Support? Call (720)-798-2211</p>

    </div>
  );
}

SignInView.propTypes = {
  /* data */
  loading: PropTypes.bool.isRequired,

  /* utils */
  form: PropTypes.object.isRequired,

  /* methods */
  onSubmit: PropTypes.func.isRequired,
};

export default SignInView;
