import React from 'react';
import { Button } from '@progress/kendo-react-buttons';

/* data management */
import PropTypes from 'prop-types';

/* components */
import JolHeaderLogo from 'common/jolHeaderLogo';
import InputWrapper from 'components/InputWrapper';
import style from './_SignUp.module.scss';
import { Link } from 'react-router-dom';

import {
  Icon,
  Input,
} from 'antd';

function SignUpView(props) {
  const {
    /* data */
    loading,
    alertVisible,
    emailMessage,
    signUpError,

    /* utils */
    form,
    formData,

    /* methods */
    onSubmit,
    sendPasswordResetEmail,
  } = props;

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        {/* <JolHeaderLogo fill='black' /> */}
        <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
      </div>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={style.form_container}
      >
        <h2 className={style.signup_header}>Welcome! <br />
        Please fill out the form below to begin the sign up process.</h2>
        <div style={{ border: '3px solid #ff6161', borderRadius: '4px' }}>
          <h3 style={{ color: '#ff6161', textAlign: 'center', fontWeight: 700, marginBottom: 0, paddingBottom: 0 }}>PLEASE READ</h3>
          <p style={{ color: '#ff6161' }}>Are you attempting to join a line? You do not need to sign up. Please <a href='https://joinourline.com'>click here</a> to go back.</p>
        </div>
        <p style={{ color: '#129A7F' }}>If you are wanting to sign up your store/office/location, please continue below.</p>
        <InputWrapper
          name="email"
          placeholder="Email"
          component={<Input type={'email'} />}
          form={form}
          containerClassName={style.form_column}
        />

        <InputWrapper
          name="emailConfirmation"
          placeholder="Confirm Email"
          component={<Input type={'email'} />}
          form={form}
          containerClassName={style.form_column}
        />

        <InputWrapper
          name="password"
          placeholder="Password"
          component={<Input
            type={'password'}
          />}
          form={form}
          containerClassName={style.form_column}
        />

        <InputWrapper
          name="passwordConfirmation"
          placeholder="Confirm Password"
          component={<Input
            type={'password'}
          />}
          form={form}
          containerClassName={style.form_column}
        />

        {
          alertVisible
            ?  <div className={style.error}>
              <p>Unable to log in.<br/>
              Please make sure you have an active Join Our Line account, or call <a href={'tel:2487876898'} target={'_blank'}>(248) 787-6898</a> for support.</p>
            </div>
            : null
        }

        {
          signUpError !== ''
            ?  <div className={style.error}>
              <p>Sign-Up Error:<br/>
                {signUpError} <br /> Call <a href={'tel:2487876898'} target={'_blank'}>(248) 787-6898</a> for support.</p>
            </div>
            : null
        }


        <div className={style.submit_button}>
          {
            loading ?
              <Icon
                spin
                type="loading"
                style={{ fontSize: 20, color: '#007bff' }}
              />
              :  <Button
                disabled={loading}
                primary={true}
                style={{ width: '100%', backgroundColor: '#129A7F', border: 'none' }}
              >
                Sign Up
              </Button>
          }

        </div>

      </form>

      <p className={style.signup_btn}>
        <Link to='/sign-in' className={style.link_btn} >Already have an account? Click here to login.</Link>
      </p>

    </div>
  );
}

SignUpView.propTypes = {
  /* data */
  loading: PropTypes.bool.isRequired,

  /* utils */
  form: PropTypes.object.isRequired,

  /* methods */
  onSubmit: PropTypes.func.isRequired,
};

export default SignUpView;

