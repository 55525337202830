// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._Brands-module__container___m1sn_{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-flex:1;flex:1 1}._Brands-module__container___m1sn_ ._Brands-module__header_container___2KE-p{display:-webkit-box;display:flex;padding:30px 0}._Brands-module__container___m1sn_ ._Brands-module__header_container___2KE-p h1{align-self:center;margin:0;text-align:center;font-size:18px}\n", ""]);
// Exports
exports.locals = {
	"container": "_Brands-module__container___m1sn_",
	"header_container": "_Brands-module__header_container___2KE-p"
};
module.exports = exports;
