
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

/* data management */
import LOADING_KEY from 'definitions/keys/loading.keys';
import { signinAuth, verifyAuth } from 'state/actions';
import createDispatchedActions from 'utils/createDispatchedActions';
import validationSchema from './validationSchema';

/* components */
import SignUpView from './SignUp.view';

/**
 * SignUp container
 * - manage form data
 * - handle initial authentication
 * - render dedicated view component
 */
function SignUpContainer(props) {
  const attemptSignUp = window.firebase.functions().httpsCallable('attemptSignUp');
  const updateClientUserClaims = window.firebase.functions().httpsCallable('updateClientUserClaims');

  /* create dispatched actions */
  const actions = createDispatchedActions(signinAuth, verifyAuth);

  /* redux state */
  // const {
  //   loading,
  // } = useSelector(state => ({
  //   authenticated: state.authenticated,
  //   loading: state.loading[LOADING_KEY.auth],
  // }));

  // /* local state */
  const [ redirectToReferrer, setRedirectToReferrer ] = useState(false);
  const [ emailMessage, setEmailMessage ] = useState('');
  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ currentUid, setCurrentUid ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ signUpError, setSignUpError ] = useState('');

  /* local form state */
  const form = useForm({
    validationSchema,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  let unsubscribe;

  /* on mount */
  useEffect(() => {
    unsubscribe = window.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setCurrentUid(user.uid);
        actions.verifyAuth()
          .then(() => {
            setRedirectToReferrer(true);
          })
          .catch(error => {
            console.log('error', error);
          });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);


  /* container methods */
  const updateUser = async (uid) => {
    await updateClientUserClaims({ uid, user_role: 'client' })
      .then((updatedUser) => {
        console.log('updated user:', updatedUser);
      })
      .catch(e => console.log('error updating claims:', e));
  };
  const methods = {
    onSubmit(data) {
      setLoading(true);
      const userAuthData = {
        email: data.email,
        password: data.password,
      };
      return attemptSignUp(userAuthData)
        .then(async (newUser) => {
          if (newUser.data.errorInfo) {
            // return setCreateUserError(`Could not create a new user: ${newUser.data.errorInfo.message}`);
            console.log(`Could not create a new user: ${newUser.data.errorInfo.message}`);
            setSignUpError(`Could not create a new user: ${newUser.data.errorInfo.message}`);
            setLoading(false);
            return;
          }
          // TODO: add custom claim to new user, sign them in, navigate to add-location
          console.log(`created user!!!!: ${newUser.data.uid}`);
          await updateUser(newUser.data.uid);
          setCurrentUid(newUser.data.uid);
          unsubscribe && unsubscribe();
          actions.signinAuth(userAuthData)
            .then(() => {
              setAlertVisible(false);
              setErrorMessage('');
              setRedirectToReferrer(true);
              setLoading(false);
            })
            .catch(error => {
              console.log('error', error);
              handleLogInError(error);
            });
        })
        .catch(e => console.log('error signing up:', e));
    },
    

    sendPasswordResetEmail(emailAddress) {
      window.firebase.auth().sendPasswordResetEmail(emailAddress)
        .then(() => {
          setEmailMessage(`Success! We sent an email to ${emailAddress}`);
        })
        .catch((error) => {
          setEmailMessage(`Oops! Something went wrong. ${error}`);

          setTimeout(() => {
            setEmailMessage('');
          }, 5000);
        });
    },
  };

  if (redirectToReferrer) {
    return <Redirect to={`/add-location/${currentUid}`}/>;
  }

  function handleLogInError(error) {
    setAlertVisible(true);
    setErrorMessage(error);
  }

  return (
    <SignUpView
      form={form}
      formData={form.watch()}
      loading={loading}
      onSubmit={methods.onSubmit}
      signUpError={signUpError}
      emailMessage={emailMessage}
      alertVisible={alertVisible}
      errorMessage={errorMessage}
      sendPasswordResetEmail={methods.sendPasswordResetEmail}
    />
  );
}

export default SignUpContainer;

