// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._Privacy-module__containerWrapper___1Xucm{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-pack:justify;justify-content:space-between;overflow:scroll}._Privacy-module__containerWrapper___1Xucm ._Privacy-module__container___35URP{width:100%;padding:5px 10px;align-self:center}._Privacy-module__containerWrapper___1Xucm ._Privacy-module__container___35URP ._Privacy-module__header___jrAQl{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;margin:10px;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center}._Privacy-module__containerWrapper___1Xucm ._Privacy-module__container___35URP ._Privacy-module__header___jrAQl *{height:45px;padding:9.6px;padding:.6rem}._Privacy-module__containerWrapper___1Xucm ._Privacy-module__container___35URP ._Privacy-module__mainContent___32jc8{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;width:100%;text-align:left;-webkit-box-align:center;align-items:center}._Privacy-module__containerWrapper___1Xucm ._Privacy-module__container___35URP p{text-align:left !important;align-self:baseline}._Privacy-module__containerWrapper___1Xucm ._Privacy-module__container___35URP ul{list-style-type:none !important;text-align:left !important}\n", ""]);
// Exports
exports.locals = {
	"containerWrapper": "_Privacy-module__containerWrapper___1Xucm",
	"container": "_Privacy-module__container___35URP",
	"header": "_Privacy-module__header___jrAQl",
	"mainContent": "_Privacy-module__mainContent___32jc8"
};
module.exports = exports;
