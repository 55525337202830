import React from 'react';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Icon, Modal } from 'antd';
import isEmpty from 'lodash/isEmpty';

import InputWrapper from 'components/InputWrapper';
import style from './_EditBrand.module.scss';

function EditBrandView(props) {
  const {
    /* data */
    fields,
    setLocalDirty,
    newBrand,
    history,
    processing,
    brandError,
    displayDeleteBrand,

    /* utils */
    form,

    /* methods */
    onSubmit,
    setDisplayDeleteBrand,
    deleteBrand,
  } = props;

  function header() {
    if (newBrand) {
      return (
        <h1>Add New Brand</h1>
      );
    }
    return (
      <h1>{fields.name}</h1>
    );
  }

  // default values won't rerender on update -- need to set defaults from Firebase first
  if (isEmpty(fields)) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.header_container}>
        <Button
          primary={false}
          onClick={() => history.push(`/admin/brands`)}
        >
          Go Back
        </Button>
        <Button
          primary={true}
          disabled={processing}
          // enable line below if we want to disable deleting for users with same role
          // hidden={fields.user_role === loggedInUser}
          hidden={newBrand}
          onClick={() => {
            console.log('delete location clicked');
            setDisplayDeleteBrand(true);
          }}
          style={{ backgroundColor: '#ff6161', border: 'none', marginTop: '1rem', marginLeft: '2rem', marginRight: '2rem', width: '150px' }}
        >
          Delete Brand
        </Button>
        {header()}
      </div>

      <form onSubmit={form.handleSubmit(onSubmit)} >
        <div className={style.formWrapper}>

          <div className={style.column}>
            <InputWrapper
              component={<Input />}
              name="brand_id"
              label='Brand ID'
              form={form}
              defaultValue={fields.brand_id}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="name"
              label='Brand Name'
              form={form}
              defaultValue={fields.name}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="logo"
              label='Logo'
              form={form}
              defaultValue={fields.logo}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<NumericTextBox />}
              name="line_limit"
              label='Line Limit'
              form={form}
              defaultValue={fields.line_limit}
              onChange={() => {
                setLocalDirty(true);
              }}
              className={style.dynamic_width}
              min={0}
            />
          </div>

          <div className={style.column}>
            <label htmlFor={'content'}>
              Content
              <InputWrapper
                component={<textarea />}
                name="content"
                form={form}
                defaultValue={fields.content}
                onChange={() => {
                  setLocalDirty(true);
                }}
                style={{ minHeight: '200px', width: '100%', padding: '5px' }}
              />
            </label>
            <label htmlFor={'styles'}>
              Styles
              <InputWrapper
                component={<textarea />}
                name="styles"
                form={form}
                defaultValue={fields.styles}
                onChange={() => {
                  setLocalDirty(true);
                }}
                style={{ minHeight: '200px', width: '100%', padding: '5px' }}
              />
            </label>
          </div>

        </div>

        {
          brandError
            ? <div className={style.field_error} style={{ textAlign: 'left' }}>{brandError}</div>
            : null
        }

        {
          processing
            ? <div style={{ width: '100%', textAlign: 'center', paddingTop: 100 }}>
              <Icon
                spin
                type="loading"
                style={{ fontSize: 20, color: '#002E6D' }}
              />
            </div>
            : null
        }

        <Button
          disabled={processing}
          primary={true}
        >
          Save
        </Button>
        <Modal
          className={style.delete_user_modal}
          visible={displayDeleteBrand}
          centered={true}
          destroyOnClose={true}
          closable={true}
          maskClosable={true}
          okText={'Yes, Delete Brand'}
          onOk={() => deleteBrand(fields.brand_id)}
          cancelText={'No, Go Back'}
          onCancel={() =>  setDisplayDeleteBrand(false)}
        >
          <h4 style={{ textAlign: 'center', fontSize: '1rem', marginTop: '.5rem' }}>Please confirm the deletion of this brand:<h4 style={{ textAlign: 'center', fontSize: '1rem', margin: 0, marginBottom: '1rem', marginTop: '1rem', color: '#ff6161' }}>{`${fields.brand_id}`}</h4>This action cannot be undone.</h4>
        </Modal>
      </form>
    </div>
  );
}

export default EditBrandView;
