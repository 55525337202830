import React, { useEffect } from 'react';

/* data management */
import LOADING_KEY from 'definitions/keys/loading.keys';
import { useSelector } from 'react-redux';

/**
 * RoleHawk
 * - watch authentication and verify if need ( on refresh/update )
 *
 * @param Resource{node}
 * @param permittedUserRoles{array.<string>}
 *
 */
function RoleHawk(Resource, permittedUserRoles) {
  let restricted = true;

  return function(props) {
    /* redux state */
    const state = useSelector(state => ({
      user: state.user,
      loading: state.loading[LOADING_KEY.auth],
      authenticated: state.authenticated,
    }));


    /* internal methods */
    const internalMethods = {

      handleRoleCheck() {
        console.log('\nRoleHawk.handleRoleCheck');
        if (state.loading || !state.authenticated || !state.user.user_role) {
          console.log('- required data not yet available');
          return;
        }

        if (!permittedUserRoles.includes(state.user.user_role)) {
          // console.log(`- ${state.user.name} is NOT permitted to see ${Resource.name}`);
          // could redirect if needed?
          return;
        }

        restricted = false;
      },

    };

    /* lifecycle */
    useEffect(
      () => {
        console.log('\nRoleHawk.lifecycle - on UPDATE');
        internalMethods.handleRoleCheck();
      },
      [
        props.location.pathname,
      ]
    );

    /* render */
    if (restricted) {
      return (
        <h1>
          You are not permitted to see this page
        </h1>
      );
    }

    return (
      <Resource
        {...props}
      />
    );
  };
}

export default RoleHawk;
