import types from '../types';

import LOADING_KEY from 'definitions/keys/loading.keys';

export function setAuthLoading(isLoading) {
  return setDataLoading(LOADING_KEY.auth, isLoading);
}

function setDataLoading(key, isLoading) {
  return {
    type: types.SET_LOADING,
    payload: {
      [key]: isLoading,
    },
  };
}
