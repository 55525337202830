import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import JolHeaderLogo from 'common/jolHeaderLogo';
import style from './_CheckIn.module.scss';

function CheckInView(props) {
  const {
    guest,
    brandDetails,
    handleSignOut,
    stringHelper,
    styleHelper,
    location,
  } = props;

  function getBoxStyle() {
    let key = 'boxColor';
    if (guest.priority) {
      key = 'boxColorPriority';
    }
    return styleHelper.createStyle(key);
  }

  function getFontStyle() {
    let key = 'fontColor';
    if (guest.priority) {
      key = 'fontColorPriority';
    }
    return styleHelper.createStyle(key);
  }

  function renderPage() {
    if (guest.checked_in) {
      return (
        <div style={{ padding: '2rem' }}>
          { stringHelper.createDivWithString({
            className: style.socialDistance,
          },
          'checkin-social-distancing'
          )}
        </div>
      );
    }

    if (guest.checked_out) {
      return (
        <p>
          <Link to={`/`} onClick={() => handleSignOut()} className={`ld_cursor_pointer`}>{stringHelper.createString('checkin-expired')}</Link>
        </p>
      );
    }

    return (
      <div>
        <p className={style.prompt}>
          {stringHelper.createString('checkin-instructions-header')}<br />
          {stringHelper.createString('checkin-instructions')}<br />
        </p>
        <p className={style.number} style={{ backgroundColor: getBoxStyle(), color: getFontStyle() }}>{guest.queue_id}</p>
        <p>{stringHelper.createString('checkin-expire-instructions', moment(guest.allowed).add(guest.wait_limit || 5, 'minutes')
          .format('h:mm A')) }</p>
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.logoContainer} style={{ backgroundColor: styleHelper.createStyle('defaultLogoColor') }}>
        {
          brandDetails.logo ? 
            <img src={brandDetails.logo} style={{ maxWidth: '256px' }} />
            : 
            <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
        }
      </div>

      {renderPage()}

    </div>
  );
}

export default CheckInView;
