import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Location Name is required')
    .label('Location Name')
    .required(),
  address1: yup
    .string()
    .typeError('Address is required')
    .label('Address')
    .required(),
  city: yup
    .string()
    .typeError('City is required')
    .label('City')
    .required(),
  state: yup
    .string()
    .length(2)
    .typeError('State is required')
    .label('State')
    .required(),
  zip_code: yup
    .string()
    .typeError('Zip Code is required')
    .label('Zip Code')
    .required(),
  party_limit: yup
    .number()
    .nullable()
    .label('Party Limit')
    .lessThan(yup.ref('capacity'), "party limit must be less than capacity."),
  distance: yup
    .number()
    .max(1610)
    .required(),
  capacity: yup
    .number()
    .min(1)
    .required(),
  capacity_margin: yup
    .number()
    .min(0)
    .required()
    .lessThan(yup.ref('capacity'), "capacity margin must be less than capacity."),
  population: yup
    .number()
    .min(0)
    .max(yup.ref('capacity'), "population must be less than capacity."),
});

export default validationSchema;
