import mergeWith from 'lodash/mergeWith';

const defaultState = {
  line_limit: 500,
};

const mergeNonNull = (objValue, srcValue) => srcValue === null ? objValue : undefined;

class DataHelper {
  constructor(location, brand) {
    this.dataValues = mergeWith(defaultState, brand, location, mergeNonNull);
  }

  createData = (key) => {
    let data = this.dataValues[key];

    if (!data) {
      data = null;
    }

    return data;
  }
}

export default DataHelper;

