const USER_ROLE = {
  admin: null,
  door_admin: null,
  client: null,
  brand_manager: null,
  regional_manager: null,
  store_manager: null,
};

/* mirror keys/values */
Object.keys(USER_ROLE).forEach(role => {
  USER_ROLE[role] = role;
});

export default USER_ROLE;
