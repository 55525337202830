const { warn } = console;

console.warn = function (...args) {
  for (const arg of args) {
    if (arg.includes('https://fb.me/react-unsafe-component-lifecycles')) {
      return;
    }
  }
  warn(...args);
};
