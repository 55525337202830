import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import style from './_ClientEditLocation.module.scss';

/* data management */
import validationSchema from './validationSchema';

/* components */
import ClientEditLocationView from './ClientEditLocation.view';

function ClientEditLocationContainer(props) {
  const {
    match: {
      params: {
        location_id,
      },
    },
  } = props;

  // /* redux state */
  const state = useSelector(state => ({
    user: state.user,
  }));

  /* local state */
  const [ emailMessage, setEmailMessage ] = useState('');
  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ locationExists, setLocationExists ] = useState(false);
  const [ localDirty, setLocalDirty ] = useState(false);
  const [ fields, setFields ] = useState({});
  const [ newLocation, setNewLocation ] = useState(false);
  const [ locationError, setLocationError ] = useState(null);
  const [ processing, setProcessing ] = useState(false);
  const [ currentUid, setCurrentUid ] = useState('');
  const [ locationId, setLocationId ] = useState('');
  const [ mapVisible, toggleMapVisible ] = useState(false);
  const [ preciseLat, setPreciseLat ] = useState(0);
  const [ preciseLong, setPreciseLong ] = useState(0);
  const [ locationData, setLocationData ] = useState({});
  const [ isLoading, setLoading ] = useState(false);

  /* local form state */
  const form = useForm({
    validationSchema,
  });

  useEffect(() => {
    const locationRef = state.user.location_id ? window.firebase.firestore().doc(`locations/${state.user.location_id}`) : window.firebase.firestore().doc(`locations/${location_id}`) ;
    const locationRefOff = locationRef.onSnapshot((snapshot) => {
      if (!snapshot.data()) {
        return;
      }

      const locationValues = snapshot.data();
      const hasAddress = locationValues && locationValues.address;
      const hasOpenTime = locationValues && locationValues.location_open_time;
      const hasStartTime = locationValues && locationValues.line_start_time;
      const hasEndTime = locationValues && locationValues.line_end_time;
      
      setFields({
        ...locationValues,
        address1: hasAddress ? hasAddress.address1 : null,
        address2: hasAddress ? hasAddress.address2 : null,
        city: hasAddress ? hasAddress.city : null,
        state: hasAddress ? hasAddress.state : null,
        zip_code: hasAddress ? hasAddress.zip_code : null,
        content: locationValues.content ? JSON.stringify(locationValues.content) : null,
        styles: locationValues.styles ? JSON.stringify(locationValues.styles) : null,
        location_open_time: hasOpenTime
          ? new Date(null, null, null, locationValues.location_open_time.hour, locationValues.location_open_time.minute, 0)
          : null,
        line_start_time: hasStartTime
          ? new Date(null, null, null, locationValues.line_start_time.hour, locationValues.line_start_time.minute, 0)
          : null,
        line_end_time: hasEndTime
          ? new Date(null, null, null, locationValues.line_end_time.hour, locationValues.line_end_time.minute, 0)
          : null,
      });
    });

    return () => {
      locationRefOff();
    };
  }, [  ]);

  /* container methods */
  const methods = {
    onSubmit(formData) {
      console.log('form data:', formData);
      setLoading(true);
      const removeProperties = ({ address1, address2, city, zip_code, state, location_open_time, line_start_time, line_end_time, ...rest }) => rest;
      const address = {
        address1: formData.address1,
        address2: formData.address2 || null,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zip_code,
      };
      const location_open_time = {
        hour: formData.location_open_time ? formData.location_open_time.getHours() : null,
        minute: formData.location_open_time ? formData.location_open_time.getMinutes() : null,
      };
      const line_start_time = {
        hour: formData.line_start_time ? formData.line_start_time.getHours() : null,
        minute: formData.line_start_time ? formData.line_start_time.getMinutes() : null,
      };
      const line_end_time = {
        hour: formData.line_end_time ? formData.line_end_time.getHours() : null,
        minute: formData.line_end_time ? formData.line_end_time.getMinutes() : null,
      };
      const partlyFormattedData = {
        address,
        location_id: fields.location_id,
        location_open_time,
        line_start_time,
        line_end_time,
        waiting: 0,
        ...removeProperties(formData),
      };
      return methods.formatLocationData(partlyFormattedData);
    },

    async formatLocationData(rawData) {
      const {
        address1,
        address2,
        city,
        state,
        zip_code
      } = rawData.address;
      // get lat and long and trigger rest of func after promise resolves
      const addressString = address2 ? `${address1}${` ${address2}`}, ${city} ${state}` : `${address1}, ${city} ${state}`;
      const geocoder = new google.maps.Geocoder();
      return await geocoder.geocode({ 'address': addressString }, async (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const formattedData = {
            ...rawData,
            location_id: rawData.location_id,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
            population: rawData.population || 0,
          };
          // if address data has changed, show map
          if (fields.address.address1 !== address1 || fields.address.address2 !== address2 || fields.address.city !== city || fields.address.state !== state || fields.address.zip_code !== zip_code) {
            toggleMapVisible(true);
            setLocationData(formattedData);
            return methods.createMap(formattedData.latitude, formattedData.longitude);
          } 
          // if it has not changed, hide the map and submit
          setLocationData(formattedData);
          return methods.updateLocation(formattedData);
        }
      });
    },

    generateLocationId(locationName) {
      const removedSpaces = locationName.split(' ').join('');
      const locationId = removedSpaces.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
      return locationId;
    },

    async updateLocation(locationData) {
      return window.firebase.firestore().collection('locations')
        .doc(locationData.location_id)
        .update({
          ...locationData,
        })
        .then(() => {
          console.log('updated location doc');
          if (state.user.user_role === 'brand_manager' || state.user.user_role === 'regional_manager') {
            return props.history.push('/admin/locations');
          }
          return props.history.push(`/admin/dashboard`);
        })
        .catch(e => console.log('error creating location', e));
    },

    createMap(lat, long) {
      setLoading(false);
      const map = new google.maps.Map(document.getElementById('map-div'), {
        center: { lat, lng: long },
        zoom: 16,
      });
      const marker = new google.maps.Marker({
        map,
        position: {
          lat,
          lng: long,
        },
        title: 'Drag me to your exact location.',
        draggable: true,
      });
      google.maps.event.addListener(marker, 'mouseup', ((marker) => {
        setPreciseLat(marker.latLng.lat());
        setPreciseLong(marker.latLng.lng());
      })
      );
    },

    updateLocationData() {
      if (preciseLat !== 0 && preciseLong !== 0) {
        const finalLocationData = {
          ...locationData,
          latitude: preciseLat,
          longitude: preciseLong,
        };
        return methods.updateLocation(finalLocationData);
      }
      return methods.updateLocation(locationData);
    },

    renderError(name) {
      if (form.errors[name]) {
        return (
          <span className={style.field_error}>{form.errors[name].message}</span>
        );
      }
      return (
        <span className={style.field_error}>&nbsp;</span>
      );
    },
  };

  return (
    <ClientEditLocationView
      formData={form.watch()}
      emailMessage={emailMessage}
      alertVisible={alertVisible}
      errorMessage={errorMessage}
      locationExists={locationExists}
      mapVisible={mapVisible}
      toggleMapVisible={toggleMapVisible}
      fields={fields}
      form={form}
      loading={isLoading}
      history={props.history}
      newLocation={newLocation}
      locationError={locationError}
      processing={processing}
      isDoorAdmin={false}
      onSubmit={methods.onSubmit}
      setLocalDirty={setLocalDirty}
      renderError={methods.renderError}
      updateLocationData={methods.updateLocationData}
      currentUserRole={state.user.user_role}
    />
  );
}

export default ClientEditLocationContainer;
