import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Input, NumericTextBox, Switch } from '@progress/kendo-react-inputs';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Icon, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';

/* data management */
import PropTypes from 'prop-types';

/* components */
import JolHeaderLogo from 'common/jolHeaderLogo';
import InputWrapper from 'components/InputWrapper';
import style from './_ClientEditLocation.module.scss';


function ClientEditLocationView(props) {
  const {
    /* data */
    mapVisible,
    loading,
    currentUserRole,
    /* utils */
    form,
    /* methods */
    onSubmit,
    setLocalDirty,
    fields,
    processing,
    isDoorAdmin,
    renderError,
    updateLocationData,
  } = props;
  
  
  // default values won't rerender on update -- need to set defaults from Firebase first
  if (isEmpty(fields)) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  if (mapVisible) {
    return (
      <section className={style.map_section} style={mapVisible ? null : { display: 'none' }}>
        <h4 className={style.map_header}>One More Step:</h4>
        <p className={style.map_text}>Please drag the marker to pinpoint your location on the map to calculate more accurate coordinates.</p>
        <div id='map-div' className={style.map} style={{ width: '400px', height: '350px' }}></div>
        <Button className={style.submit_map} onClick={() => updateLocationData()}>Submit Location</Button>
      </section>
    );
  }

  return (
    <div className={style.container}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={style.form_container}
      >
        <h3 className={style.welcome_text}>Edit Location Details:</h3>
        <p style={{ textAlign: 'center' }}>If you need to make any changes to the details of your location, you can do so below.</p>
        <p style={{ textAlign: 'center', fontWeight: 700, color: '#ff6161' }}>Note: Some fields may be disabled depending on your access level. Contact your higher level management to make changes to disabled fields.</p>
        <div className={style.formWrapper}>
          <div className={style.column}>
            <h3 className={style.column_heading}>Basic Information</h3>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The name of your location. ex: Main Street Grocery' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <InputWrapper
                component={<Input />}
                name="name"
                label="Location Name"
                form={form}
                disabled={currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
                defaultValue={fields.name}
                onChange={() => {
                  setLocalDirty(true);
                }}
              />
            </div>
            <InputWrapper
              component={<Input />}
              name="address1"
              label="Address"
              form={form}
              disabled={currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
              defaultValue={fields.address1}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="address2"
              label="Address Line 2"
              form={form}
              disabled={currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
              defaultValue={fields.address2}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="city"
              label="City"
              form={form}
              disabled={currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
              defaultValue={fields.city}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="state"
              label="State"
              form={form}
              disabled={currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
              defaultValue={fields.state}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="zip_code"
              label="Zip Code"
              form={form}
              disabled={currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
              defaultValue={fields.zip_code}
              onChange={() => {
                setLocalDirty(true);
              }}
            />
          </div>

          <div className={style.column}>
            <h3 className={style.column_heading}>Location Information</h3>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' placement='top' title='The value in meters representing how far away your guests can be from your location before they are allowed to enter the line. Max: 1610m'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label
                className={style.grid_label}
                htmlFor={'distance'}
                style={{ display: isDoorAdmin ? 'none' : 'inherit' }}
              >
                Distance in meters (max: 1610m/1 mile)
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="distance"
                  defaultValue={fields.distance}
                  disabled={currentUserRole === 'door_admin'}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('distance');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('distance')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The total capacity of your business' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'capacity'}>
                Capacity
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="capacity"
                  defaultValue={fields.capacity}
                  disabled={currentUserRole === 'door_admin' || currentUserRole === 'store_manager'}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('capacity');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('capacity')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Capacity-margin is an optional feature. When set to 0 the feature is disabled completely. When enabled (by adding a number greater than 0) the feature allows a location to call customers up immediately, without them having to join the line, until the number of people in the store is equal or greater to the store capacity minus capacity margin. So if a location has a capacity of 50, and a margin capacity of 10, customers will see a “Come on in” message until 40 people are in the store.' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'capacity_margin'}>
                Capacity Margin
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="capacity_margin"
                  defaultValue={fields.capacity_margin}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('capacity_margin');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('capacity_margin')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The maximum number of guests who can join the line.' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'line_limit'}>
                Line Limit
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="line_limit"
                  defaultValue={fields.line_limit}
                  disabled={currentUserRole === 'door_admin'}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('line_limit');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('line_limit')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The maximum number of people who can join in one party (representing one group waiting in line).' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'party_limit'}>
                Party Limit
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="party_limit"
                  defaultValue={fields.party_limit}
                  disabled={currentUserRole === 'door_admin'}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('party_limit');
                  }}
                  className={style.full_width}
                  min={1}
                  max={fields.capacity}
                />
                {renderError('party_limit')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The amount of time a guest has to enter the location once they are told it is their turn to come in before they are kicked from the line.' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'wait_limit'}>
                Wait Timeout
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="wait_limit"
                  defaultValue={fields.wait_limit}
                  disabled={currentUserRole === 'door_admin'}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('wait_limit');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('wait_limit')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Toggle if door minders will be required to enter the phone number of a guest who is added to the line manually.' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'manual_add'}>
                Phone Required <br /> on Manual Add
                <Switch
                  ref={form.register}
                  disabled={currentUserRole !== 'brand_manager' && currentUserRole !== 'admin' && currentUserRole !== 'store_manager'}
                  form={form}
                  name="phoneRequiredManual"
                  label={'Phone Required on Manual Add'}
                  defaultChecked={fields.phoneRequiredManual}
                  value={fields.phoneRequiredManual}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('phoneRequiredManual');
                  }}
                />
                {renderError('phoneRequired')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Toggle if guests will be required to enter their name before joining the line on their own device or manually by a door-minder.' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'name'}>
                Name Required
                <Switch
                  ref={form.register}
                  disabled={currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
                  form={form}
                  name="nameRequired"
                  label={'Name Required'}
                  defaultChecked={fields.nameRequired}
                  value={fields.nameRequired}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('nameRequired');
                  }}
                />
                {renderError('phoneRequired')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Toggle if guests will be required to enter their phone number before joining the line on their own device.' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'phone'}>
                Phone Required
                <Switch
                  ref={form.register}
                  form={form}
                  disabled={currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
                  name="phoneRequired"
                  label={'Phone Required'}
                  defaultChecked={fields.phoneRequired}
                  value={fields.phoneRequired}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('phoneRequired');
                  }}
                />
                {renderError('phoneRequired')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='Toggle if guests will be required to fill out a CDC Covid-19 symptom screening before joining the line.' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '2rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'name'} style={{ textAlign: 'center' }}>
                Covid Screening <br /> Required
                <Switch
                  ref={form.register}
                  form={form}
                  disabled={currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
                  name="covidScreenRequired"
                  label={'Covid Screen Required'}
                  defaultChecked={fields.covidScreenRequired}
                  value={fields.covidScreenRequired}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('covidScreenRequired');
                  }}
                />
                {renderError('covidScreenRequired')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The current number of people inside your location.' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'population'}>
                Current Population
                <NumericTextBox
                  ref={form.register}
                  form={form}
                  name="population"
                  defaultValue={fields.population}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('population');
                  }}
                  className={style.full_width}
                  min={0}
                />
                {renderError('population')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The time of day your location opens for business' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'location_open_time'}>
                Location Open Time (**Using Eastern Time, not location time)
                <TimePicker
                  ref={form.register}
                  name="location_open_time"
                  format={'hh:mm a'}
                  form={form}
                  disabled={currentUserRole === 'door_admin'}
                  defaultValue={fields.location_open_time}
                  className={style.full_width}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('location_open_time');
                  }}
                />
                {renderError('location_open_time')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The time of day you want to start allowing guests to join your line' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'line_start_time'}>
                Line Start Time
                <TimePicker
                  ref={form.register}
                  name="line_start_time"
                  format={'hh:mm a'}
                  form={form}
                  disabled={currentUserRole === 'door_admin'}
                  defaultValue={fields.line_start_time}
                  className={style.full_width}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('line_start_time');
                  }}
                />
                {renderError('line_start_time')}
              </label>
            </div>
            <div className={style.input_wrapper}>
              <Tooltip trigger='click' title='The time of day when you want to stop guests from being able to enter the line' placement='top'>
                <Icon type="question-circle" style={{ color: '#129A7F', fontSize: '1.5rem', marginRight: '.5rem', marginTop: '1.5rem' }}/>
              </Tooltip>
              <label className={style.grid_label} htmlFor={'line_end_time'}>
                Line End Time
                <TimePicker
                  ref={form.register}
                  name="line_end_time"
                  format={'hh:mm a'}
                  form={form}
                  disabled={currentUserRole === 'door_admin'}
                  defaultValue={fields.line_end_time}
                  className={style.full_width}
                  onChange={() => {
                    setLocalDirty(true);
                    form.clearError('line_end_time');
                  }}
                />
                {renderError('line_end_time')}
              </label>
            </div>
          </div>
        </div>
        <Button primary={true} disabled={processing} className={style.submit_btn}>
					Submit
        </Button>
      </form>
    </div>
  );
}

// ClientEditLocationView.propTypes = {
//   /* data */
//   loading: PropTypes.bool.isRequired,

//   /* utils */
//   form: PropTypes.object.isRequired,

//   /* methods */
//   onSubmit: PropTypes.func.isRequired,
// };

export default ClientEditLocationView;
