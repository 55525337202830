const { default: CovidScreenFailed } = require("../../../components/CovidScreenFailed/CovidScreenFailed");

export SignIn from 'pages/SignIn/SignIn.container';
export SignOut from 'pages/SignOut/SignOut.container';
export Error from 'pages/Error/Error.container';
export Admin from 'pages/Admin/Admin.container';
export Register from 'pages/Register/Register.container';
export Join from 'pages/Join/Join.container';
export Waiting from 'pages/Waiting/Waiting.container';
export CheckIn from 'pages/CheckIn/CheckIn.container';
export Privacy from 'pages/Privacy/Privacy.container';
export SignUp from 'pages/SignUp/SignUp.container';
export AddLocation from 'pages/AddLocation/AddLocation.container';
export ClientSignOut from 'pages/ClientSignOut/ClientSignOut.container';
export CovidScreenFailed from '../../../components/CovidScreenFailed/CovidScreenFailed';