import React, { Fragment } from 'react';

/* data management */
import { clearSuccess, clearError, clearWarning } from 'state/actions';
import { useSelector } from 'react-redux';
import createDispatchedActions from 'utils/createDispatchedActions';

/* components */
import style from './_Notification.module.scss';
import { NotificationGroup, Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';

function NotificationContainer() {
  /* create dispatched actions */
  const actions = createDispatchedActions(clearSuccess, clearError, clearWarning);

  /* redux state */
  const {
    success,
    error,
    warning,
  } = useSelector(state => ({
    success: state.notifications.success,
    error: state.notifications.error,
    warning: state.notifications.warning,
  }));


  /* methods */
  const methods = {
    renderSuccess: () => {
      if (!success) {
        return null;
      }

      setTimeout(() => {
        actions.clearSuccess();
      }, 3000);

      return success;
    },

  };


  return (
    <Fragment>
      
      <NotificationGroup
        className={style.notification_container}
      >
        <Fade enter={true} exit={true}>
          {success && <Notification
            type={{ style: 'success', icon: true }}
          >
            <span>{methods.renderSuccess()}</span>
          </Notification>}
        </Fade>
        <Fade enter={true} exit={true}>
          {error && <Notification
            type={{ style: 'error', icon: true }}
            closable={true}
            onClose={() => actions.clearError()}
          >
            <span>{error && error}</span>
          </Notification>}
        </Fade>
        <Fade enter={true} exit={true}>
          {warning && <Notification
            type={{ style: 'warning', icon: true }}
            closable={true}
            onClose={() => actions.clearWarning()}
          >
            <span>{warning && warning}</span>
          </Notification>}
        </Fade>
      </NotificationGroup>
    </Fragment>
  );
}

export default NotificationContainer;
