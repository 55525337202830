import types from '../types';

import {
  setAuthLoading,
} from './';

export function signinAuth({ email, password }) {
  return function(dispatch) {
    dispatch(setAuthLoading(true));

    return window.firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        return window.firebase.auth().currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (idTokenResult.claims.user_role) {
              // console.log(`firebase.auth().currentUser - Logged In. User is a ${idTokenResult.claims.user_role}`);
              dispatch({
                type: types.SET_AUTH,
                payload: true,
              });

              // console.log('idTokenResult', idTokenResult.claims);

              dispatch({
                type: types.SET_USER,
                payload: {
                  location_id: idTokenResult.claims.location_id || null,
                  user_id: window.firebase.auth().currentUser.uid,
                  user_role: idTokenResult.claims.user_role,
                  brand_id: idTokenResult.claims.brand_id || null,
                  region_id: idTokenResult.claims.region_id || null,
                },
              });

              dispatch(setAuthLoading(false));
              return Promise.resolve(true);
            }
            dispatch(setAuthLoading(false));
            return Promise.reject();
          });
      })
      .catch((error) => {
        // console.log(error);
        let errorMessage = 'An unexpected error occurred. Please refresh the page';
        if (error.code === 'auth/user-not-found') {
          errorMessage = 'Unable to log in.';
        } else if (error.code === 'auth/network-request-failed') {
          errorMessage = 'Request timed out. Refresh and try again.';
        } else if (error.response) {
          errorMessage = error.response.data.message;
        }
        dispatch(setAuthLoading(false));
        return Promise.reject(errorMessage);
      });
  };
}

export function verifyAuth() {
  return function(dispatch) {
    dispatch(setAuthLoading(true));

    if (!window.firebase.auth().currentUser) {
      console.log('cant find current user');
      
      dispatch({
        type: types.DESTROY_ALL,
      });
      return Promise.reject();
    }

    return window.firebase.auth().currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        console.log('idTokenResult !!!!:', idTokenResult);
        
        // Confirm the user is an Admin.
        if (idTokenResult.claims && idTokenResult.claims.user_role) {
          console.log(`firebase.auth().currentUser - Logged In. User is ${idTokenResult.claims.user_role}`);
          dispatch({
            type: types.SET_AUTH,
            payload: true,
          });

          console.log('idTokenResult', idTokenResult.claims);
          dispatch({
            type: types.SET_USER,
            payload: {
              location_id: idTokenResult.claims.location_id,
              user_id: window.firebase.auth().currentUser.uid,
              user_role: idTokenResult.claims.user_role,
              brand_id: idTokenResult.claims.brand_id || null,
              region_id: idTokenResult.claims.region_id || null,
            },
          });

          dispatch(setAuthLoading(false));
          return Promise.resolve(true);
        }
        dispatch(setAuthLoading(false));
        return Promise.reject();
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = 'Auth  - getIdToken: An unexpected error occurred. Please contact the development team :)';
        if (error.response) {
          errorMessage = error.response.data.message;
        }
        dispatch(setAuthLoading(false));
        return Promise.reject(errorMessage);
      });
  };
}

export function signOut() {
  return function(dispatch) {
    window.firebase.auth()
      .signOut()
      .then(() => {
        dispatch({
          type: types.DESTROY_ALL,
        });
      })
      .catch((e) => {
        console.log('Sign Out error: ', e);
      });
  };
}
