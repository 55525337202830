import React from 'react';
import ReactPlayer from 'react-player';
import style from './_Help.module.scss';
import { Icon, Tooltip } from 'antd';

function HelpView(props) {
  return (
    <main className={style.help_container}>
      <h1 style={{ marginBottom: 0, color: '#129A7F', fontWeight: 700 }}>For Technical Support, Please Call (720)-798-2211</h1>
      <h1 className={style.help_desc}>Please see the videos below for more instructions on how to use JOL:</h1>
      <p className={style.help_comingsoon}>Click <a href={'https://about.joinourline.com/'} target='_blank' style={{ color: '#129A7F' }}>here</a> for more information.</p>
      <div className={style.video_container}>
        <h3 className={style.video_header}>Training Video</h3>
        <ReactPlayer 
          className={style.video_player}
          width={'76%'}
          style={{ marginLeft: '12%' }}
          controls={true}
          url='https://www.youtube.com/watch?v=495nb3dHjco' 
        />
      </div>
      <div className={style.video_container}>
        <h3 className={style.video_header}>Quick Intro</h3>
        <ReactPlayer 
          className={style.video_player}
          width={'100%'}
          controls={true}
          url='https://vimeo.com/404651141' 
        />
      </div>
      <div className={style.video_container}>
        <h3 className={style.video_header}>Feature Overview</h3>
        <ReactPlayer 
          className={style.video_player}
          width={'100%'}
          controls={true}
          url='https://vimeo.com/404676956' 
        />
      </div>
      <div className={style.gloss_container}>
        <h3 className={style.glossary_header}>JOL Glossary</h3>
        <p className={style.glossary_desc}>Please click on the icon next to any term to view the definition.</p>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="The total capacity of your business.">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Capacity</h4>
        </div>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="Capacity-margin is an optional feature. When set to 0 the feature is disabled completely. When enabled (by adding a number greater than 0) the feature allows a location to call customers up immediately, without them having to join the line, until the number of people in the store is equal or greater to the store capacity minus capacity margin. So if a location has a capacity of 50, and a margin capacity of 10, customers will see a “Come on in” message until 40 people are in the store.">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Capacity Margin</h4>
        </div>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="The current number of people inside your location.">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Current Population</h4>
        </div>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="The value in meters representing how far away your guests can be from your location before they are allowed to enter the line.">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Distance</h4>
        </div>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="The maximum number of guests who can join the line.">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Line Limit</h4>
        </div>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="The time of day when you want to stop guests from being able to enter the line.">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Line End Time</h4>
        </div>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="The time of day you want to start allowing guests to join your line.">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Line Start Time</h4>
        </div>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="The time of day your location opens for business.">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Location Open Time</h4>
        </div>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="The maximum number of people who can join in one party (representing one group waiting in line).">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Party Limit</h4>
        </div>
        <div className={style.gloss_item_container}>
          <Tooltip trigger='click' placement="left" className={style.gloss_item_tooltip} title="The amount of time a guest has to enter the location once they are told it is their turn to come in before they are kicked from the line.">
            <Icon type="question-circle" className={style.gloss_item_icon} />
          </Tooltip>
          <h4 className={style.gloss_item_text}>Wait Timeout</h4>
        </div>
      </div>
    </main>
  );
}

export default HelpView;
