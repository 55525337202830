import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

/* data management */
import validationSchema from './validationSchema';

/* Components */
import CustomMessagingView from './CustomMessaging.view';
import defaults from 'formDefaults/location.defaults';
import StringHelper from 'common/stringHelper';
import style from '../Admin/EditBrand/_EditBrand.module.scss';

function CustomMessagingContainer(props) {
  /* redux state */
  const state = useSelector(state => ({
    user: state.user,
  }));

  /* data */
  const {
    match: {
      params: {
        location_id,
      },
    },
  } = props;

  /* local state */
  const [ processing, setProcessing ] = useState(false);
  const [ newLocation, setNewLocation ] = useState(false);
  const [ fields, setFields ] = useState({});
  const [ location, setLocation ] = useState({});
  const [ locationError, setLocationError ] = useState(null);
  const [ localDirty, setLocalDirty ] = useState(false);
  const [ brands, setBrands ] = useState([]);
  const [ currentCustomTextValue, setCurrentCustomTextValue ] = useState('');
  const [ currentCustomTextKey, setCurrentCustomTextKey ] = useState('');
  const [ locationName, setLocationName ] = useState('');
  const [ atTop, setAtTop ] = useState(false);
  const [ currentBrand, setCurrentBrand ] = useState('');
  const brandsRef = window.firebase.firestore().collection(`brands`);
  let locationRef = window.firebase.firestore().doc(`locations/${state.user.location_id}`);

  const strings = new StringHelper('en').getDefaultStrings();
  const languages = [ 'en', 'es' ];

  /* local form state */
  const form = useForm({
    validationSchema,
  });

  /* on mount */
  useEffect(() => {
    // if user is brand manager, change firestore reference to the brand document
    if (state.user.user_role === 'brand_manager' || state.user.user_role === 'regional_manager') {
      locationRef = window.firebase.firestore().doc(`brands/${state.user.brand_id}`);
    }
    const locationRefOff = locationRef.onSnapshot(async (snapshot) => {
      if (!snapshot.data()) {
        setNewLocation(true);
        return setFields({
          ...defaults,
        });
      }
      // if this location is associated with a brand, get content from the brand data
      if (snapshot.data().brand_id && snapshot.data().brand_id !== 'client_brand' && state.user.user_role !== 'store_manager') {
        setCurrentBrand(snapshot.data().brand_id);
        const locationValues = snapshot.data();

        await window.firebase.firestore().doc(`brands/${snapshot.data().brand_id}`)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              console.log('no brand doc');
            }
            const brandData = doc.data();
            const stringFields = {};
            Object.keys(strings).forEach(key => {
              for (const lang of languages) {
                stringFields[`${key}-${lang}`] = brandData.content && brandData.content[key] ? brandData.content[key][lang] : '';
              }
            });
            setFields({
              ...stringFields,
              content: brandData.content ? JSON.stringify(brandData.content) : null,
            });
            setLocationName(locationValues.name);
          });
      // get content from location data if it is not connected to a brand
      } else {
        const locationValues = snapshot.data();
    
        const stringFields = {};
        Object.keys(strings).forEach(key => {
          for (const lang of languages) {
            stringFields[`${key}-${lang}`] = locationValues.content && locationValues.content[key] ? locationValues.content[key][lang] : '';
          }
        });
    
        setFields({
          ...stringFields,
          content: locationValues.content ? JSON.stringify(locationValues.content) : null,
        });
        setLocationName(locationValues.name);
      }
    });

    window.onscroll = function() {
      if (window.pageYOffset >= 0 && window.pageYOffset < 20) {
        setAtTop(true);
      } else {
        setAtTop(false);
      }
    };

    return () => {
      locationRefOff();
    };
  }, [  ]);

  /* container methods */
  const methods = {
    async onSubmit() {
      setLocationError(null);
      setProcessing(true);

      const formData = form.watch();
      const dirty = form.formState.dirty || localDirty;
      if (!dirty) {
        if (state.user.user_role === 'door_admin' || state.user.user_role === 'store_manager' || state.user.user_role === 'client') {
          return props.history.push(`/admin/dashboard`);
        }

        return props.history.replace('/admin/locations');
      }

      const thisLocation = {
        content: formData.content ? JSON.parse(formData.content) : null,
      };

      const updatedContent = {
        ...JSON.parse(fields.content),
        ...thisLocation.content,
      };

      if (currentBrand !== '' && state.user.user_role !== 'store_manager') {
        window.firebase.firestore().doc(`brands/${currentBrand}`)
          .update({
            content: updatedContent,
          })
          .then(() => {
            setProcessing(false);
            setLocationError(null);
            if (state.user.user_role === 'door_admin' || state.user.user_role === 'store_manager' || state.user.user_role === 'client') {
              return props.history.push(`/admin/dashboard`);
            }
            return props.history.push(`/admin/locations`);
          })
          .catch((e) => {
            setProcessing(false);
            console.log('joinLine - update location error:', e);
          });
      } else {
        window.firebase.firestore().doc(`locations/${state.user.location_id}`)
          .update({
            content: updatedContent,
          })
          .then(() => {
            setProcessing(false);
            setLocationError(null);
            if (state.user.user_role === 'door_admin' || state.user.user_role === 'store_manager' || state.user.user_role === 'client') {
              return props.history.push(`/admin/dashboard`);
            }
            return props.history.push(`/admin/locations`);
          })
          .catch((e) => {
            setProcessing(false);
            console.log('joinLine - update location error:', e);
          });
      }
    },

    renderError(name) {
      if (form.errors[name]) {
        return (
          <span className={style.field_error}>{form.errors[name].message}</span>
        );
      }
      return (
        <span className={style.field_error}>&nbsp;</span>
      );
    },

    customTextTrigger(key, value) {
      setCurrentCustomTextValue(value);
      setCurrentCustomTextKey(key);
    },
  };

  const isDoorAdmin = state.user.user_role === 'door_admin';

  return (
    <CustomMessagingView
      /* data */
      fields={fields}
      form={form}
      history={props.history}
      newLocation={newLocation}
      brands={brands}
      locationError={locationError}
      processing={processing}
      isDoorAdmin={isDoorAdmin}
      userRole={state.user.user_role}
      currentCustomTextValue={currentCustomTextValue}
      currentCustomTextKey={currentCustomTextKey}
      locationName={locationName}
      /* methods */
      onSubmit={methods.onSubmit}
      setLocalDirty={setLocalDirty}
      renderError={methods.renderError}
      customTextTrigger={methods.customTextTrigger}
      setCurrentCustomTextKey={setCurrentCustomTextKey}
      atTop={atTop}
    />
  );
}

export default CustomMessagingContainer;
