import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/* Components */
import UsersView from './Users.view';

function UsersContainer(props) {
  const state = useSelector(state => ({
    user: state.user,
  }));
  const [ users, setUsers ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    let getUsers = window.firebase.functions().httpsCallable('getUsers');
    if (state.user.user_role !== 'admin') {
      getUsers = window.firebase.functions().httpsCallable('getUsersManager');
    }
    async function getFirebaseUsers() {
      await getUsers()
        .then((data) => {
          let rawUsers = data.data;
          if (state.user.user_role === 'store_manager') {
            rawUsers = rawUsers.filter((user) => {
              return user.customClaims.location_id === state.user.location_id;
            });
          } else if (state.user.user_role === 'regional_manager') {
            rawUsers = rawUsers.filter((user) => {
              return user.customClaims.region_id === state.user.region_id;
            });
          }
          setLoading(false);
          setUsers(rawUsers);
        })
        .catch(e => {
          setLoading(false);
          console.log('could not get Firebase users', e);
        });
    }

    getFirebaseUsers();
  }, [  ]);

  /* container methods */
  const methods = {
    handleNavigate(param) {
      return props.history.push(`/admin/users/${param}`);
    },
  };

  return (
    <UsersView
      loading={loading}
      users={users}
      handleNavigate={methods.handleNavigate}
      history={props.history}
    />
  );
}

export default UsersContainer;
