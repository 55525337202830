import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon, Modal } from 'antd';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Button, ButtonGroup, Toolbar, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import ReactPlayer from 'react-player';

import style from './_guestList.module.scss';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';

function formatPhone(text, phone) {
  let newValue = text.substring(0, 12);
  const oldValue = phone || '';
  const lastCharacter = newValue.split('').pop();
  const aNumberKey = parseInt(lastCharacter, 10);

  if (newValue.length < oldValue.length) return newValue;

  if (isNaN(aNumberKey)) return oldValue;

  switch (newValue.length) {
    case 4: newValue = `${oldValue}-${aNumberKey}`;
      break;
    case 8: newValue = `${oldValue}-${aNumberKey}`;
      break;
    case 14: return oldValue;
  }

  return newValue;
}

function GuestListView(props) {
  const {
    loading,
    user,
    location,
    checkInGuest,
    deleteGuest,
    isSaving,
    buttonQueue,
    removeFromLocation,
    addToLocation,
    populationChanging,
    addGuestToLine,
    isAddingGuest,
    isSavingGuest,
    setAddingGuest,
    phone,
    name,
    notes,
    priority,
    handleSetNotes,
    handleSetName,
    handleSetPhone,
    handleSetPriority,
    handleSetPriorityWithValue,
    peopleInQueue,
    guests,
    partySize,
    handleSetPartySize,
    showWaiting,
    showWaitingSwitch,
    showVideo,
    setShowVideo,
    handleDontShowClick,
    divColor,
    percentOccupied,
    noLocation,
    currentPhase,
  } = props;

  if (loading && !noLocation) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  if (noLocation) {
    return (
      <h4>There is no location associated with your account.</h4>
    );
  }

  if (location.disabled) {
    return (
      <h4 style={{ textAlign: 'center', fontSize: '1.5rem', width: '60%', marginLeft: '20%', paddingTop: '2rem' }}>This location is not currently active. <br /> You can still view metrics and edit location details.</h4>
    );
  }

  function renderGuest(guest) {
    if (guest.phone_number || guest.notes || guest.name) {
      return (
        <span
          onClick={() => { Modal.info({
            title: 'Notes',
            content: guest.notes || 'No notes',
          });
          }}
        >Guest #{guest.queue_id} <br />{guest.name} Party of {guest.party_size}</span>
      );
    }
    return (
      <span>Guest #{guest.queue_id} <br />Party of {guest.party_size}</span>
    );
  }

  function renderCustomData(guest) {
    if (!guest.custom_data) {
      return null;
    }
    const customKeys = Object.keys(guest.custom_data);
    return customKeys.map((key) => {
      return (
        <p style={{ margin: '.25rem' }}>{`${key}: ${guest.custom_data[key]}`}</p>
      );
    });
  }

  function tableFeatures() {
    return (
      <Toolbar style={{ backgroundColor: '#F0F0F0', border: 'none', borderRadius: '8px' }}>
        <ToolbarItem>
          <ButtonGroup>
            <Button icon="check" style={ !showWaiting ? { backgroundColor: '#129A7F', border: 'none' } : { backgroundColor: 'white', border: 'none' }} title="Check In" selected={!showWaiting} togglable={true} onClick={() => showWaitingSwitch(false)}>Check Ins</Button>
            <Button icon="clock" style={ showWaiting ? { backgroundColor: '#129A7F', border: 'none' } : { backgroundColor: 'white', border: 'none' }} title="Waiting" selected={showWaiting} togglable={true} onClick={() => showWaitingSwitch(true)}>Waiting</Button>
          </ButtonGroup>
        </ToolbarItem>

        <ToolbarSpacer />
        <ToolbarItem>
          <Button icon="add" title="Add to Line" onClick={() => setAddingGuest(true)} style={{ backgroundColor: 'white', border: 'none' }}>Add to Line</Button>
        </ToolbarItem>
      </Toolbar>
    );
  }

  function getMinutesPassed(time) {
    const now = moment(new Date());
    const formattedTime = moment(time);
    const duration = moment.duration(now.diff(formattedTime));
    return Math.round(duration.asMinutes());
  }

  function renderTable() {
    if (!(showWaiting ? peopleInQueue : guests).length) {
      return (
        <div className={style.guestTable}>
          {tableFeatures()}
          { showWaiting ? <p>There are no guests waiting to be checked in</p> : <p>There are no guests waiting in line</p> }
        </div>
      );
    }
    return (
      <div className={style.guestTable}>
        {tableFeatures()}
        {
          (showWaiting ? peopleInQueue : guests).map((guest) => {
            return (
              <div key={guest.firebase_uid} className={style.guestRow}  style={{ backgroundColor: guest.priority ? '#D77000' : '#129A7F' }}>
                <p style={{ marginLeft: '0' }}>{guest.queue_id}</p>
                {renderGuest(guest)}
                {renderCustomData(guest)}
                <p style={{ margin: '0', marginRight: '5%', textAlign: 'center', width: '2.5rem', fontWeight: '300' }}>{guest.allowed ? `Called ${getMinutesPassed(guest.allowed)}min` : `Waiting ${getMinutesPassed(guest.joined)}min`}</p>
                <Icon
                  style={{ color: '#fff' }}
                  onClick={() => deleteGuest(guest) }
                  type={'delete'}/>
                { guest.allowed && <Icon
                  style={{ color: '#fff' }}
                  onClick={() => checkInGuest(guest) }
                  type={'check'}/>
                }
              </div>
            );
          })
        }
      </div>
    );
  }

  function renderAddGuest() {
    return (
      <form
        className={style.form}
        onSubmit={(e) => {
          e.preventDefault();
          addGuestToLine(phone, name, notes, priority, partySize);
        }}
      >
        <Input
          name={'phone'}
          placeholder={'Mobile Phone Number'}
          hidden={location.phoneRequiredManual === false}
          value={phone.value}
          required={!notes.value && location.phoneRequiredManual}
          onChange={(e) => handleSetPhone({ value: formatPhone(e.value, phone.value) })}
          style={{ width: '100%', height: '50px', margin: '10px 0' }}
          pattern={'^[0-9]{3}-[0-9]{3}-[0-9]{4}$'}
          validationMessage={'Phone or Notes is Required'}
        />
        <Input
          id={'name'}
          name={'name'}
          placeholder={'Name'}
          hidden={!location.nameRequired}
          required={location.nameRequired}
          value={name.value}
          onChange={(e) => handleSetName({ value: e.value })}
          style={{ width: '100%', height: '80px', margin: '10px 0' }}
        />
        <Input
          id={'notes'}
          name={'notes'}
          placeholder={'Guest Notes/Description (ex: person in blue striped t-shirt)'}
          required={!phone.value}
          value={notes.value}
          onChange={(e) => handleSetNotes({ value: e.value })}
          style={{ width: '100%', height: '80px', margin: '10px 0' }}
          validationMessage={'Notes is Required'}
        />
        <div className={style.partySizeWrapper}>
          <span style={{ width: '100%', height: '50px', margin: '10px 0' }} >Party Size</span>
          <div className={style.partySize}>
            <Button
              type={'button'}
              onClick={() => { handleSetPartySize('subtract'); }}
              style={{ backgroundColor: '#129A7F' }}
              disabled={partySize <= 1}
              icon='minus'
            />
            <span>{partySize}</span>
            <Button
              type={'button'}
              onClick={() => { handleSetPartySize('add'); }}
              disabled={partySize >= (location.party_limit || 5)}
              style={{ backgroundColor: '#129A7F' }}
              icon='plus'
            />
          </div>
        </div>

        <div className={style.checkboxWrap}>
          <Checkbox
            id={'priority_field'}
            label={'Priority Guest'}
            labelPlacement={'before'}
            defaultChecked={false}
            onClick={() => handleSetPriority()}
            onChange={(event) => handleSetPriorityWithValue(event.value) }
            value={priority} />
        </div>
        <div style={{ flexGrow: 1 }}></div>

        <div className={style.bottomButtonContainer}>
          {isSavingGuest ?
            <div className={style.buttonsContainer}>
              <ProgressBar value={null} style={{ backgroundColor: '#005cbf', borderColor: '#fff', marginTop: '15px', height: '30px' }} />
            </div> :
            <Button
              type={'submit'}
              primary={true}
              className={style.bottomButton}
              style={{ backgroundColor: '#129A7F', border: 'none', marginBottom: '1rem' }}
            >
            Save
            </Button> }
          <Button
            type={'button'}
            className={style.bottomButton}
            onClick={() => { setAddingGuest(false); }}
            style={{ backgroundColor: '#2A3C5B', border: 'none', color: 'white' }}
          >
            Cancel
          </Button>
        </div>

      </form>
    );
  }


  function renderLocationContent() {
    if (!location.location_id) {
      return (
        <div>
          <div className={style.guestCountContainer}>
            <p>We could not find a location for this location ID</p>
          </div>
        </div>
      );
    }

    const buttonQueueValue = sum(buttonQueue);

    return (
      <div>
        { isSaving ?
          <div className={style.buttonsContainer}>
            <ProgressBar value={null} style={{ backgroundColor: '#005cbf', borderColor: '#fff', marginTop: '15px', height: '30px' }} />
          </div>
          :
          <div className={style.buttonsContainer}>
            <Button
              onClick={() => { removeFromLocation(); }}
              primary
              style={{ backgroundColor: '#129A7F', borderColor: '#129A7F', boxShadow: 'none', fontSize: '28px' }}
              icon={buttonQueueValue < 0 ? '' : 'minus'}
            >{buttonQueueValue < 0 ? `(${buttonQueueValue})` : ''}</Button>
            <Button
              onClick={() => { addToLocation(); }}
              disabled={location.population >= user.capacity}
              primary
              style={{ backgroundColor: '#10725E', borderColor: '#10725E', boxShadow: 'none', fontSize: '28px' }}
              icon={buttonQueueValue > 0 ? '' : 'plus'}
            >{buttonQueueValue > 0 ? `(+${buttonQueueValue})` : ''}</Button>
          </div>
        }
        <section className={style.pop_color_section}>
          <div style={{ padding: '1.5rem', width: '250px' }}>
            <CircularProgressbar
              value={Math.round(percentOccupied)}
              text={`${Math.round(percentOccupied)}% Full`}
              styles={buildStyles({
                strokeLinecap: 'butt',
                padding: '1rem',
                textSize: '12px',
                pathTransitionDuration: 0.5,
                pathColor: divColor,
                textColor: divColor,
              })}
            />
          </div>
        </section>
        <div className={style.guestCountContainer}>
          <div className={style.count} style={{ backgroundColor: '#f17c4e2c' }}>
            <p style={{ color: '#DC551D' }}>Capacity</p>
            <p style={{ color: '#DC551D' }}>{location.capacity}</p>
          </div>

          <div className={style.count} style={{ backgroundColor: '#4ed7f223' }}>
            <p style={{ color: '#1ABCD9' }}>Current</p>
            <p style={{ color: '#1ABCD9' }}>{populationChanging ? '🕑' : location.population - location.waiting}</p>
          </div>

          <div className={style.count} style={{ backgroundColor: '#f24e692f' }}>
            <p style={{ color: '#DA1C38' }}>Waiting</p>
            <p style={{ color: '#DA1C38' }}>{sumBy(peopleInQueue, 'party_size')}</p>
          </div>
        </div>
        { isAddingGuest ? renderAddGuest() : renderTable() }
      </div>
    );
  }

  return (
    <div className={style.container}>
      <Modal
        className={style.video_modal}
        visible={showVideo}
        centered={true}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        okText={'Close'}
        onOk={() => setShowVideo(false)}
        cancelText={'Close and Do Not Show Again'}
        onCancel={() => handleDontShowClick()}
      >
        <h4 style={{ textAlign: 'center', fontSize: '1rem' }}>Welcome! Please watch this short training video:</h4>
        <p style={{ textAlign: 'center', fontSize: '1rem' }}>Make sure to select the "Close and Do Not Show Again" button if you do not want this pop-up to continue appearing upon signing in.
        You can always find this video along with more instructions in the "Help" tab in the menu.
        </p>
        <ReactPlayer 
          className={style.video_player}
          width={'100%'}
          controls={true}
          url='https://vimeo.com/404651141' 
        />
      </Modal>
      <h2 style={{ textAlign: 'center' }}>{location.name}</h2>
      {renderLocationContent()}
    </div>
  );
}

GuestListView.propTypes = {
  /* data */
  loading: PropTypes.bool,
};

export default GuestListView;
