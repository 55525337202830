export const basePath = `/admin_v2/v1`;

export const getApiURL = (ENV = process.env.NODE_ENV, path = basePath) => {
  const domain = `${process.env.API_DOMAIN}${path}`;
  const isLocalhost = process.env.API_DOMAIN.indexOf('localhost') > -1;

  let protocol = 'https://';

  if (isLocalhost || (ENV !== 'staging' && ENV !== 'production')) {
    protocol = 'http://';
  }

  return `${protocol}${domain}`;
};

export const API_URL = getApiURL();
