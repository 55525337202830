import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import style from './_AddLocation.module.scss';

/* data management */
import LOADING_KEY from 'definitions/keys/loading.keys';
import validationSchema from './validationSchema';

/* components */
import AddLocationView from './AddLocation.view';
const updateClientUserClaims = window.firebase.functions().httpsCallable('updateClientUserClaims');

function AddLocationContainer(props) {
  // /* redux state */
  const {
    loading,
  } = useSelector(state => ({
    authenticated: state.authenticated,
    loading: state.loading[LOADING_KEY.auth],
  }));
  const state = useSelector(state => ({
    user: state.user,
  }));
  

  /* local state */
  const [ emailMessage, setEmailMessage ] = useState('');
  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ locationExists, setLocationExists ] = useState(false);
  const [ localDirty, setLocalDirty ] = useState(false);
  const [ fields, setFields ] = useState({});
  const [ locationError, setLocationError ] = useState(null);
  const [ processing, setProcessing ] = useState(false);
  const [ currentUid, setCurrentUid ] = useState('');
  const [ locationId, setLocationId ] = useState('');
  const [ mapVisible, toggleMapVisible ] = useState(false);
  const [ preciseLat, setPreciseLat ] = useState(0);
  const [ preciseLong, setPreciseLong ] = useState(0);
  const [ locationData, setLocationData ] = useState({});
  const [ isLoading, setLoading ] = useState(false);
  const [ currentLocationId, setCurrentLocationId ] = useState('');
  const [ locationIds, setLocationIds ] = useState([]);
  const [ locationIdExists, setLocationIdExists ] = useState(false);
  const [ preventSubmission, setPreventSubmission ] = useState(false);
  const [ locationOpenTime, setLocationOpenTime ] = useState(new Date(moment().hour(6).minute(0)));

  /* local form state */
  const form = useForm({
    validationSchema,
  });

  /* on mount */
  useEffect(() => {
    const currentLocationIds = [];
    window.firebase.firestore().collection('locations')
      .get()
      .then((snapshot) => {
        for (const location of snapshot.docs) {
          if (!currentLocationIds.includes(location.id)) {
            currentLocationIds.push(location.id.toLowerCase());
          }
        }
        setLocationIds(currentLocationIds);
      });

    setCurrentUid(state.user.user_id);
    if (!state.user.location_id) {
      console.log('user has not set up their first location yet...');
      setLocationExists(false);
      return;
    }
    return props.history.push(`/admin/dashboard`);
  }, [ ]);

  /* container methods */
  const methods = {
    onSubmit(formData) {
      if (preventSubmission) {
        return;
      }
      setLoading(true);
      const removeProperties = ({ address1, address2, city, zip_code, state, location_open_time, line_start_time, line_end_time, ...rest }) => rest;
      const address = {
        address1: formData.address1,
        address2: formData.address2 || null,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zip_code,
      };
      const location_open_time = {
        hour: formData.location_open_time !== null ? formData.location_open_time.getHours() : null,
        minute: formData.location_open_time !== null ? formData.location_open_time.getMinutes() : null,
      };
      const line_start_time = {
        hour: formData.line_start_time ? formData.line_start_time.getHours() : null,
        minute: formData.line_start_time ? formData.line_start_time.getMinutes() : null,
      };
      const line_end_time = {
        hour: formData.line_end_time ? formData.line_end_time.getHours() : null,
        minute: formData.line_end_time ? formData.line_end_time.getMinutes() : null,
      };
      const partlyFormattedData = {
        address,
        brand_id: 'client_brand',
        location_open_time,
        line_start_time,
        line_end_time,
        waiting: 0,
        population: 0,
        ...removeProperties(formData),
      };
      return methods.formatLocationData(partlyFormattedData);
    },

    async formatLocationData(rawData) {
      const {
        address1,
        address2,
        city,
        state,
      } = rawData.address;
      // get lat and long and trigger rest of func after promise resolves
      const addressString = address2 ? `${address1}${` ${address2}`}, ${city} ${state}` : `${address1}, ${city} ${state}`;
      const geocoder = new google.maps.Geocoder();
      return await geocoder.geocode({ 'address': addressString }, async (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const formattedData = {
            ...rawData,
            location_id: await this.generateLocationId(rawData.name),
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
            population: rawData.population || 0,
          };
          toggleMapVisible(true);
          setLocationData(formattedData);
          setFields(formattedData);
          return methods.createMap(formattedData.latitude, formattedData.longitude);
        }
      });
    },

    generateLocationId(locationName) {
      const removedSpaces = locationName.split(' ').join('');
      const locationId = removedSpaces.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').toLowerCase();
      if (locationIds.includes(locationId)) {
        setLocationIdExists(true);
        setCurrentLocationId(locationId);
        setPreventSubmission(true);
      } else {
        setCurrentLocationId(locationId);
        setLocationIdExists(false);
        setPreventSubmission(false);
        return locationId;
      }
    },

    async createLocation(locationData) {
      setLoading(true);
      const brandId = `${locationData.location_id}_brand`;
      locationData.brand_id = brandId;
      const dataForClaimUpdate = {
        uid: currentUid,
        user_role: 'client',
        location_id: locationData.location_id,
      };
      await updateClientUserClaims(dataForClaimUpdate)
        .then(response => console.log('response from creating user:', response))
        .catch(e => console.log('error updating user:', e));
      // create actual location document with all the form data
      return window.firebase.firestore().collection('locations')
        .doc(locationData.location_id)
        .set({
          ...locationData,
          userId: state.user.user_id,
        })
        .then(async () => {
          await window.firebase.firestore().doc(`populationLogs/${locationData.location_id}`)
            .set({
              location_id: locationData.location_id,
            });
          await window.firebase.firestore().collection('brands')
            .doc(brandId)
            .set({
              content: {},
              brand_id: brandId,
              name: `Brand for ${locationData.name}`,
            });
          setLoading(false);
          return props.history.push(`/client_signout`);
        })
        .catch(e => console.log('error creating location', e));
    },

    createMap(lat, long) {
      setLoading(false);
      const map = new google.maps.Map(document.getElementById('map-div'), {
        center: { lat, lng: long },
        zoom: 16,
      });
      const marker = new google.maps.Marker({
        map,
        position: {
          lat,
          lng: long,
        },
        title: 'Drag me to your exact location.',
        draggable: true,
      });
      google.maps.event.addListener(marker, 'mouseup', ((marker) => {
        setPreciseLat(marker.latLng.lat());
        setPreciseLong(marker.latLng.lng());
      })
      );
    },

    updateLocationData() {
      if (preciseLat !== 0 && preciseLong !== 0) {
        const finalLocationData = {
          ...locationData,
          latitude: preciseLat,
          longitude: preciseLong,
        };
        return methods.createLocation(finalLocationData);
      }
      return methods.createLocation(locationData);
    },

    renderError(name) {
      if (form.errors[name]) {
        return (
          <span className={style.field_error}>{form.errors[name].message}</span>
        );
      }
      return (
        <span className={style.field_error}>&nbsp;</span>
      );
    },
  };

  return (
    <AddLocationView
      formData={form.watch()}
      emailMessage={emailMessage}
      alertVisible={alertVisible}
      errorMessage={errorMessage}
      locationExists={locationExists}
      mapVisible={mapVisible}
      toggleMapVisible={toggleMapVisible}
      fields={fields}
      form={form}
      loading={isLoading}
      history={props.history}
      locationError={locationError}
      processing={processing}
      isDoorAdmin={false}
      onSubmit={methods.onSubmit}
      setLocalDirty={setLocalDirty}
      renderError={methods.renderError}
      updateLocationData={methods.updateLocationData}
      currentLocationId={currentLocationId}
      generateLocationId={methods.generateLocationId}
      locationIdExists={locationIdExists}
      locationOpenTime={locationOpenTime}
      setLocationOpenTime={setLocationOpenTime}
    />
  );
}

export default AddLocationContainer;
