import * as yup from 'yup';

const validationSchema = yup.object().shape({
  // location_id: yup
  //   .string()
  //   .min(3)
  //   .matches(/^(\d|\w)+$/, 'Must be lower case with no spaces or special characters')
  //   .trim()
  //   .lowercase()
  //   .strict()
  //   .typeError('LocationID is required')
  //   .label('Location ID')
  //   .required(),
  // name: yup
  //   .string()
  //   .typeError('Location Name is required')
  //   .label('Location Name')
  //   .required(),
  // brand_id: yup
  //   .string()
  //   .typeError('Brand is required')
  //   .label('Brand ID')
  //   .required(),
  // address1: yup
  //   .string()
  //   .typeError('Address is required')
  //   .label('Address')
  //   .required(),
  // city: yup
  //   .string()
  //   .typeError('City is required')
  //   .label('City')
  //   .required(),
  // state: yup
  //   .string()
  //   .length(2)
  //   .typeError('State is required')
  //   .label('State')
  //   .required(),
  // zip_code: yup
  //   .string()
  //   .typeError('Zip Code is required')
  //   .label('Zip Code')
  //   .required(),
  // longitude: yup
  //   .string()
  //   .typeError('Longitude is required')
  //   .label('Longitude')
  //   .required(),
  // latitude: yup
  //   .string()
  //   .typeError('Latitude is required')
  //   .label('Latitude')
  //   .required(),
  // party_limit: yup
  //   .number()
  //   .nullable()
  //   .typeError('Party cannot be bigger than capacity')
  //   .label('Party Limit')
  //   .when('capacity', (capacity, schema) => {
  //     return schema.max(capacity);
  //   }),
});

export default validationSchema;
