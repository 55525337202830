// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._InputWrapper-module__field_error___3Cm52{display:block;color:red;margin-bottom:10px;text-align:right}\n", ""]);
// Exports
exports.locals = {
	"field_error": "_InputWrapper-module__field_error___3Cm52"
};
module.exports = exports;
