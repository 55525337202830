import React from 'react';
import { Input, NumericTextBox, Switch } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Icon, Modal } from 'antd';
import isEmpty from 'lodash/isEmpty';

import InputWrapper from 'components/InputWrapper';
import StringsForm from 'common/stringsForm';
import style from './_EditLocation.module.scss';

function EditLocationView(props) {
  const {
    /* data */
    fields,
    setLocalDirty,
    newLocation,
    history,
    brands,
    locationError,
    processing,
    isDoorAdmin,
    userRole,
    displayDeleteLocation,
    /* utils */
    form,

    /* methods */
    onSubmit,
    renderError,
    setDisplayDeleteLocation,
    deleteLocation,
  } = props;

  function header() {
    if (newLocation) {
      return (
        <h1>Add New Location</h1>
      );
    }
    return (
      <h1>{fields.name}</h1>
    );
  }

  // default values won't rerender on update -- need to set defaults from Firebase first
  if (isEmpty(fields)) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  const defaultBrand = brands.length && brands.find(o => o.brand_id === fields.brand_id);

  return (
    <div className={style.container}>
      <div className={style.header_container}>
        <Button primary={false} onClick={() => history.push(`/admin/locations`)} hidden={isDoorAdmin}>
					Go Back
        </Button>
        <Button
          primary={true}
          disabled={processing}
          // enable line below if we want to disable deleting for users with same role
          // hidden={fields.user_role === loggedInUser}
          hidden={newLocation}
          onClick={() => {
            console.log('delete location clicked');
            setDisplayDeleteLocation(true);
          }}
          style={{ backgroundColor: '#ff6161', border: 'none', marginTop: '1rem', marginLeft: '2rem', marginRight: '2rem', width: '150px' }}
        >
          Delete Location
        </Button>
        {header()}
      </div>

      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={style.formWrapper}>
          <div className={style.column} style={{ display: isDoorAdmin  || userRole === 'client' ? 'none' : 'inherit' }}>
            <InputWrapper
              component={<Input />}
              name="name"
              label="Location Name"
              form={form}
              defaultValue={fields.name}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="location_id"
              label="Location ID"
              form={form}
              defaultValue={fields.location_id}
              onChange={() => {
                setLocalDirty(true);
              }}
              disabled={!newLocation}
            />

            <div className={style.grid_label}>
              <DropDownList
                ref={form.register}
                name="brand_id"
                label="Brand"
                form={form}
                defaultValue={defaultBrand}
                data={brands}
                // value={userRole === 'client' ? 'client_brand' : null}
                // placeholder={userRole === 'client' ? 'client_brand' : null}
                dataItemKey={'brand_id'}
                textField={'name'}
                style={{ width: '100%' }}
                onChange={() => {
                  setLocalDirty(true);
                }}
              />
              {renderError('brand_id')}
            </div>

            <InputWrapper
              component={<Input />}
              name="address1"
              label="Address"
              form={form}
              defaultValue={fields.address1}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="address2"
              label="Address Line 2"
              form={form}
              defaultValue={fields.address2}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="city"
              label="City"
              form={form}
              defaultValue={fields.city}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="state"
              label="State"
              form={form}
              defaultValue={fields.state}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="zip_code"
              label="Zip Code"
              form={form}
              defaultValue={fields.zip_code}
              onChange={() => {
                setLocalDirty(true);
              }}
            />
          </div>

          <div className={style.column}>
            <InputWrapper
              component={<Input />}
              name="latitude"
              label="Latitude"
              form={form}
              defaultValue={fields.latitude}
              onChange={() => {
                setLocalDirty(true);
              }}
              containerStyles={{ display: isDoorAdmin  || userRole === 'client' ? 'none' : 'inherit' }}
            />

            <InputWrapper
              component={<Input />}
              name="longitude"
              label="Longitude"
              form={form}
              defaultValue={fields.longitude}
              onChange={() => {
                setLocalDirty(true);
              }}
              containerStyles={{ display: isDoorAdmin  || userRole === 'client' ? 'none' : 'inherit' }}
            />

            <label
              className={style.grid_label}
              htmlFor={'distance'}
              style={{ display: isDoorAdmin ? 'none' : 'inherit' }}
            >
              Distance in meters (1610m == 1 mile)
              <NumericTextBox
                ref={form.register}
                form={form}
                name="distance"
                defaultValue={fields.distance}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('distance');
                }}
                className={style.full_width}
                min={0}
              />
              {renderError('distance')}
            </label>

            <label className={style.grid_label} htmlFor={'capacity'}>
							Capacity
              <NumericTextBox
                ref={form.register}
                form={form}
                name="capacity"
                defaultValue={fields.capacity}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('capacity');
                }}
                className={style.full_width}
                min={0}
              />
              {renderError('capacity')}
            </label>

            <label className={style.grid_label} htmlFor={'capacity_margin'}>
							Capacity Margin
              <NumericTextBox
                ref={form.register}
                form={form}
                name="capacity_margin"
                defaultValue={fields.capacity_margin || 0}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('capacity_margin');
                }}
                className={style.full_width}
                min={0}
              />
              {renderError('capacity_margin')}
            </label>

            <label className={style.grid_label} htmlFor={'line_limit'}>
							Line Limit
              <NumericTextBox
                ref={form.register}
                form={form}
                name="line_limit"
                defaultValue={fields.line_limit}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('line_limit');
                }}
                className={style.full_width}
                min={0}
              />
              {renderError('line_limit')}
            </label>

            <label className={style.grid_label} htmlFor={'party_limit'}>
							Party Limit
              <NumericTextBox
                ref={form.register}
                form={form}
                name="party_limit"
                defaultValue={fields.party_limit}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('party_limit');
                }}
                className={style.full_width}
                min={1}
                max={fields.capacity}
              />
              {renderError('party_limit')}
            </label>

            <label className={style.grid_label} htmlFor={'wait_limit'}>
							Wait Timeout
              <NumericTextBox
                ref={form.register}
                form={form}
                name="wait_limit"
                defaultValue={fields.wait_limit}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('wait_limit');
                }}
                className={style.full_width}
                min={0}
              />
              {renderError('wait_limit')}
            </label>
            <label className={style.grid_label} htmlFor={'manual_add'}>
              Phone Required <br /> on Manual Add
              <Switch
                ref={form.register}
                form={form}
                name="phoneRequiredManual"
                label={'Phone Required on Manual Add'}
                defaultChecked={fields.phoneRequiredManual}
                value={fields.phoneRequiredManual}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('phoneRequiredManual');
                }}
              />
              {renderError('phoneRequired')}
            </label>
            <label className={style.grid_label} htmlFor={'name'}>
							Name Required
              <Switch
                ref={form.register}
                form={form}
                name="nameRequired"
                label={'Name Required'}
                defaultChecked={fields.nameRequired}
                value={fields.nameRequired}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('nameRequired');
                }}
              />
              {renderError('phoneRequired')}
            </label>

            <label className={style.grid_label} htmlFor={'phone'}>
							Phone Required
              <Switch
                ref={form.register}
                form={form}
                name="phoneRequired"
                label={'Phone Required'}
                defaultChecked={fields.phoneRequired}
                value={fields.phoneRequired}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('phoneRequired');
                }}
              />
              {renderError('phoneRequired')}
            </label>

            <label className={style.grid_label} htmlFor={'email'}>
							Email Required
              <Switch
                ref={form.register}
                form={form}
                name="emailRequired"
                label={'email Required'}
                defaultChecked={fields.emailRequired}
                value={fields.emailRequired}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('emailRequired');
                }}
              />
              {renderError('emailRequired')}
            </label>
            <label className={style.grid_label} htmlFor={'name'}>
              Covid Screening <br /> Required
              <Switch
                ref={form.register}
                form={form}
                name="covidScreenRequired"
                label={'Covid Screen Required'}
                defaultChecked={fields.covidScreenRequired}
                value={fields.covidScreenRequired}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('covidScreenRequired');
                }}
              />
              {renderError('covidScreenRequired')}
            </label>

            <label className={style.grid_label} htmlFor={'population'}>
							Current Population
              <NumericTextBox
                ref={form.register}
                form={form}
                name="population"
                defaultValue={fields.population}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('population');
                }}
                className={style.full_width}
                min={0}
              />
              {renderError('population')}
            </label>

            <label className={style.grid_label} htmlFor={'location_open_time'}>
							Location Open Time (**Using Eastern Time, not location time)
              <TimePicker
                ref={form.register}
                name="location_open_time"
                format={'hh:mm a'}
                form={form}
                defaultValue={fields.location_open_time}
                className={style.full_width}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('location_open_time');
                }}
              />
              {renderError('location_open_time')}
            </label>

            <label className={style.grid_label} htmlFor={'line_start_time'}>
							Line Start Time
              <TimePicker
                ref={form.register}
                name="line_start_time"
                format={'hh:mm a'}
                form={form}
                defaultValue={fields.line_start_time}
                className={style.full_width}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('line_start_time');
                }}
              />
              {renderError('line_start_time')}
            </label>

            <label className={style.grid_label} htmlFor={'line_end_time'}>
							Line End Time
              <TimePicker
                ref={form.register}
                name="line_end_time"
                format={'hh:mm a'}
                form={form}
                defaultValue={fields.line_end_time}
                className={style.full_width}
                onChange={() => {
                  setLocalDirty(true);
                  form.clearError('line_end_time');
                }}
              />
              {renderError('line_end_time')}
            </label>
            
            <label htmlFor={'content'} style={userRole !== 'client' ? null : { display: 'none' }}>
              Content
              <StringsForm
                name="content"
                form={form}
                style={style}
                fields={fields}
                onChange={() => {
                  setLocalDirty(true);
                }}
              />
              {renderError('content')}
            </label>

            <label htmlFor={'styles'} style={{ display: isDoorAdmin || userRole === 'client' ? 'none' : 'block' }}>
              Styles
              <InputWrapper
                component={<textarea />}
                name="styles"
                form={form}
                defaultValue={fields.styles}
                onChange={() => {
                  setLocalDirty(true);
                }}
                style={{ minHeight: '200px', width: '100%', padding: '5px' }}
              />
              {renderError('styles')}
            </label>
          </div>
        </div>

        {locationError ? (
          <div className={style.field_error} style={{ textAlign: 'left' }}>
            {locationError}
          </div>
        ) : null}

        {processing ? (
          <div style={{ width: '100%', textAlign: 'center', paddingTop: 100 }}>
            <Icon spin type="loading" style={{ fontSize: 20, color: '#002E6D' }} />
          </div>
        ) : null}

        <Button primary={true} disabled={processing}>
					Save Changes
        </Button>
        <Modal
          className={style.delete_user_modal}
          visible={displayDeleteLocation}
          centered={true}
          destroyOnClose={true}
          closable={true}
          maskClosable={true}
          okText={'Yes, Delete Location'}
          onOk={() => deleteLocation(fields.location_id)}
          cancelText={'No, Go Back'}
          onCancel={() =>  setDisplayDeleteLocation(false)}
        >
          <h4 style={{ textAlign: 'center', fontSize: '1rem', marginTop: '.5rem' }}>Please confirm the deletion of this location:<h4 style={{ textAlign: 'center', fontSize: '1rem', margin: 0, marginBottom: '1rem', marginTop: '1rem', color: '#ff6161' }}>{`${fields.name}`}</h4>This action cannot be undone.</h4>
        </Modal>
      </form>
    </div>
  );
}

EditLocationView.propTypes = {
};

export default EditLocationView;
