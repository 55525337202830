export default {
  'location_id': null,
  'brand_id': null,
  'name': null,
  'address1': null,
  'address2': null,
  'city': null,
  'state': null,
  'zip_code': null,
  'capacity': 80,
  'latitude': null,
  'longitude': null,
  'location_open_time': new Date(null, null, null, 6, 30, 0),
  'line_start_time': new Date(null, null, null, 6, 30, 0),
  'line_end_time': new Date(null, null, null, 21, 0, 0),
  'line_limit': 500,
  'population': 0,
  'waiting': 0,
  'next_ticket': 1,
  'content': null,
  'styles': null,
  'distance': null,
  'party_limit': null,
  'phoneRequired': false,
  'nameRequired': false,
  'wait_limit': null,
};
