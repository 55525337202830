import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .typeError('Email is required')
    .label('Email')
    .required(),
  // password: yup
  //   .string()
  //   .email()
  //   .typeError('Password is required')
  //   .label('Password')
  //   .required(),
  confirm_email: yup
    .string()
    .email()
    .typeError('Email confirmation is required')
    .label('Confirm Email')
    // .required()
    .oneOf([ yup.ref('email'), null ], 'emails must match'),
  user_role: yup
    .string()
    .typeError('User Role is required')
    .label('User Role')
    .required(),
  confirm_password: yup
    .string()
    // .email()
    .typeError('Password confirmation is required')
    .label('Confirm Password')
    // .required()
    .oneOf([ yup.ref('password'), null ], 'passwords must match'),
  // location_id: yup
  //   .string()
  //   .typeError('Location ID is required')
  //   .label('Location ID')
  //   .required(),
  // region_id: yup
  //   .string()
  //   .typeError('Region ID is required')
  //   .label('Region ID')
  //   .required(),
  // brand_id: yup
  //   .string()
  //   .typeError('Brand ID is required')
  //   .label('Brand ID')
  //   .required(),
});

export default validationSchema;
