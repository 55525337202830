import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';

import { signOut, clearLocation } from 'state/actions';

import createDispatchedActions from 'utils/createDispatchedActions';
import JolHeaderLogo from 'common/jolHeaderLogo';
import style from '../SignIn/_SignIn.module.scss';

function SignOutContainer(props) {
  /* redux actions, wrapped in dispatch */
  const actions = createDispatchedActions(signOut);
  const dispatch = useDispatch();

  let redirectTimeout;

  /* lifecycle methods */
  useEffect( // on mount
    () => {
      dispatch(clearLocation());
      actions.signOut();

      redirectTimeout = setTimeout(() => {
        props.history.replace('/sign-in');
      }, 5000);

      return () => {
        clearTimeout(redirectTimeout);
      };
    },
    []
  );

  return (
    <div className={style.container} >
      <div className={style.header}>
        {/* <JolHeaderLogo fill='black' /> */}
        <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
      </div>
      <p>Thanks for using Join Our Line!</p>

      <div className={style.submit_button}>
        <Button
          primary={true}
          style={{ width: '80%', marginLeft: '10%', backgroundColor: '#129A7F', border: 'none' }}
          onClick={() => { props.history.replace('/sign-in'); }}
        >
          Go Back to Sign In
        </Button>
      </div>
    </div>
  );
}

export default SignOutContainer;
