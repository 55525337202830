import React, { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Icon, Modal } from 'antd';
import isEmpty from 'lodash/isEmpty';

/* Components */
import InputWrapper from 'components/InputWrapper';
import style from './_EditUser.module.scss';

function EditUserView(props) {
  const {
    /* data */
    fields,
    setLocalDirty,
    newUser,
    history,
    userRoles,
    userError,
    createUserError,
    processing,
    loggedInUser,
    locationIdError,
    displayDeleteUser,
    /* utils */
    form,
    /* methods */
    onSubmit,
    renderError,
    setDisplayDeleteUser,
    deleteUser,
  } = props;
  
  // default values won't rerender on update -- need to set defaults from Firebase first
  if (isEmpty(fields)) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }
  const [ currentUserRole, setCurrentUserRole ] = useState(fields.user_role);

  function header() {
    if (newUser) {
      return (
        <h1 style={{ marginLeft: '1rem', color: '#129A7F' }}>Add New User</h1>
      );
    }

    return (
      <h1 style={{ marginLeft: '1rem', color: '#129A7F' }}>{fields.email}</h1>
    );
  }

  if (userError) {
    return (
      <div className={style.header_container}>
        <Button
          primary={false}
          onClick={() => history.push(`/admin/users`)}
        > Go Back
        </Button>
        <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
          <p>{userError}</p>
        </div>
      </div>
    );
  }


  return (
    <div className={style.container}>
      <div className={style.header_container}>
        <Button
          primary={false}
          onClick={() => history.push(`/admin/users`)}
        >
          Go Back
        </Button>
        {header()}
      </div>

      <form onSubmit={form.handleSubmit(onSubmit)} >
        <p style={loggedInUser === 'brand_manager'|| loggedInUser === 'regional_manager' ? null : { display: 'none' }}>If you are unsure what the Location/Region ID is for a certain location, check the table on the "Manage Locations" page. </p>
        <div className={style.formWrapper}>

          <div className={style.column}>
            <div className={style.grid_label}>
              <DropDownList
                ref={form.register}
                name="user_role"
                label='User Role'
                form={form}
                defaultValue={fields.user_role}
                data={userRoles}
                style={{ width: '100%' }}
                onChange={(e) => {
                  setLocalDirty(true);
                  setCurrentUserRole(e.target.value);
                }}
              />
              {renderError('user_role')}
            </div>

            <InputWrapper
              component={<Input />}
              name="email"
              label='Email'
              form={form}
              defaultValue={fields.email}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            {
              newUser
                ?  <InputWrapper
                  component={<Input />}
                  name="confirm_email"
                  label='Confirm Email'
                  form={form}
                  // defaultValue={fields.confirm_email}
                  onChange={() => {
                    setLocalDirty(true);
                  }}
                />
                : null
            }

            {
              newUser
                ?  <InputWrapper
                  component={<Input />}
                  name="password"
                  label='Password'
                  form={form}
                  defaultValue={fields.password}
                  onChange={() => {
                    setLocalDirty(true);
                  }}
                />
                : <InputWrapper
                  component={<Input />}
                  name="password"
                  label='Reset Password'
                  form={form}
                  defaultValue={fields.password}
                  onChange={() => {
                    setLocalDirty(true);
                  }}
                />
            }

            {
              newUser
                ?  <InputWrapper
                  component={<Input />}
                  name="confirm_password"
                  label='Confirm Password'
                  form={form}
                  // defaultValue={fields.confirm_password}
                  onChange={() => {
                    setLocalDirty(true);
                  }}
                />
                : null
            }

            <InputWrapper
              component={<Input />}
              name="location_id"
              label='Location ID'
              disabled={loggedInUser === 'store_manager' || currentUserRole === 'brand_manager' || currentUserRole === 'regional_manager'}
              form={form}
              defaultValue={fields.location_id}
              onChange={() => {
                setLocalDirty(true);
              }}
            />
            <p style={locationIdError ? { color: 'red' } : { display: 'none' }}>This Location Id is invalid, please reference the Manage Locations page or contact support.</p>

            <InputWrapper
              component={<Input />}
              name="region_id"
              label='Region ID'
              disabled={currentUserRole === 'brand_manager' || (loggedInUser !== 'brand_manager' && loggedInUser !== 'admin')}
              form={form}
              required={currentUserRole === 'regional_manager' || currentUserRole === 'store_manager' || currentUserRole === 'door_admin'}
              validationMessage={'Region is required for this user role. Reference the Manage Locations page if needed.'}
              defaultValue={fields.region_id}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

            <InputWrapper
              component={<Input />}
              name="brand_id"
              label='Brand ID'
              disabled={loggedInUser !== 'admin'}
              form={form}
              defaultValue={fields.brand_id}
              onChange={() => {
                setLocalDirty(true);
              }}
            />

          </div>

        </div>

        {
          createUserError
            ? <div className={style.field_error} style={{ textAlign: 'left' }}>{createUserError}</div>
            : null
        }

        {
          processing
            ? <div style={{ width: '100%', textAlign: 'center', paddingTop: 100 }}>
              <Icon
                spin
                type="loading"
                style={{ fontSize: 20, color: '#002E6D' }}
              />
            </div>
            : null
        }

        <Button
          primary={true}
          disabled={processing}
          style={{ backgroundColor: '#129A7F', border: 'none' }}
        >
          Save
        </Button>
      </form>
      <Button
        primary={true}
        disabled={processing}
        // enable line below if we want to disable deleting for users with same role
        // hidden={fields.user_role === loggedInUser}
        hidden={newUser}
        onClick={() => {
          console.log('delete user clicked');
          setDisplayDeleteUser(true);
        }}
        style={{ backgroundColor: '#ff6161', border: 'none', marginTop: '1rem', width: '120px' }}
      >
        Delete User
      </Button>
      <Modal
        className={style.delete_user_modal}
        visible={displayDeleteUser}
        centered={true}
        destroyOnClose={true}
        closable={true}
        maskClosable={true}
        okText={'Yes, Delete User'}
        onOk={() => deleteUser(fields.email)}
        cancelText={'No, Go Back'}
        onCancel={() =>  setDisplayDeleteUser(false)}
      >
        <h4 style={{ textAlign: 'center', fontSize: '1rem', marginTop: '.5rem' }}>Please confirm the deactivation of this user:<h4 style={{ textAlign: 'center', fontSize: '1rem', margin: 0, marginBottom: '1rem', marginTop: '1rem', color: '#ff6161' }}>{`${fields.email}`}</h4>This action cannot be undone.</h4>
      </Modal>
    </div>
  );
}

export default EditUserView;
