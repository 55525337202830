import types from '../types';

export function setSuccess(message) {
  return setNotification(types.SET_SUCCESS, message);
}

export function setError(message) {
  return setNotification(types.SET_ERROR, message);
}

export function setWarning(message) {
  return setNotification(types.SET_WARNING, message);
}

export function clearSuccess() {
  return clearNotification(types.CLEAR_SUCCESS);
}

export function clearError() {
  return clearNotification(types.CLEAR_ERROR);
}

export function clearWarning() {
  return clearNotification(types.CLEAR_WARNING);
}

function setNotification(type, message) {
  return {
    type,
    payload: message,
  };
}

function clearNotification(type) {
  return {
    type,
  };
}
