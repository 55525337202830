import React from 'react';
import { Link } from 'react-router-dom';
import style from './_Waiting.module.scss';
import JolHeaderLogo from 'common/jolHeaderLogo';

function WaitingView(props) {
  const {
    queuePlace,
    brandDetails,
    stringHelper,
    styleHelper,
    guestRemoved,
    handleSignOut,
    isDisabled,
    locationName,
  } = props;

  if (guestRemoved) {
    return (
      <p className={style.removedContainer} style={{ backgroundColor: styleHelper.createStyle('defaultLogoColor') }}>
        {
          brandDetails.logo ? 
            <img src={brandDetails.logo} style={{ maxWidth: '256px' }} />
            : 
            <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
        }
        <Link to={`/`} onClick={() => handleSignOut()} className={`ld_cursor_pointer`}>{stringHelper.createString('checkin-expired')}</Link>
      </p>
    );
  }
  
  if (isDisabled) {
    return (
      <div className={style.container}>
        <div className={style.logoContainer}>
          {/* <JolHeaderLogo fill={ styleHelper.createStyle('primaryColor') || styleHelper.createStyle('defaultLogoColor') } /> */}
          <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
        </div>
        <p>This location is no longer active.</p>
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.logoContainer} style={{ backgroundColor: styleHelper.createStyle('defaultLogoColor') }}>
        {
          brandDetails.logo ? 
            <img src={brandDetails.logo} style={{ maxWidth: '256px' }} />
            : 
            <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
        }
      </div>
      <div className={style.sub_container}>
        <p className={style.alert} style={{ backgroundColor: styleHelper.createStyle('waitingBackgroundColor'), color: styleHelper.createStyle('waitingFontColor') }}>
          <h4 style={{ marginBottom: 0 }}>{stringHelper.createString('waiting-instructions')}</h4><br/>
          <div className={style.subtext}>{stringHelper.createString('waiting-instructions-details')}</div>
        </p>
        <p className={style.place} style={{ borderColor: styleHelper.createStyle('primaryColor'), backgroundColor: styleHelper.createStyle('primaryColor') }}>{stringHelper.createString('waiting-place-in-line', queuePlace)}</p>
        <p className={style.subtext}>
          {stringHelper.createString('waiting-priority-disclaimer')}
        </p>

        {stringHelper.createDivWithString(
          { className: style.socialDistance },
          'waiting-social-distancing'
        )}
      </div>
    </div>
  );
}

export default WaitingView;
