import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { Icon, Collapse } from 'antd';
import { isMobile, isIOS, isAndroid, isWinPhone } from 'react-device-detect';
import moment from 'moment';

import JolHeaderLogo from 'common/jolHeaderLogo';
import style from './_Register.module.scss';

const { Panel } = Collapse;

function RegisterView(props) {
  const {
    locationList,
    goToJoin,
    coords,
    geolocationFailed,
    locationID,
    handleSetValue,
    useLocationID,
    permissionDenied,
    locationLookupError,
    panelBarExpanded,
    handlePanelBar,
    stringHelper,
    loading,
  } = props;

  function renderPermissionLanguage() {
    let message = stringHelper.createDivWithString({}, 'register-use-mobile');

    if (isMobile && isIOS) {
      message = stringHelper.createDivWithString({}, 'gps-mobile-ios');
    }

    if (isMobile && isAndroid) {
      message = stringHelper.createDivWithString({}, 'gps-mobile-android');
    }

    else if (isMobile && isWinPhone) {
      message = stringHelper.createDivWithString({}, 'gps-mobile-windows');
    }

    if (permissionDenied) {
      return (
        <div className={style.basicWrapper}>
          <p className={style.permissionPrompt}>
            {stringHelper.createDivWithString({}, 'gps-permission-denied')}
          </p>

          <Collapse
            style={{ margin: '10px 0', width: '100%' }}
            expandIconPosition={'right'}
          >
            <Panel
              header={<b>{stringHelper.createString('gps-settings-header')}</b>}
              onChange={() => { handlePanelBar(!panelBarExpanded); }}
            >
              <div>
                {message}
              </div>
            </Panel>
          </Collapse>

          <div className={style.basicWrapper} style={{ margin: '20px 0' }}>
            <span><b>{stringHelper.createString('register-location-by-id')}</b></span>
            {
              locationLookupError && !loading
                ?  <p className={style.error}>{stringHelper.createString('register-no-location-found')}</p>
                : <div style={{ height: '30px' }}></div>
            }
            <Input
              name={'locationID'}
              autoCapitalize={'none'}
              placeholder={stringHelper.createString('location-id')}
              value={locationID}
              onChange={(e) => handleSetValue({ value: e.value })}
            />

            <Button
              htmltype={'button'}
              style={{ width: '12.4em' }}
              primary={true}
              disabled={!locationID.length}
              onClick={() => { useLocationID(locationID); }}
            >
              {stringHelper.createString('find-location')}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <p>
          {stringHelper.createDivWithString({}, 'gps-error')}
        </p>
        <Button
          primary={true}
          onClick={() => { window.location.reload(true); }}
        >
          {stringHelper.createString('refresh-page')}
        </Button>
      </div>
    );
  }

  function renderContent() {
    if (!navigator.geolocation) {
      console.log('!navigator.geolocation');
      return (
        <div className={style.mainContent}>
          <p className={style.permissionPrompt}>
            {stringHelper.createString('gps-browser-error')}
          </p>
        </div>
      );
    }

    if (geolocationFailed) {
      console.log('geolocationFailed');

      return (
        <div className={style.mainContent}>
          {renderPermissionLanguage()}
        </div>
      );
    }

    if (!coords.lat) {
      console.log('!coords.lat');
      return (
        <div>
          <p className={style.permissionPrompt}>
            {stringHelper.createDivWithString({}, 'register-browser-permission')}
          </p>
          <div className={style.mainContent}>
            <p>{stringHelper.createString('register-finding-location')}</p>
            <Icon
              spin
              type="loading"
              style={{ fontSize: 14, color: '#004E36' }}
            />
          </div>
        </div>
      );
    }

    if (!locationList.length) {
      console.log('!locationList.length');
      return (
        <div>
          <p className={style.permissionPrompt}>
            {stringHelper.createDivWithString({}, 'register-browser-permission')}
          </p>
          {stringHelper.createDivWithString({ className: style.mainContent }, 'register-no-locations')}
        </div>

      );
    }

    console.log('else');
    return (
      <div className={style.mainContent}>
        <p>{stringHelper.createString('register-select-location')}</p>

        <div style={{ width: '100%' }}>
          {
            locationList.map((loc) => {
              if (loc.disabled) {
                return null;
              }
              const isLineDisabled = moment().isBefore(moment().set(loc.line_start_time));
              return (
                <div>
                  <Button
                    primary={true}
                    key={loc.location_id}
                    onClick={() => { goToJoin(loc); }}
                    style={{ width: '80%' }}
                    disabled={isLineDisabled}
                  >
                    <div style={ isLineDisabled ? { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } : { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {loc.name}
                    </div>
                  </Button>
                  {isLineDisabled ? 
                    <p style={{ fontSize: '.75rem', marginTop: 0, paddingTop: 0, color: '#000000' }}>{`This line is unavailable to join until ${moment(moment().set(loc.line_start_time)).format('h:mm A')}`}</p> 
                    :
                    null  
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }

  return (
    <div className={style.container}>
      {/* <div className={style.header}> */}
      {/* <JolHeaderLogo fill='black' /> */}
      {/* <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p> */}
      {/* </div> */}

      {renderContent()}
    </div>
  );
}

export default RegisterView;
