import ApiGroup from './ApiGroup';

export default class authAPI extends ApiGroup {
  constructor() {
    super({
      name: 'auth',
    });
  }

  // signin = (data) => (
  //   this.post({
  //     endpoint: `/signin`,
  //     data,
  //   })
  // );

  signin = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            token: 'JWT_test_token',
            credentials: {
              location_id: 1,
              user_role: 'admin',
              location_name: 'WFM Boulder',
              cut_off_time: '22:00:00',
              capacity: 6,
              timezone: 'America/Denver',
              // expiration_minutes: 15,
            },
          },
        });
      }, 100);
    });
  };

  // verify = () => (
  //   this.get('/verify')
  // );

  verify = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            credentials: {
              location_id: 1,
              user_role: 'admin',
              location_name: 'WFM Boulder',
              cut_off_time: '22:00:00',
              capacity: 6,
              timezone: 'America/Denver',
              // expiration_minutes: 15,
            },
          },
        });
      }, 100);
    });
  };
}
