

export default function createClickLink(address) {
  const anchor = document.createElement('a');
  anchor.href = address;
  anchor.target = `_blank`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
