import React, { useEffect } from 'react';

/* data management */
import LOADING_KEY from 'definitions/keys/loading.keys';
// import * as action from 'state/actions';
import { signOut, verifyAuth } from 'state/actions';
import { useSelector } from 'react-redux';
import createDispatchedActions from 'utils/createDispatchedActions';

/* components */
import DataLoading from 'components/DataLoading/DataLoading';

/**
 * AuthHawk
 * - watch authentication and verify if need ( on refresh/update )
 *
 * @param Resource{node}
 */
function AuthHawk(Resource) {
  const landingPagePath = '/sign-in';

  // console.log('authhawk resource', Resource);
  

  return function(props) {
    console.log('function props', props);
    
    /* redux actions, wrapped in "dispatch" */
    const actions = createDispatchedActions(signOut, verifyAuth);

    /* redux state */
    const state = useSelector(state => ({
      user: state.user,
      loading: state.loading[LOADING_KEY.auth],
      authenticated: state.authenticated,
    }));

    console.log('user from AUTHhawk state:', state.user.user_role);
    

    /* internal methods */
    const internalMethods = {

      /**
       * redirectToSignIn
       * - ... to specified page while tracking users desired path, so we may redirect there later
       *
       * @param fromPath{string}
       * @param toPath{string}
       */
      redirectToSignIn(fromPath, toPath = landingPagePath) {
        console.log(props.history.pathname);
        
        props.history.replace({
          pathname: toPath,
          state: {
            from: { pathname: fromPath },
          },
        });
      },

      /**
       * handleVerification
       * - redirect or sign out if not authenticated
       * - verify if authenticated without proof ( some piece of user data )
       *
       * @param shouldForceSignOut{boolean}
       */
      handleVerification(shouldForceSignOut = false) {
        if (state.loading) { // Just in case this method runs when loading (waiting for auth response). Does it ever?
          console.log('\nAuthHawk.handleVerification - loading');
          return;
        }

        // if (!state.authenticated) {
        //   console.log('\nAuthHawk.handleVerification - NOT authenticated');
        //   this.redirectToSignIn(props.location.pathname);
        //   actions.signOut();
        //   return;
        // }

        if (!state.user.user_id) {
          console.log('\nAuthHawk.handleVerification - run verification');
          actions.verifyAuth()
            .then(() => {
              console.log('\nAuthHawk.handleVerification - verified');
            })
            .catch(() => {
              console.log('caught error line 87 &&&&&');
              console.log('props location pathname:', props.location.pathname);
              
              this.redirectToSignIn(props.location.pathname);
            });
        }
      },

    };



    /* lifecycle methods */
    useEffect( // on update
      () => {
        console.log('\nAuthHawk.lifecycle - on UPDATE');
        internalMethods.handleVerification();
      },
      [
        props.location.pathname,
      ]
    );



    /* render */
    if (state.loading || !state.authenticated) {
      return <DataLoading message="Authenticating..." />;
    }

    return (
      <Resource
        {...props}
        user={state.user}
      />
    );
  };
}

export default AuthHawk;
