import { useDispatch } from 'react-redux';

export default function createDispatchedActions(...actions) {
  const dispatch = useDispatch();
  const dispatchedActions = {};

  for (const action of actions) {
    dispatchedActions[action.name] = (...args) => dispatch(action(...args));
  }
  return dispatchedActions;
}

// export function createDispatchedAction(dispatch, action) {
//   return (...args) => dispatch(action(...args));
// }
