// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._Locations-module__container___3mVJB{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-flex:1;flex:1 1}._Locations-module__container___3mVJB ._Locations-module__header_container___1ZanZ{display:-webkit-box;display:flex;padding:30px 0}._Locations-module__container___3mVJB ._Locations-module__header_container___1ZanZ h1{align-self:center;margin:0;text-align:center;font-size:18px}\n", ""]);
// Exports
exports.locals = {
	"container": "_Locations-module__container___3mVJB",
	"header_container": "_Locations-module__header_container___1ZanZ"
};
module.exports = exports;
