import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import { v4 as uuidv4 } from 'uuid';
import jstz from 'jstz';

/* Components */
import GuestListView from './GuestList.view';
import snapshotToArray from 'common/snapshotToArray';

const updatePopulation = window.firebase.functions().httpsCallable('updatePopulation');

function GuestListContainer(props) {
  /* redux state */
  const state = useSelector(state => ({
    user: state.user,
  }));
  
  const [ location, setLocation ] = useState({
    location_id: null,
    population: 0,
    waiting: 0,
    capacity: 0,
  });
  const [ guests, setGuests ] = useState([]);
  const [ peopleInQueue, setPeopleInQueue ] = useState([]);
  const [ waiting, setWaiting ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isSavingGuest, setIsSavingGuest ] = useState(false);
  const [ isAddingGuest, setIsAddingGuest ] = useState(false);
  const [ phone, setPhone ] = useState({
    value: '',
    error: '',
  });
  const [ name, setName ] = useState({
    value: '',
    error: '',
  });
  const [ notes, setNotes ] = useState({
    value: '',
    error: '',
  });
  const [ priority, setPriority ] = useState(false);
  const [ nextPosition, setNextPosition ] = useState(1);
  const [ partySize, setPartySize ] = useState(1);
  const [ buttonQueue, setButtonQueue ] = useState([]);
  const [ showVideo, setShowVideo ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ percentOccupied, setPercentOccupied ] = useState(0);
  const [ divColor, setDivColor ] = useState('');
  const [ noLocation, setNoLocation ] = useState(false);
  const [ buttonQueueTimeout, setButtonQueueTimeout ] = useState({});
  const [ currentPhase, setCurrentPhase ] = useState('');
  
  // let buttonQueueTimeout;

  useEffect(() => {
    if (!state.user.location_id && state.user.user_role === 'client') {
      return props.history.push(`/add-location`);
    } else if (!state.user.location_id && state.user.user_role === 'admin') {
      setNoLocation(true);
      return;
    }
    console.log('[buttonQueue]', 'buttonQueue updated', buttonQueue);
    if (buttonQueue.length && !isSaving) {
      console.log('[buttonQueue]', 'resetting timeout');
      // buttonQueueTimeout = setTimeout(processButtonQueue, 1000);
      setButtonQueueTimeout(setTimeout(processButtonQueue, 1000));
    }
  }, [ buttonQueue ]);

  const checkIfWatched = async () => {
    await window.firebase.firestore().doc(`metadata/${state.user.user_id}`)
      .get()
      .then(async (doc) => {
        // if no metadata, create doc and default dontShow to false
        if (!doc.exists) {
          await window.firebase.firestore().collection('metadata')
            .doc(state.user.user_id)
            .set({
              'dontShowAgain': false,
            });
          window.sessionStorage.setItem('inSameSession', true);
          setShowVideo(true);
          // if there is metadata, check if they have asked not to see video again
        } else {
          if (!doc.data().dontShowAgain && !window.sessionStorage.getItem('inSameSession')) {
            // display video
            window.sessionStorage.setItem('inSameSession', true);
            setShowVideo(true);
          } else {
            // do not display video
            setShowVideo(false);
          }
        }
      });
  };

  const handleDontShowClick = async () => {
    setShowVideo(false);
    await window.firebase.firestore().collection('metadata')
      .doc(state.user.user_id)
      .update({
        'dontShowAgain': true,
      });
  };

  const locationRef = window.firebase.firestore().doc(`locations/${state.user.location_id}`);
  const queueRef = window.firebase.firestore().collection(`queues/${state.user.location_id}/guests`)
    .orderBy('priority', 'desc')
    .orderBy('queue_id');

  const getPopPercentColor = (locationData) => {
    if (!locationData) {
      setDivColor('#3D9970');
      setPercentOccupied(0);
      return;
    }
    const percentFull = (locationData.population / locationData.capacity) * 100;
    setPercentOccupied(percentFull);
    if (percentFull <= 50) {
      setDivColor('#3D9970');
    } else if (percentFull > 50 && percentFull <= 65) {
      setDivColor('#FABC2A');
    } else if (percentFull > 65 && percentFull <= 80) {
      setDivColor('#FF8C00');
    } else if (percentFull > 80) {
      setDivColor('#F05365');
    }
  };

  useEffect(() => {
    setLoading(true);
    locationRef.get().then((snapshot) => {
      if (!snapshot.exists) {
        setNoLocation(true);
        return;
      }
    });
    if (state.user.user_role === 'client') {
      checkIfWatched();
    }
    const locationRefOff = locationRef.onSnapshot((snapshot) => {
      setLocation({
        ...location,
        ...snapshot.data(),
      });
      getPopPercentColor(snapshot.data());
      setLoading(false);
    });
    const queueRefOff = queueRef.onSnapshot((snapshot) => {
      const queue = snapshotToArray(snapshot);
      // start counting stuff
      const tempPeopleInQueue = [];
      let tempNextPosition = 1;
      const waitingToCheckIn = [];

      // loop queue so we can see what's up
      for (let i = 0; i < queue.length; i++) {
        const thisGuest = queue[i];

        // ignore guests who entered within cap margin
        if (!thisGuest.entered_within_margin) {
          // get line count
          if (!thisGuest.checked_in && !thisGuest.checked_out && !thisGuest.allowed) {
            tempPeopleInQueue.push(thisGuest);
          }
          // who needs to be allowed in?
          if (!thisGuest.checked_in && !thisGuest.checked_out && thisGuest.allowed) {
            waitingToCheckIn.push(thisGuest);
          }
          // find next queue_id
          if (tempNextPosition <= thisGuest.queue_id) {
            tempNextPosition = thisGuest.queue_id + 1;
          }
        }
      }

      // save counts to state
      setPeopleInQueue(tempPeopleInQueue);
      setGuests(waitingToCheckIn);
      setNextPosition(tempNextPosition);
      // do some business logic here
      console.log(`Queue data received.`);
      console.log(`People waiting: ${peopleInQueue}`);
      console.log(`Store Status: ${location.population - sumBy(waitingToCheckIn, (o) => { return o.party_size; })} in store out of ${location.capacity} allowed, ${sumBy(waitingToCheckIn, (o) => { return o.party_size; })} guests eligible`);
    });

    return () => {
      locationRefOff();
      queueRefOff();
    };
  }, []);


  if (!location) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  function processButtonQueue() {
    setCurrentPhase('processing button queue...');
    setIsSaving(true);
    const populationDelta = sum(buttonQueue) || 0;
    console.log('[buttonQueue]', 'processing', populationDelta);
    console.log('[buttonQueue]', 'buttonQueue', buttonQueue);
    if (populationDelta !== 0) {
      setCurrentPhase('calling firestore: updating population...');
      updatePopulation({
        locationID: state.user.location_id,
        capacity: location.capacity,
        amount: populationDelta,
      }).then(() => {
        setCurrentPhase('successfully updated population.');
        setIsSaving(false);
        setButtonQueue([]);
      })
        .catch((e) => {
          console.log('error updating population:', e);
          setCurrentPhase('error updating population!!');
          setIsSaving(false);
          setButtonQueue([]);
        });
    }
  }

  function removeFromLocation() {
    setCurrentPhase('function execution started...');
    if (location.population > 0) {
      // clearTimeout(buttonQueueTimeout);
      setButtonQueue(buttonQueue => [ ...buttonQueue, -1 ]);
      setButtonQueueTimeout(clearTimeout(buttonQueueTimeout));
    } else {
      console.log('cannot remove a guest, store empty');
    }
  }

  function addToLocation() {
    setCurrentPhase('function execution started...');
    if (location.population < location.capacity) {
      // clearTimeout(buttonQueueTimeout);
      setButtonQueue(buttonQueue => [ ...buttonQueue, 1 ]);
      setButtonQueueTimeout(clearTimeout(buttonQueueTimeout));
    } else {
      console.log('cannot add a guest, store full');
    }
  }

  function deleteGuest({ location_id, firebase_uid }) {
    window.firebase.firestore().collection('queues')
      .doc(location_id)
      .collection('guests')
      .doc(firebase_uid)
      .update({
        checked_out: new Date().getTime(),
      });
  }

  function checkInGuest({ location_id, firebase_uid }) {
    window.firebase.firestore().collection('queues')
      .doc(location_id)
      .collection('guests')
      .doc(firebase_uid)
      .update({
        checked_in: new Date().getTime(),
      });
  }

  function setAddingGuest(value) {
    if (value === false) {
      setPhone({
        ...phone,
        value: '',
      });
      setNotes({
        ...notes,
        value: '',
      });
      setName({
        ...name,
        value: '',
      });
      setPriority(false);
      setPartySize(1);
    }
    setIsAddingGuest(value);
  }

  function addGuestToLine(phone, name, notes, priority, party_size) {
    setIsSavingGuest(true);

    const locationRef = window.firebase.firestore().doc(`locations/${state.user.location_id}`);

    window.firebase.firestore().runTransaction(transaction => {
      return transaction.get(locationRef).then(async (doc) => {
        const thisLocation = doc.data();

        const thisGuest = {
          'firebase_uid': uuidv4(),
          'location_id': state.user.location_id,
          party_size,
          'joined': new Date().getTime(),
          'allowed': null,
          'checked_in': null,
          'checked_out': null,
          'queue_id': thisLocation.next_ticket || 0,
          'priority': priority ? 1 : 0,
          notes: notes.value || null,
          name: name.value || null,
          phone_number: phone.value || null,
          'tz': jstz.determine().name(),
          'wait_limit': location.wait_limit || 5,
        };

        await window.firebase.firestore().doc(`queues/${state.user.location_id}`)
          .set({
            location_id: state.user.location_id,
          });
        const guestRef = window.firebase.firestore().collection('queues')
          .doc(state.user.location_id)
          .collection('guests')
          .doc(thisGuest.firebase_uid);

        console.log('thisguest', thisGuest);

        transaction.set(guestRef, thisGuest);
        transaction.update(doc.ref, {
          next_ticket: window.firebase.firestore.FieldValue.increment(1),
        });
      });
    })
      .then(() => {
        setAddingGuest(false);
        setIsSavingGuest(false);
      })
      .catch((e) => {
        setAddingGuest(false);
        setIsSavingGuest(false);
        console.log('could not add guest to queue:', e);
      });
  }

  function handleSetPhone(newValues) {
    return setPhone({
      ...phone,
      ...newValues,
    });
  }

  function handleSetNotes(newValues) {
    return setNotes({
      ...notes,
      ...newValues,
    });
  }

  function handleSetName(newValues) {
    return setName({
      ...name,
      ...newValues,
    });
  }

  function handleSetPriority() {
    return setPriority(!priority);
  }

  function handleSetPriorityWithValue(value) {
    return setPriority(value);
  }

  function handleSetPartySize(func) {
    if (func === 'add') {
      setPartySize(partySize + 1);
    }
    if (func === 'subtract') {
      setPartySize(partySize - 1);
    }
  }

  return (
    <GuestListView
      location={location}
      guests={guests}
      user={state.user}
      addToLocation={addToLocation}
      loading={loading}
      removeFromLocation={removeFromLocation}
      buttonQueue={buttonQueue}
      isSaving={isSaving}
      populationChanging={!!buttonQueue.length}
      deleteGuest={deleteGuest}
      checkInGuest={checkInGuest}
      addGuestToLine={addGuestToLine}
      setAddingGuest={setAddingGuest}
      isAddingGuest={isAddingGuest}
      phone={phone}
      name={name}
      notes={notes}
      priority={priority}
      handleSetName={handleSetName}
      handleSetNotes={handleSetNotes}
      handleSetPhone={handleSetPhone}
      handleSetPriority={handleSetPriority}
      handleSetPriorityWithValue={handleSetPriorityWithValue}
      peopleInQueue={peopleInQueue}
      partySize={partySize}
      handleSetPartySize={handleSetPartySize}
      showWaiting={waiting}
      showWaitingSwitch={setWaiting}
      isSavingGuest={isSavingGuest}
      showVideo={showVideo}
      setShowVideo={setShowVideo}
      handleDontShowClick={handleDontShowClick}
      divColor={divColor}
      percentOccupied={percentOccupied}
      noLocation={noLocation}
      currentPhase={currentPhase}
    />
  );
}

export default GuestListContainer;
