import React from 'react';

// import DevTools from './DevTools';
import Main from './Main/Main';
import { Notification } from '../components/Notification';

function App() {
  return (
    <div className="app">

      <Main />

      <Notification />

      {/* <DevTools /> */}

    </div>
  );
}

export default App;
