import React, { useEffect } from 'react';
import HelpView from './Help.view';

function HelpContainer(props) {
  // useEffect(() => {
  // }, [  ]);


  return (
    <HelpView
    />
  );
}

export default HelpContainer;
