import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import AuthHawk from 'HOC/AuthHawk';

import {
  SignIn,
  SignOut,
  Error,
  Admin,
  Register,
  Join,
  Waiting,
  CheckIn,
  Privacy,
  SignUp,
  AddLocation,
  ClientSignOut,
  CovidScreenFailed,
} from './lazy_routes';

export default (
  <Switch>

    <Route
      exact
      path="/"
      component={Register}
    />

    <Route
      exact
      path={[ '/sign-in', '/signin' ]}
      component={SignIn}
    />

    <Route
      exact
      path={[ '/sign-up', '/signup' ]}
      component={SignUp}
    />

    <Route
      exact
      path={[ '/add-location/:user_id', '/add-location' ]}
      // component={AuthHawk(AddLocation)}
      component={AddLocation}
    />

    <Route
      exact
      path={[ '/sign-out', '/signout' ]}
      component={SignOut}
    />

    <Route
      exact
      path="/client_signout"
      component={ClientSignOut}
    />

    <Route
      path="/admin"
      component={AuthHawk(Admin)}
    />

    <Route
      exact
      path="/join/:location_id"
      component={Join}
    />

    <Route
      exact
      path="/waiting/:location_id/:guest_id"
      component={Waiting}
    />

    <Route
      exact
      path="/checkin/:location_id/:guest_id"
      component={CheckIn}
    />

    <Route
      exact
      path="/privacy"
      component={Privacy}
    />

    <Route
      exact
      path="/covid-fail"
      component={CovidScreenFailed}
    />

    <Route component={Error} />

  </Switch>
);
