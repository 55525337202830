import types from '../types';

const INITIAL_STATE = false;

const INITIAL_ACTION = {
  type: '',
  payload: '',
};

export default function(
  state = INITIAL_STATE,
  action = INITIAL_ACTION
) {
  switch (action.type) {
    case types.SET_AUTH:
      return true;

    case types.SET_UNAUTH:
      return false;

    default:
      return state;
  }
}
