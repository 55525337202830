import types from '../types';

const INITIAL_STATE = {
  content: {},
  styles: {},
  brandDetails: {
    content: {},
    styles: {},
  },
};

const INITIAL_ACTION = {
  type: '',
  payload: '',
};

export default function(
  state = INITIAL_STATE,
  action = INITIAL_ACTION
) {
  switch (action.type) {
    case types.SET_LOCATION:
      return {
        ...state,
        ...action.payload,
      };

    case types.CLEAR_LOCATION:
      return action.payload;

    default:
      return state;
  }
}
