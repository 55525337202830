import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';

/* Components */
import BrandsView from './Brands.view';
import snapshotToArray from 'common/snapshotToArray';

function BrandsContainer(props) {
  const [ brands, setBrands ] = useState([]);
  const brandsCollection = window.firebase.firestore().collection(`brands`);

  useEffect(() => {
    const unsubscribe = brandsCollection.onSnapshot((snapshot) => {
      setBrands(snapshotToArray(snapshot));
    });

    return () => {
      unsubscribe();
    };
  }, [  ]);

  /* container methods */
  const methods = {
    handleNavigate(param) {
      return props.history.push(`/admin/brands/${param}`);
    },

    handleCreateNewLocation() {
      props.history.push(`/admin/location`);
    },
  };

  return (
    <BrandsView
      brands={brands}
      handleNavigate={methods.handleNavigate}
      history={props.history}
    />
  );
}

export default BrandsContainer;
