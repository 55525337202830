import React from 'react';
import { Button } from '@progress/kendo-react-buttons';

const CustomButton = (props) => {
  const {
    hidden,
    size,
    disabled,
    type,
    className,
    onClick = () => {},
    title,
    style,
    primary,
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <Button
      className={className}
      htmltype={type}
      size={size}
      style={style}
      primary={primary}
      disabled={disabled}
      onClick={() => { onClick(); }}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
