import types from '../types';

const INITIAL_STATE = {
  user_id: '',
  location_id: '',
};

const INITIAL_ACTION = {
  type: '',
  payload: '',
};

export default function(
  state = INITIAL_STATE,
  action = INITIAL_ACTION
) {
  switch (action.type) {
    case types.SET_USER:
      return action.payload;

    default:
      return state;
  }
}
