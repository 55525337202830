import * as yup from 'yup';

const validationSchema = yup.object().shape({
  brand_id: yup
    .string()
    .min(4)
    .matches(/^(\d|\w)+$/, 'Must be lower case with no spaces or special characters')
    .trim()
    .lowercase()
    .strict()
    .typeError('Brand ID is required')
    .label('Brand ID')
    .required(),
  name: yup
    .string()
    .typeError('Brand Name is required')
    .label('Brand Name')
    .required(),
  // logo: yup
  //   .string()
  //   .url()
  //   .typeError('Logo must be a URL')
  //   .label('Logo'),
});

export default validationSchema;
