import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

/* data management */
import LOADING_KEY from 'definitions/keys/loading.keys';
import { signinAuth, verifyAuth } from 'state/actions';
import createDispatchedActions from 'utils/createDispatchedActions';
import validationSchema from './validationSchema';

/* components */
import SignInView from './SignIn.view';

/**
 * SignIn container
 * - manage form data
 * - handle initial authentication
 * - render dedicated view component
 */
function SignInContainer(props) {
  /* create dispatched actions */
  const actions = createDispatchedActions(signinAuth, verifyAuth);

  /* redux state */
  const {
    loading,
  } = useSelector(state => ({
    authenticated: state.authenticated,
    loading: state.loading[LOADING_KEY.auth],
  }));

  // /* local state */
  const [ redirectToReferrer, setRedirectToReferrer ] = useState(false);
  const [ emailMessage, setEmailMessage ] = useState('');
  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isClient, setIsClient ] = useState(false);
  const [ currentUserRole, setCurrentUserRole ] = useState('');
  const [ connectionError, setConnectionError ] = useState(false);

  /* local form state */
  const form = useForm({
    validationSchema,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  let unsubscribe;

  /* on mount */
  useEffect(() => {
    unsubscribe = window.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        actions.verifyAuth()
          .then(() => {
            setRedirectToReferrer(true);
          })
          .catch(error => {
            console.log('error', error);
          });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  /* container methods */
  const methods = {
    onSubmit(data) {
      unsubscribe && unsubscribe();
      actions.signinAuth(data)
        .then(async () => {
          setAlertVisible(false);
          setErrorMessage('');
          setConnectionError(false);
          await window.firebase.auth().currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              if (idTokenResult.claims.user_role === 'client') {
                setIsClient(true);
                setRedirectToReferrer(true);
              }
              setCurrentUserRole(idTokenResult.claims.user_role);
              setIsClient(false);
              setRedirectToReferrer(true);
            })
            .catch(e => console.log('error getting token:', e));
        })
        .catch(error => {
          console.log('error', error);
          handleLogInError(error);
        });
    },

    sendPasswordResetEmail(emailAddress) {
      window.firebase.auth().sendPasswordResetEmail(emailAddress)
        .then(() => {
          setEmailMessage(`Success! We sent an email to ${emailAddress}`);
        })
        .catch((error) => {
          setEmailMessage(`Oops! Something went wrong. ${error}`);

          setTimeout(() => {
            setEmailMessage('');
          }, 5000);
        });
    },
  };

  function handleLogInError(error) {
    if (error === 'Request timed out. Refresh and try again.') {
      setConnectionError(true);
    }
    setAlertVisible(true);
    setErrorMessage(error);
  }

  return (
    <SignInView
      form={form}
      isClient={isClient}
      redirectToReferrer={redirectToReferrer}
      formData={form.watch()}
      loading={loading}
      onSubmit={methods.onSubmit}
      emailMessage={emailMessage}
      alertVisible={alertVisible}
      errorMessage={errorMessage}
      connectionError={connectionError}
      sendPasswordResetEmail={methods.sendPasswordResetEmail}
      currentUserRole={currentUserRole}
    />
  );
}

export default SignInContainer;
