import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import mergeWith from 'lodash/mergeWith';
import { clearLocation } from 'state/actions';

/* components */
import StyleHelper from 'common/styleHelper';
import snapshotToArray from 'common/snapshotToArray';
import WaitingView from './Waiting.view';
import style from './_Waiting.module.scss';
import StringHelper from 'common/stringHelper';
import { Link } from 'react-router-dom';

const mergeNonNull = (objValue, srcValue) => srcValue === null ? objValue : undefined;

function WaitingContainer(props) {
  /* redux state */
  const state = useSelector(state => ({
    location: state.location,
  }));

  const dispatch = useDispatch();

  const [ location, setLocation ] = useState({
    location_id: 1,
    population: 0,
  });
  const [ peopleInFrontOfMe, setPeopleInFrontOfMe ] = useState(0);
  const [ guestRemoved, setGuestRemoved ] = useState(false);

  useEffect(() => {
    let userId;
    let guestRef;
    let guestRefOff;
    if (window.firebase.auth().currentUser) {
      userId = window.firebase.auth().currentUser.uid;
      guestRef = window.firebase.firestore().doc(`queues/${state.location.location_id}/guests/${userId}`);
      guestRefOff = guestRef.onSnapshot((doc) => {
        if (doc.exists) {
          if (doc.data().checked_out) {
            setGuestRemoved(true);
          }
        }
      });
    }

    if (!state.location.location_id) {
      return props.history.replace(`/`);
    }

    const locationRef = window.firebase.firestore().doc(`locations/${state.location.location_id}`);
    const queueRef = window.firebase.firestore().collection(`queues/${state.location.location_id}/guests`)
      .orderBy('priority', 'desc')
      .orderBy('queue_id');

    const locationRefOff = locationRef.onSnapshot((snapshot) => {
      setLocation({
        ...location,
        ...snapshot.data(),
      });
    });

    const queueRefOff = queueRef.onSnapshot((snapshot) => {
      const queue = snapshotToArray(snapshot);
      // start counting stuff
      let tempPeopleInQueue = 0;

      let tempPeopleInFrontOfMe = 0;
      let beenFound = false;
      
      // loop queue so we can see what's up
      for (let i = 0; i < queue.length; i++) {
        const thisGuest = queue[i];

        // get line count
        if (!thisGuest.checked_in && !thisGuest.checked_out) {
          tempPeopleInQueue += thisGuest.party_size;
        }

        // find my position
        if (window.firebase.auth().currentUser) {
          if (thisGuest.firebase_uid === window.firebase.auth().currentUser.uid) {
            beenFound = true;
  
            // if this guest is ready to check in, go to checkin page
            if (thisGuest.allowed) {
              return props.history.push(`/checkin/${state.location.location_id}/${thisGuest.firebase_uid}`);
            }
          } else if (!beenFound && !thisGuest.checked_in && !thisGuest.checked_out) {
            // if this guest isn't me, count them if they're in front of me
            tempPeopleInFrontOfMe += thisGuest.party_size;
          }
        }
      }

      // save counts to state
      setPeopleInFrontOfMe(tempPeopleInFrontOfMe + 1);

      // do some business logic here
      console.log(`Queue data received.`);
      console.log(`People waiting: ${tempPeopleInQueue}`);
    });

    return () => {
      locationRefOff();
      queueRefOff();
      guestRefOff();
    };
  }, [ ]);

  function createOrdinalNumber(n) {
    if (!n) return `next`;

    const digits = [ n % 10, n % 100 ];
    const ordinals = [ 'st', 'nd', 'rd', 'th' ];
    const oPattern = [ 1, 2, 3, 4 ];
    const tPattern = [ 11, 12, 13, 14, 15, 16, 17, 18, 19 ];
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? n + ordinals[digits[0] - 1]
      : n + ordinals[3];
  }
  
  function handleSignOut() {
    dispatch(clearLocation());
    return window.firebase.auth().signOut();
  }

  return (
    <div className={style.containerWrapper}>
      <WaitingView
        queuePlace={createOrdinalNumber(peopleInFrontOfMe)}
        brandDetails={mergeWith(state.location.brandDetails, { styles: state.location.styles }, mergeNonNull)}
        styleHelper={new StyleHelper(state.location.brandDetails.styles, state.location.styles)}
        stringHelper={new StringHelper('en', state.location.content, state.location.brandDetails.content)}
        guestRemoved={guestRemoved}
        handleSignOut={handleSignOut}
        isDisabled={location.disabled || false}
        locationName={location.name}
      /> 
      <div className={style.footerContent}>
        <div className={style.privacy_footer_content}>
          &copy; {moment().year()} Latitude Digital&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/privacy'} target={'_blank'} className={`ld_cursor_pointer`}>Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
}

export default WaitingContainer;
