import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

/* Components */
import EditUserView from './EditUser.view';
import validationSchema from './validationSchema';
import defaults from 'formDefaults/user.defaults';
import style from './_EditUser.module.scss';
import { StaticRouter } from 'react-router-dom';

const getUserByID = window.firebase.functions().httpsCallable('getUserByID');
const createUser = window.firebase.functions().httpsCallable('createUser');
const updateCustomUserClaims = window.firebase.functions().httpsCallable('updateCustomUserClaims');
const updateUser = window.firebase.functions().httpsCallable('updateUser');
const doDeleteUser = window.firebase.functions().httpsCallable('deleteUser');


function EditUserContainer(props) {
  const state = useSelector(state => ({
    user: state.user,
  }));

  const [ processing, setProcessing ] = useState(false);
  const [ newUser, setNewUser ] = useState(false);
  const [ userError, setUserError ] = useState('');
  const [ createUserError, setCreateUserError ] = useState('');
  const [ fields, setFields ] = useState({});
  const [ localDirty, setLocalDirty ] = useState(false);
  const [ locationIdError, setLocationIdError ] = useState(false);
  const [ displayDeleteUser, setDisplayDeleteUser ] = useState(false);
  /* local form state */
  const form = useForm({
    validationSchema,
  });

  /* data */
  const {
    match: {
      params: {
        user_id,
      },
    },
  } = props;

  let userRoles = [];
  switch (state.user.user_role) {
    case 'brand_manager': 
      userRoles = [ 'brand_manager', 'regional_manager', 'store_manager', 'door_admin' ];
      break;
    case 'regional_manager':
      userRoles = [ 'regional_manager', 'store_manager', 'door_admin' ];
      break;
    case 'store_manager':
      userRoles = [ 'store_manager', 'door_admin' ];
      break;
    case 'admin':
      userRoles = [ 'admin', 'client', 'brand_manager', 'regional_manager', 'store_manager', 'door_admin' ];
      break;
  }

  useEffect(() => {
    if (user_id === 'new' && state.user.user_role === 'admin') {
      setNewUser(true);

      return setFields({
        ...fields,
        ...defaults,
      });
    } else if (user_id === 'new' && state.user.user_role !== 'admin') {
      setNewUser(true);

      return setFields({
        ...fields,
        ...defaults,
        region_id: state.user.region_id || null,
        location_id: state.user.location_id || null,
        brand_id: state.user.brand_id || null,
        user_role: state.user.user_role,
      });
    }

    async function getFirebaseUser(uid) {
      await getUserByID(uid)
        .then((data) => {
          if (isEmpty(data) || !data.data) {
            setUserError(`Could not find a record for user_id: ${user_id}`);
          }

          const user = data.data;
          return setFields({
            ...fields,
            ...defaults,
            email: user.email,
            location_id: user.customClaims.location_id || null,
            user_role: user.customClaims.user_role,
            region_id: user.customClaims.region_id || null,
            brand_id: user.customClaims.brand_id || null,
          });
        })
        .catch(e => {
          setUserError(`Something went wrong: ${e}`);
          console.log('could not get Firebase user', e);
        });
    }
    getFirebaseUser(user_id);
  }, [ ]);

  /* container methods */
  const checkLocationId = async (enteredId) => {
    return await window.firebase.firestore().collection('locations')
      .get()
      .then((snapshot) => {
        const locationIds = [];
        for (const location of snapshot.docs) {
          locationIds.push(location.id);
        }
        return locationIds.includes(enteredId);
      });
  };

  const methods = {
    async onSubmit() {
      setProcessing(true);
      const formData = form.watch();
      const dirty = form.formState.dirty || localDirty;

      if (!dirty) {
        return props.history.replace('/admin/users');
      }
      // see if location ID is valid, display error if not 
      const validLocationId = await checkLocationId(formData.location_id);
      const usersWithLocations = [ 'store_manager', 'door_admin', 'client' ];
      if (!validLocationId && usersWithLocations.includes(formData.user_role)) {
        setLocationIdError(true);
        setProcessing(false);
        return;
      }
      const thisUser = {
        ...defaults,
        ...formData,
      };

      if (newUser) {
        return createNewUser(thisUser);
      }

      return updateFirebaseUser(user_id, thisUser);
    },

    renderError(name) {
      if (form.errors[name]) {
        return (
          <span className={style.field_error}>{form.errors[name].message}</span>
        );
      }
      return (
        <span className={style.field_error}>&nbsp;</span>
      );
    },
    async deleteUser(email) {
      console.log('deleteing user:', email);
      setProcessing(true);
      setDisplayDeleteUser(false);
      // call firestore delete user function, then redirect to manage users page
      return await doDeleteUser(email)
        .then((res) => {
          console.log('response from deleting user:', res);
          return props.history.replace('/admin/users');
        })
        .catch(e => console.log('error deleting user:', e));
    },
  };

  async function createNewUser(user) {
    await createUser(user)
      .then((newUser) => {
        if (newUser.data.errorInfo) {
          setProcessing(false);
          return setCreateUserError(`Could not create a new user: ${newUser.data.errorInfo.message}`);
        }
        console.log(`created user ${newUser.data.uid}`);
        return updateFirebaseCustomUserClaims(newUser.data.uid, user);
      });
  }

  async function updateFirebaseCustomUserClaims(uid, user) {
    await updateCustomUserClaims({ uid, ...user })
      .then((updatedUser) => {
        if (!updatedUser.data) {
          console.log(`updated user ${uid}`);
          setProcessing(false);
          return props.history.replace('/admin/users');
        }
        if (updatedUser.data.errorInfo) {
          setProcessing(false);
          return setCreateUserError(`Could not create a new user: ${updatedUser.data.errorInfo.message}`);
        }
      });
  }

  async function updateFirebaseUser(uid, user) {
    await updateCustomUserClaims({ uid, ...user })
      .then(() => {
        return updateUser({ uid, ...user });
      })
      .then((res) => {
        if (res.data.errorInfo) {
          setProcessing(false);
          return setCreateUserError(`Could not update password for user ${uid}: ${res.data.errorInfo.message}`);
        }
        console.log(`updated password ${uid}`);
        setProcessing(false);
        return props.history.replace('/admin/users');
      });
  }

  return (
    <EditUserView
      /* data */
      fields={fields}
      form={form}
      history={props.history}
      newUser={newUser}
      userRoles={userRoles}
      userError={userError}
      createUserError={createUserError}
      processing={processing}
      loggedInUser={state.user.user_role}
      locationIdError={locationIdError}
      displayDeleteUser={displayDeleteUser}
      /* methods */
      onSubmit={methods.onSubmit}
      renderError={methods.renderError}
      setLocalDirty={setLocalDirty}
      setDisplayDeleteUser={setDisplayDeleteUser}
      deleteUser={methods.deleteUser}
    />
  );
}

export default EditUserContainer;
