import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mergeWith from 'lodash/mergeWith';
import moment from 'moment';
import { Link } from 'react-router-dom';

/* components */
import StringHelper from 'common/stringHelper';
import StyleHelper from 'common/styleHelper';
import CheckInView from './CheckIn.view';
import { clearLocation } from 'state/actions';
import style from '../Waiting/_Waiting.module.scss';

const mergeNonNull = (objValue, srcValue) => srcValue === null ? objValue : undefined;

function CheckInContainer(props) {
  /* redux state */
  const state = useSelector(state => ({
    location: state.location,
  }));

  const dispatch = useDispatch();

  const [ guest, setGuest ] = useState({});

  useEffect(() => {
    if (!state.location.location_id) {
      return props.history.replace(`/`);
    }

    const queueRef = window.firebase.firestore().doc(`queues/${state.location.location_id}/guests/${window.firebase.auth().currentUser.uid}`);

    const queueRefOff = queueRef.onSnapshot((snapshot) => {
      setGuest({
        ...guest,
        ...snapshot.data(),
      });

      if (snapshot.data().checked_in) {
        // dispatch(clearLocation());
        window.firebase.auth().signOut();
      }
    });

    return () => {
      queueRefOff();
    };
  }, [ ]);

  function handleSignOut() {
    dispatch(clearLocation());
    return window.firebase.auth().signOut();
  }

  return (
    <div className={style.containerWrapper}>
      <CheckInView
        guest={guest}
        brandDetails={mergeWith(state.location.brandDetails, {
          styles: state.location.styles,
        }, mergeNonNull)}
        stringHelper={new StringHelper('en', state.location.content, state.location.brandDetails.content)}
        handleSignOut={handleSignOut}
        styleHelper={new StyleHelper(state.location.brandDetails.styles, state.location.styles)}
        location={state.location}
      />
      <div className={style.footerContent}>
        <div className={style.privacy_footer_content}>
          &copy; {moment().year()} Latitude Digital&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/privacy'} target={'_blank'} className={`ld_cursor_pointer`}>Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
}

export default CheckInContainer;
