import React from 'react';
import { Table, DatePicker, Modal, Icon } from 'antd';
import moment from 'moment-timezone';
import snapshotToArray from 'common/snapshotToArray';
// TODO: figure out why SCSS file not working, need to fix and refactor out of CSS.
// import style from './_Metrics.module.scss';
import './Metrics.css';
function MetricsView(props) {
  const {
    // data
    capacityNumbers,
    rawLocationsData,
    chosenDate,
    displayLocationTable,
    displayTodayTable,
    historicalData,
    chosenLocation,
    locations,
    loading,
    userRole,
    selectedLocation,
    // methods
    handleDateChange,
    toggleDisplayLocationTable,
    handleHistoricalTableOpen,
    getTodaysData,
    setDisplayTodayTable,
  } = props;

  console.log('SELCTEDLOCATION:', selectedLocation);

  const displayLocations = () => {
    // get selected location to the top of the list
    locations.forEach((location, i) => {
      if (location.location_id === selectedLocation) {
        locations.splice(i, 1);
        locations.unshift(location);
      }
    });
    return locations.map((location) => {
      return (
        <div className='location_card_container' style={ selectedLocation === location.location_id ? { border: '4px solid #129A7F', borderRadius: '4px' } : null}>
          <p className='location_name'>{location.name}</p>
          <button className='show_today_btn' onClick={() => {
            getTodaysData(location);
            setDisplayTodayTable(true);
          }}>See Today's Data</button>
          <button className='show_historical_btn' onClick={() => {
            handleHistoricalTableOpen(location.location_id, location.name);
          }}>See Historical Data</button>
        </div>
      );
    });
  };

  const disabledDate = (chosenDate) => {
    return chosenDate > moment();
  };

  const columns = [
    {
      title: 'Location Name',
      dataIndex: 'location_name',
      key: 'location_name',
    },
    {
      title: 'Current Count',
      dataIndex: 'current_count',
      key: 'current_count',
    },
    {
      title: 'Percent Occupied',
      dataIndex: 'percent_occupied',
      key: 'percent_occupied',
      render: percent_occupied => {
        let color = '';
        if (percent_occupied <= 50) {
          color = '#129A7F';
        } else if (percent_occupied > 50 && percent_occupied <= 65) {
          color = 'yellow';
        } else if (percent_occupied > 65 && percent_occupied <= 80) {
          color = 'orange';
        } else if (percent_occupied > 80) {
          color = 'red';
        }
        return <p style={{ color, fontWeight: 700 }}>{`${percent_occupied}%`}</p>;
      },
    },
    {
      title: 'Total Guests Entered Today',
      dataIndex: 'total_in_today',
      key: 'total_in_today',
    },
    {
      title: 'Guests Counted At Door',
      dataIndex: 'total_counted',
      key: 'total_counted',
    },
    {
      title: 'Guests Joined By Virtual Queue',
      dataIndex: 'total_joined',
      key: 'total_joined',
    },
    {
      title: 'Current Queue',
      dataIndex: 'current_queue',
      key: 'current_queue',
    },
  ];
  const historicalColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Total Guests Entered Today',
      dataIndex: 'total_in_today',
      key: 'total_in_today',
    },
    {
      title: 'Guests Counted At Door',
      dataIndex: 'total_counted',
      key: 'total_counted',
    },
    {
      title: 'Guests Joined By Virtual Queue',
      dataIndex: 'total_joined',
      key: 'total_joined',
    },
    // {
    //   title: 'Avg. Wait Time',
    //   dataIndex: 'avg_wait_time',
    //   key: 'avg_wait_time',
    // },
  ];

  const tableData = rawLocationsData.map((location) => {
    return {
      key: location.location_id,
      location_name: location.name,
      current_count: `${location.population}/${location.capacity}`,
      percent_occupied: Math.round((location.population / location.capacity) * 100),
      customers_in: location.capacity + Math.round(Math.random() * (50 - 10) + 10),
      // find people in queue who are allowed OR !allowed but not checked in or out, include party size
      current_queue: location.current_queue,
      total_in_today: ((location.totalCountedIn + location.totalJoinedIn) < location.population) ? location.population : (location.totalCountedIn + location.totalJoinedIn),
      total_counted: location.totalCountedIn,
      total_joined: location.totalJoinedIn,
    };
  });
  
  const historicalTableData = historicalData.map((day) => {
    return {
      key: day.day,
      date: day.day,
      total_in_today: day.totalInByQueue ? day.totalInByPlus + day.totalInByQueue : day.totalInByPlus,
      total_counted: day.totalInByPlus || 0,
      total_joined: day.totalInByQueue || 0,
      avg_wait_time: `Coming Soon!`,
    };
  });

  return (
    <main className='metric_container'>
      <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: 0, }}>Advanced Metrics</h1>
      {/* <section className='metric_circles_container'>
        <div className='capacity_circles'>
          <p className='capacity_text'>Locations Under Capacity:</p>
          <p className='capacity_numbers'>{`${capacityNumbers.underCapacity}/${locations.length}`}</p>
        </div>
        <div className='capacity_circles'>
          <p className='capacity_text'>Locations At Capacity:</p>
          <p className='capacity_numbers'>{`${capacityNumbers.atCapacity}/${locations.length}`}</p>
        </div>
      </section> */}
      <p style={{ textAlign: 'left', fontSize: '1rem', color: '#129A7F', fontWeight: '700' }}>{userRole === 'store_manager' ? 'Click on the buttons to view data about your location.' : 'Click on a row to see weekly data for that location.' }</p>
      {displayLocations()}
      <Modal
        className='table_modal'
        visible={displayLocationTable}
        width={'80%'}
        centered={true}
        // destroyOnClose={true}
        // closable={true}
        // maskClosable={true}
        okText={'Close'}
        onOk={() => toggleDisplayLocationTable(false)}
        cancelText={' '}
        onCancel={() =>  toggleDisplayLocationTable(false)}
      >
        { loading ? 
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Icon
              spin
              type="loading"
              style={{ fontSize: 100, color: '#002E6D' }}
            />
          </div> :
          <div>
            <h3 style={{ color: '#129A7F', fontWeight: '700', marginBottom: 0 }}>{chosenLocation}</h3>
            <h3 style={{ marginTop: 0 }}>{`Week of ${moment(chosenDate).startOf('week').format('dddd, MMM Do YYYY')}`}</h3>
            <p style={{ color: '#129A7F', fontWeight: 700 }}>Select a date to view the data for that week:</p>
            <DatePicker onChange={((event) => handleDateChange(event))} value={moment(chosenDate).startOf('week')} disabledDate={disabledDate} style={{ marginBottom: '1rem' }}/>
            <Table 
              dataSource={historicalTableData} columns={historicalColumns} style={{ border: 'none' }} />
          </div>
        }
      </Modal>
      <Modal
        className='table_modal'
        visible={displayTodayTable}
        width={'80%'}
        centered={true}
        // destroyOnClose={true}
        // closable={true}
        // maskClosable={true}
        okText={'Close'}
        onOk={() => setDisplayTodayTable(false)}
        cancelText={' '}
        onCancel={() =>  setDisplayTodayTable(false)}
      >
        {loading ? 
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Icon
              spin
              type="loading"
              style={{ fontSize: 100, color: '#002E6D' }}
            />
          </div> :
          <Table 
            dataSource={tableData} columns={columns} style={{ border: 'none' }} />
        }
      </Modal>
    </main>
  );
}

// MetricsView.propTypes = {
// };

// Table.prototype = React.Component.prototype;

export default MetricsView;
