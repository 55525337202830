import mergeWith from 'lodash/mergeWith';

const defaultState = {
  defaultLogoColor: '#000',
  primaryColor: '#129A7F',
  boxColor: '#28A745',
  boxColorPriority: '#ffc107',
  fontColor: '#fff',
  fontColorPriority: '#000',
  waitingBackgroundColor: '#F8F8F8',
  waitingFontColor: '#000000',
};

const mergeNonNull = (objValue, srcValue) => srcValue === null ? objValue : undefined;

class StyleHelper {
  constructor(location, brand) {
    this.styleValues = mergeWith(defaultState, brand, location, mergeNonNull);
  }

  createStyle = (key) => {
    let style = this.styleValues[key];

    if (!style) {
      style = null;
    }

    return style;
  }
}

export default StyleHelper;

