// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._EditLocation-module__field_error___1_ek8{display:block;color:red;margin-bottom:10px;text-align:right;font-size:14px}._EditLocation-module__container___1BdmM{width:100%;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}._EditLocation-module__container___1BdmM ._EditLocation-module__header_container___16wFa{display:-webkit-box;display:flex;padding:30px 0}._EditLocation-module__container___1BdmM ._EditLocation-module__header_container___16wFa h1{align-self:center;margin:0;text-align:center;font-size:18px}._EditLocation-module__container___1BdmM ._EditLocation-module__formWrapper___20siA{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;width:100%;-webkit-box-pack:justify;justify-content:space-between}@media (min-width: 800px){._EditLocation-module__container___1BdmM ._EditLocation-module__formWrapper___20siA{-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row}}._EditLocation-module__container___1BdmM ._EditLocation-module__formWrapper___20siA ._EditLocation-module__column___3Lukh{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;width:100%}@media (min-width: 800px){._EditLocation-module__container___1BdmM ._EditLocation-module__formWrapper___20siA ._EditLocation-module__column___3Lukh{width:48%}}._EditLocation-module__container___1BdmM ._EditLocation-module__formWrapper___20siA ._EditLocation-module__column___3Lukh ._EditLocation-module__grid_label___Y18Lx{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;font-size:11px}._EditLocation-module__container___1BdmM ._EditLocation-module__formWrapper___20siA ._EditLocation-module__column___3Lukh ._EditLocation-module__full_width___xxXwL{width:100%}\n", ""]);
// Exports
exports.locals = {
	"field_error": "_EditLocation-module__field_error___1_ek8",
	"container": "_EditLocation-module__container___1BdmM",
	"header_container": "_EditLocation-module__header_container___16wFa",
	"formWrapper": "_EditLocation-module__formWrapper___20siA",
	"column": "_EditLocation-module__column___3Lukh",
	"grid_label": "_EditLocation-module__grid_label___Y18Lx",
	"full_width": "_EditLocation-module__full_width___xxXwL"
};
module.exports = exports;
