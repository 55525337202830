import React, { useState } from 'react';
import { Layout } from 'antd';

/* components */
import AdminRouter, { navLinks }  from './_router';
import AdminHeader from './Admin.header';
import AdminSidebar from './Admin.sidebar';
import createClickLink from 'utils/createClickLink';
import style from './_Admin.module.scss';

/**
 * Admin container
 * - high level admin logic to be used throughout sub routes
 * - render routes with header, sidebar, footer, etc.
 */
function AdminContainer(props) {
  /* local state */
  const [ sidebarCollapsed, setSidebarCollapsed ] = useState(false);

  /* methods to share */
  const containerMethods = {

    getOpenKeys() {
      return [ props.location.pathname ];
    },

    onSelect({ key }) {
      const {
        history,
        location: {
          pathname,
        },
        user,
      } = props;

      if (pathname === key) return;

      if (key.indexOf('http') === 0) {
        return createClickLink(key);
      }

      if (key === '/admin/locations/:location_id') {
        return history.push(`/admin/locations/${user.location_id}`);
      }

      history.push(key);
    },

  };

  /* methods for internal use */
  const internalMethods = {
    parseNavLinks(links) {
      return links.map(_link => {
        const link = { ..._link };

        /* allow link to set it's own path */
        if (link.setPath) {
          link.setPath(props);
        } else {
          link.path = link.originalPath;
        }

        return link;
      }).filter(link => link);
    },

  };

  const navigationLinks = internalMethods.parseNavLinks(navLinks);

  return (
    <Layout hasSider className={style.container} style={{ minHeight: window.innerHeight, overflow: scroll }}>
      <AdminSidebar
        {...props}
        navLinks={navigationLinks}
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
        getOpenKeys={containerMethods.getOpenKeys}
        onSelect={containerMethods.onSelect}
      />

      <Layout hasSider={false}>

        <AdminHeader
          sidebarCollapsed={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />

        <Layout.Content className={style.content}>

          <AdminRouter
            {...props}
            navLinks={navigationLinks}
          />

        </Layout.Content>

      </Layout>

    </Layout>
  );
}

export default AdminContainer;
