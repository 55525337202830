import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import style from './_CovidScreenFailed.module.scss';
import JolHeaderLogo from 'common/jolHeaderLogo';
import StringHelper from '../../common/stringHelper';


function CovidScreenFailed() {
  const state = useSelector(state => ({
    location: state.location,
  }));
  const stringHelper = new StringHelper('en', state.location.content, state.location.brandDetails.content);
  return (
    <main className={style.covid_fail_main}>
      <div className={style.logoContainer}>
        {/* <JolHeaderLogo /> */}
        <p style={{ color: 'white', fontSize: '1.5rem', fontWeight: 700, marginBottom: '1rem' }}>JOIN OUR LINE</p>
      </div>
      <h1 className={style.header} >
        {stringHelper.createString('covid-failed-message')}
      </h1>
      <Link to='/' className={style.reset_btn}>Go Home</Link>
    </main>
  );
}

export default CovidScreenFailed;