import React from 'react';
import iPadImage from '../../assets/JOL_IpadStockPhoto.jpg';
import './BatteryTips.css';

function BatteryTipsView() {
  const renderList = (tips) => {
    return tips.map((tip) => {
      return (
        <li className='battery_tips_item' key={tip}>{tip}</li>
      );
    });
  };
  const tips = [
    'Lower screen brightness (Settings > Brightness)',
    'Turn off Bluetooth/AirDrop/Handoff (under settings)',
    'Make sure Auto-Lock (Settings > Display & Brightness) is set to 2 minutes',
    'Limit background applications/refreshing of background applications (Settings > General > Background App Refresh)',
    'Disable notifications (Settings > Notifications)',
    'Review battery usage information (Settings > Battery)',
    'Keep the iPad at room temperature. Extreme heat/cold can drain the battery',
  ];

  return (
    <main className='battery_tips_main'>
      <section className='battery_header'>
        <h1 className='battery_h1'>Tips For Improving Battery Life</h1>
        <p className='battery_desc'>Below are some tips and tricks to getting as much out of your iPad's battery as possible while using JOL:</p>
      </section>
      <ul className='battery_tips_list'>
        {renderList(tips)}
        <li className='battery_tips_item'>Consider an external battery like this: <a href='https://rebrand.ly/External_Battery'>https://rebrand.ly/External_Battery</a></li>
      </ul>
      <img className='stock_image' src={iPadImage} alt='stock image of iPad running JOL' />
    </main>
  );
}

export default BatteryTipsView;
