import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

/* components */
import USER_ROLES from 'definitions/keys/userRole.keys';
import RoleHawk from 'HOC/RoleHawk';

/* routes */
import {
  GuestList,
  Locations,
  Brands,
  Users,
  EditBrand,
  EditLocation,
  EditUser,
  NewLocation,
  CustomMessaging,
  ClientEditLocation,
  Help,
  Metrics,
  BatteryTips,
  MetricsSnapshot,
} from './lazy_routes';

/**
 * Admin Router
 * -
 *
 * @param props
 */

function AdminRouter(props) {
  function firstAvailableRoute() {
    return props.navLinks.find((navLink) => {
      return (
        !navLink.disabled
        && !navLink.hidden
      );
    });
  }

  function renderWithProps(RouteComponent) {
    return (_props) => (
      <RouteComponent
        {..._props}
        navLinks={props.navLinks}
      />
    );
  }

  function renderRoutes() {
    return props.navLinks.map((navLink) => {
      if (!navLink.Component) return null;
      if (navLink.disabled) return null;

      return (
        <Route
          key={`route-${navLink.path}`}
          exact
          path={navLink.path}
          render={renderWithProps(navLink.Component)}
        />
      );
    });
  }

  return (
    <Switch>
      {renderRoutes()}
      <Route
        render={() => {
          /* Redirect to first available route if Not Found/Available */
          return (
            <Redirect to={firstAvailableRoute().path}/>
          );
        }}
      />
    </Switch>
  );
}

export const navLinks = [
  {
    originalPath: '/admin/dashboard',
    iconLeft: 'dashboard',
    title: 'Dashboard',
    permittedUserRoles: [
      USER_ROLES.admin,
      USER_ROLES.door_admin,
      USER_ROLES.client,
      USER_ROLES.store_manager,
    ],
    Component: RoleHawk(
      GuestList,
      [
        USER_ROLES.admin,
        USER_ROLES.door_admin,
        USER_ROLES.client,
        USER_ROLES.store_manager,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/admin/metrics_snapshot',
    iconLeft: 'bar-chart',
    title: 'Metrics',
    permittedUserRoles: [
      USER_ROLES.admin,
      // USER_ROLES.door_admin,
      USER_ROLES.client,
      USER_ROLES.brand_manager,
      USER_ROLES.regional_manager,
      USER_ROLES.store_manager,
    ],
    Component: RoleHawk(
      MetricsSnapshot,
      [
        USER_ROLES.admin,
        // USER_ROLES.door_admin,
        USER_ROLES.client,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
        USER_ROLES.store_manager,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/admin/metrics/:location_id',
    hiddenFromUserRoles: [
      // USER_ROLES.admin,
      USER_ROLES.brand_manager,
      USER_ROLES.regional_manager,
      USER_ROLES.store_manager,
      USER_ROLES.client,
    ],
    iconLeft: 'bar-chart',
    title: 'Advanced Metrics',
    permittedUserRoles: [
      USER_ROLES.admin,
      // USER_ROLES.door_admin,
      USER_ROLES.client,
      USER_ROLES.brand_manager,
      USER_ROLES.regional_manager,
      USER_ROLES.store_manager,
    ],
    Component: RoleHawk(
      Metrics,
      [
        USER_ROLES.admin,
        // USER_ROLES.door_admin,
        USER_ROLES.client,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
        USER_ROLES.store_manager,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/admin/locations',
    iconLeft: 'table',
    title: 'Manage Locations',
    permittedUserRoles: [
      USER_ROLES.admin,
      USER_ROLES.brand_manager,
      USER_ROLES.regional_manager,
      // USER_ROLES.store_manager,
    ],
    Component: RoleHawk(
      Locations,
      [
        USER_ROLES.admin,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
        // USER_ROLES.store_manager,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/admin/brands',
    iconLeft: 'table',
    title: 'Brands',
    permittedUserRoles: [
      USER_ROLES.admin,
    ],
    Component: RoleHawk(
      Brands,
      [
        USER_ROLES.admin,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    hidden: true,
    originalPath: '/admin/brands/:brand_id',
    iconLeft: null,
    title: null,
    permittedUserRoles: [
      USER_ROLES.admin,
    ],
    Component: RoleHawk(
      EditBrand,
      [
        USER_ROLES.admin,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    hidden: false,
    hiddenFromUserRoles: [
      USER_ROLES.admin,
    ],
    originalPath: '/admin/locations/:location_id',
    iconLeft: 'edit',
    title: 'Edit Location',
    permittedUserRoles: [
      USER_ROLES.admin,
      // USER_ROLES.door_admin,
      // USER_ROLES.brand_manager,
      // USER_ROLES.regional_manager,
      // USER_ROLES.store_manager,
      // USER_ROLES.client,
    ],
    Component: RoleHawk(
      EditLocation,
      [
        USER_ROLES.admin,
        USER_ROLES.door_admin,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
        USER_ROLES.store_manager,
        // USER_ROLES.client,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    hidden: false,
    hiddenFromUserRoles: [
      USER_ROLES.admin,
    ],
    originalPath: '/admin/client_edit_location/:location_id',
    iconLeft: 'edit',
    title: 'Edit Location Details',
    permittedUserRoles: [
      USER_ROLES.client,
      // USER_ROLES.brand_manager,
      // USER_ROLES.regional_manager,
      USER_ROLES.door_admin,
      USER_ROLES.store_manager,
    ],
    Component: RoleHawk(
      ClientEditLocation,
      [
        USER_ROLES.client,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
        USER_ROLES.store_manager,
        USER_ROLES.door_admin,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    hidden: false,
    hiddenFromUserRoles: [
      USER_ROLES.admin,
    ],
    originalPath: '/admin/custom_messaging',
    iconLeft: 'setting',
    title: 'Optional Settings',
    permittedUserRoles: [
      USER_ROLES.client,
      USER_ROLES.store_manager,
      USER_ROLES.brand_manager,
      USER_ROLES.regional_manager,
    ],
    Component: RoleHawk(
      CustomMessaging,
      [
        USER_ROLES.client,
        USER_ROLES.store_manager,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/admin/users',
    iconLeft: 'user',
    title: 'Manage Users',
    permittedUserRoles: [
      USER_ROLES.admin,
      USER_ROLES.brand_manager,
      USER_ROLES.regional_manager,
      USER_ROLES.store_manager,
    ],
    Component: RoleHawk(
      Users,
      [
        USER_ROLES.admin,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
        USER_ROLES.store_manager,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    hidden: false,
    hiddenFromUserRoles: [
      USER_ROLES.admin,
    ],
    originalPath: '/admin/request_new_location',
    iconLeft: 'plus',
    title: 'Request New Location',
    permittedUserRoles: [
      USER_ROLES.client,
    ],
    Component: RoleHawk(
      NewLocation,
      [
        USER_ROLES.client,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    hidden: false,
    hiddenFromUserRoles: [
      USER_ROLES.admin,
    ],
    originalPath: '/admin/battery-tips',
    iconLeft: 'poweroff',
    title: 'Battery Life Tips',
    permittedUserRoles: [
      USER_ROLES.client,
      USER_ROLES.brand_manager,
      USER_ROLES.regional_manager,
      USER_ROLES.store_manager,
      USER_ROLES.door_admin,
    ],
    Component: RoleHawk(
      BatteryTips,
      [
        USER_ROLES.client,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
        USER_ROLES.store_manager,
        USER_ROLES.door_admin,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    hidden: false,
    hiddenFromUserRoles: [
      USER_ROLES.admin,
    ],
    originalPath: '/admin/help',
    iconLeft: 'question',
    title: 'Help',
    permittedUserRoles: [
      USER_ROLES.client,
      USER_ROLES.brand_manager,
      USER_ROLES.regional_manager,
      USER_ROLES.store_manager,
      USER_ROLES.door_admin,
    ],
    Component: RoleHawk(
      Help,
      [
        USER_ROLES.client,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
        USER_ROLES.store_manager,
        USER_ROLES.door_admin,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    hidden: true,
    originalPath: '/admin/users/:user_id',
    iconLeft: null,
    title: null,
    permittedUserRoles: [
      USER_ROLES.admin,
      USER_ROLES.brand_manager,
      USER_ROLES.regional_manager,
      USER_ROLES.store_manager,
    ],
    Component: RoleHawk(
      EditUser,
      [
        USER_ROLES.admin,
        USER_ROLES.brand_manager,
        USER_ROLES.regional_manager,
        USER_ROLES.store_manager,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
];


export default AdminRouter;
