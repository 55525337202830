import React from 'react';
import { Link } from 'react-router-dom';
import { DatePicker, Modal, Icon } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import moment from 'moment-timezone';
// TODO: figure out why SCSS file not working, need to fix and refactor out of CSS.
import './MetricsSnapshot.css';
const { MonthPicker } = DatePicker;
function MetricsSnapshotView(props) {
  const {
    // data
    gridData,
    dataState,
    handleDataStateChange,
    displayMonthlyData,
    toggleDisplayMonthlyData,
    loading,
    selectedDate,
    setSelectedDate,
    handleDateChange,
    selectedLocation,
    handleMonthlyDataClick,
    total,
  } = props;

  if (!gridData.data) {
    return (
      <p style={{ fontWeight: 700, color: 'black' }}>Loading....</p>
    );
  }

  const findCapacityNumbers = (locations) => {
    let underCapacity = 0;
    let atCapacity = 0;
    let activeToday = 0;
    for (const location of locations) {
      if (location.population < location.capacity) {
        underCapacity++;
        moment(location.populationUpdated).isSame(moment(), 'day') ? activeToday++ : null;
      } else if (location.population === location.capacity) {
        atCapacity++;
        moment(location.populationUpdated).isSame(moment(), 'day') ? activeToday++ : null;
      }
    }
    return {
      underCapacity,
      atCapacity,
      activeToday,
    };
  };

  const capacityNumbers = gridData.data ? findCapacityNumbers(gridData.data) : {};

  class cellWithBackGround extends React.Component {
    render() {
      const percentOccupied = (this.props.dataItem.population / this.props.dataItem.capacity) * 100;
      let backgroundColor = '';
      if (percentOccupied === 100) {
        backgroundColor = '#ED5144';
      } else if (percentOccupied < 100 && percentOccupied >= 75) {
        backgroundColor = '#F3AD47';
      } else if (percentOccupied < 75 && percentOccupied > 50) {
        backgroundColor = '#F3F94D';
      } else if (percentOccupied <= 50 && percentOccupied > 25) {
        backgroundColor = '#8ECD2D';
      } else if (percentOccupied <= 25 && percentOccupied > 0) {
        backgroundColor = '#A7DD95';
      } else {
        backgroundColor = '#FFFFFF';
      }
      const style = {
        backgroundColor,
      };

      return (
        <td style={style}>
          {this.props.dataItem.percent_occupied}%
        </td>
      );
    }
}

  class timestampCell extends React.Component {
    render() {
      const date = this.props.dataItem.populationUpdated;
      if (date === 0) {
        return (
          <td style={{ backgroundColor: '#F06D6D' }}>
            Not Yet Used
          </td>
        );
      }
      let displayText = '';
      if (moment(date).isSame(moment(), 'day')) {
        displayText = `Today at ${moment(date).format('h:mm A')}`;
      } else if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
        displayText = `Yesterday at ${moment(date).format('h:mm A')}`;
      } else {
        displayText = `${moment(date).format('MMM Do')} at ${moment(date).format('h:mm A')}`;
      }

      return (
        <td>
          {displayText}
        </td>
      );
    }
  }
  const disabledDate = (chosenDate) => {
    return chosenDate > moment();
  };
  class manageLocationCell extends React.Component {
    render() {
      return (
        <td style={{ textAlign: 'center' }}>
          <Link to={`/admin/client_edit_location/${this.props.dataItem.location_id}`} style={{ textAlign: 'center' }}><EditOutlined /></Link>
        </td>
      );
    }
  }
  class advancedMetricsCell extends React.Component {
    render() {
      return (
        <td style={{ textAlign: 'center' }}>
          <Link to={`/admin/metrics/${this.props.dataItem.location_id}`} style={{ textAlign: 'center', fontSize: '.8rem'  }}>View Advanced Metrics</Link>
        </td>
      );
    }
  }
  class monthlyMetricsCell extends React.Component {
    render() {
      return (
        <td style={{ textAlign: 'center' }}>
          <button style={{ border: 'none', backgroundColor: '#129A7F', padding: '.5rem', fontSize: '.8rem', color: 'white'  }} onClick={() => handleMonthlyDataClick(this.props.dataItem.location_id)}>View Monthly Data</button>
        </td>
      );
    }
  }
  class currentQueueCell extends React.Component {
    render() {
      if (this.props.dataItem.waiting === 0) {
        return (
          <td style={{ textAlign: 'center' }}>
            No Line
          </td>
        );
      }
      return (
        <td style={{ textAlign: 'center', backgroundColor: 'orange' }}>
          {this.props.dataItem.waiting}
        </td>
      );
    }
  }

  const handleClose = () => {
    toggleDisplayMonthlyData(false);
    setSelectedDate('');
  };

  return (
    <main className='metrics_snapshot_container'>
      <section>
        <Modal
          className='table_modal'
          visible={displayMonthlyData}
          width={'80%'}
          centered={true}
          okText={'Close'}
          onOk={() => handleClose()}
          cancelText={' '}
          onCancel={() =>  handleClose()}
        >
          <h3>{`Location ID: ${selectedLocation}`}</h3>
          <p>Select a date to view the guest count for that month at this location:</p>
          <h4 style={{ marginTop: 0 }}>{`Selected Month: ${selectedDate ? moment(selectedDate).format('MMMM') : ''} ${selectedDate ? moment(selectedDate ? selectedDate : moment()).format('YYYY') : ''}`}</h4>
          <MonthPicker picker='month' onChange={((event) => handleDateChange(event))} defaultValue={moment(selectedDate ? selectedDate : moment())} disabledDate={disabledDate} style={{ marginBottom: '1rem' }}/>
          { loading ? 
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Icon
                spin
                type="loading"
                style={{ fontSize: 100, color: '#002E6D' }}
              />
            </div> :
            <div>
              <h3 style={selectedDate ? { color: '#129A7F', fontWeight: 700 } : { display: 'none' }}>{`${total} Total Guests Entered`}</h3>
            </div>
          }
        </Modal>
        <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: 0, paddingBottom: 0 }}>Metrics</h1>
        <p style={{ color: '#129A7F', textAlign: 'center' }}>This screen will update in real-time, so there is no need to refresh.</p>
        <section className='metric_circles_container'>
          <div className='capacity_circles' style={{ margin: '1rem' }}>
            <p className='capacity_text'>Locations Under Capacity:</p>
            <p className='capacity_numbers'>{`${capacityNumbers.underCapacity}/${gridData.data.length}`}</p>
          </div>
          <div className='capacity_circles' style={{ margin: '1rem' }}>
            <p className='capacity_text'>Locations At Capacity:</p>
            <p className='capacity_numbers'>{`${capacityNumbers.atCapacity}/${gridData.data.length}`}</p>
          </div>
          <div className='capacity_circles' style={{ margin: '1rem' }}>
            <p className='capacity_text'>Locations Active Today</p>
            <p className='capacity_numbers'>{`${capacityNumbers.activeToday}/${gridData.data.length}`}</p>
          </div>
        </section>
        <p style={{ color: '#129A7F', fontWeight: 700 }}>Click on any column once to sort ascending, and click twice to sort that column descending.</p>
        <p style={{ color: '#129A7F', fontWeight: 700 }}>Click and drag a column header to reorder the table.</p>
        <Grid
          resizable={true}
          data={gridData.data}
          {...dataState}
          filterable
          sortable
          reorderable
          onDataStateChange={handleDataStateChange}
        >
          <Column
            field='name'
            title='Location'
          />
          <Column
            field='percent_occupied'
            title='Percent Occupied'
            cell={cellWithBackGround}
            filterable={false}
          />
          <Column
            field='population'
            filterable={false}
            title='Population'
          />
          <Column
            field='capacity'
            title='Capacity'
            filterable={false}
          />
          <Column
            field='waiting'
            title='Current Queue'
            filterable={false}
            cell={currentQueueCell}
          />
          <Column
            field='populationUpdated'
            title='Last Used'
            filterable={false}
            cell={timestampCell}
          />
          <Column
            title='Edit Location'
            filterable={false}
            cell={manageLocationCell}
          />
          <Column
            filterable={false}
            cell={monthlyMetricsCell}
          />
          <Column
            filterable={false}
            cell={advancedMetricsCell}
          />
        </Grid>
      </section>
    </main>
  );
}

export default MetricsSnapshotView;
