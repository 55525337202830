import types from '../types';

import LOADING_KEY from 'definitions/keys/loading.keys';

const INITIAL_STATE = {
  [LOADING_KEY.auth]: false,
};

const INITIAL_ACTION = { type: '' };

export default function(
  state = INITIAL_STATE,
  action = INITIAL_ACTION
) {
  switch (action.type) {
    case types.SET_LOADING:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
