import types from '../types';

const INITIAL_STATE = {
  success: false,
  error: false,
  warning: false,
};

const INITIAL_ACTION = { type: '', payload: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case types.SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case types.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.SET_WARNING:
      return {
        ...state,
        warning: action.payload,
      };

    case types.CLEAR_SUCCESS:
      return {
        ...state,
        success: false,
      };

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: false,
      };

    case types.CLEAR_WARNING:
      return {
        ...state,
        warning: false,
      };
  }
  return state;
}