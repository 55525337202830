/* eslint-disable id-length */
import React from 'react';

import InputWrapper from 'components/InputWrapper';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { Editor, EditorTools } from '@progress/kendo-react-editor';

import StringHelper from 'common/stringHelper';
import TextArea from 'antd/lib/input/TextArea';
import { Collapse, Tooltip, Icon } from 'antd';

const { Panel } = Collapse;

const {
  Bold, Italic, Underline,
  OrderedList, UnorderedList,
  FontSize, FormatBlock,
  Undo, Redo, Link, Unlink,
  InsertImage,
  AlignLeft, AlignCenter, AlignRight, AlignJustify,
} = EditorTools;

const StringsForm = (props) => {
  const {
    name,
    form,
    fields,
    onChange,
    style,
    hidden,
  } = props;

  let {
    customTextTrigger,
  } = props;

  if (hidden) {
    return null;
  }

  if (!customTextTrigger) {
    customTextTrigger = () => {
      return;
    };
  }

  const value = fields[name] || '{}';
  const strings = new StringHelper('en', value).getDefaultStrings();
  const tooltipText = {
    'join-time-error': 'The message a user will see if they attempt to join your line outside of the designated times.',
    'join-line-full-error': 'The message a user will see if they attempt to join your line while the line is full.',
    'join-party-waiting-singular': 'The text that appears to a guest when there is one party ahead of them.',
    'join-party-waiting-plural': 'The text that appears to a guest when there is more than one party ahead of them.',
    'join-name-input': "The label for the guest's 'name' input field.",
    'join-name-error': 'The error message that appears when a guest attempts to join the line but has not entered their name.',
    'join-phone-input': "The label for the guest's 'phone' input field.",
    'join-phone-error': 'The error message that appears when a guest attempts to join the line but has not entered their phone number.',
    'join-email-input': "The label for the guest's 'email' input field",
    'join-email-error': 'The error message that appears when a guest attempts to join the line but has not entered their email.',
    'join-guests-input': 'The label above the buttons where the guest selects how many people are in their party.',
    'join-button': 'The text inside the button to join the line.',
    'waiting-instructions': 'The text for the waiting instructions that appear to the guest once they have joined the line and are now waiting to be allowed in.',
    'waiting-instructions-details': 'The text for any additional waiting instructions.',
    'waiting-place-in-line': "The text displaying the guest's current place in line",
    'waiting-priority-disclaimer': "The text explaining to waiting guest's that priority guests may be moved ahead of them in the line.",
    'waiting-social-distancing': "The text displaying your location's social distancing guidelines and/or promotional messaging on the waiting screen.",
    'checkin-expired': 'The message that displays to a guest who joined the line but did not enter within the timeframe and were therefore expired.',
    'checkin-instructions-header': 'The header message that appears once a guest has been allowed in.',
    'checkin-instructions': 'The subtext instructions message that appears once a guest has been allowed in.',
    'checkin-expire-instructions': 'The text underneath the guestID image explaining how long they have until they are expired from the line.',
    'checkin-social-distancing': "The text displaying your location's social distancing guidelines and/or promotional messaging on the checkin screen.",
    'covid-failed-message': 'The message that appears to a guest if they answer YES to any of the CDC Covid-19 Symptom Screening questions.',
  };
  // const getValue = (v, key, language) => {
  //   const parsedValue = JSON.parse(v);
  //   console.log(`getValue`, parsedValue);
  //   if (parsedValue) {
  //     return parsedValue[key] ? parsedValue[key][language] : '';
  //   }
  //   return '';
  // };

  const setValue = (key, language, inputValue) => {
    const oldValue = form.getValues()[name];
    const parsedValue = JSON.parse(oldValue || '{}');
    const newValue = Object.assign({}, parsedValue);

    if (!newValue[key]) {
      newValue[key] = { key };
    }

    newValue[key][language] = inputValue;
    form.setValue(name, JSON.stringify(newValue));
    onChange && onChange();
  };

  // hidden input to hold the large JSON value
  return (
    <div className={style.full_width}>
      <InputWrapper
        style={{ display: 'none' }}
        component={<TextArea />}
        name={name}
        form={form}
        value={value}
        onChange={onChange}
      />

      {Object.keys(strings).map((key) => {
        const thisString = strings[key];
        switch (thisString.input) {
          case 'wysiwyg':
            return (
              <Collapse key={key} className={style.full_width} expandIconPosition={'right'} style={{ margin: '10px 0' }} onChange={() => customTextTrigger(key, thisString['en'])}>
                <Panel
                  header={<b>{thisString.name || thisString.key}</b>}
                  onChange={event => {
                    setValue(key, 'en', event.html);
                    onChange && onChange();
                  }}>
                  <Tooltip title={tooltipText[key]} trigger='click' placement='right'>
                    <p style={{ marginBottom: '2rem', padding: 0, textAlign: 'left' }}>Click for description</p>
                  </Tooltip>
                  <div style={{ display: 'flex', flexDirection: 'row', }}>
                    <Tooltip trigger='click' placement="left" title="If you would like to upload an image to display to your guests using JOL, complete the following steps: 1) Upload your image to a photo-hosting service. [ex: Google Photos or Imgur]. 2) Navigate to your uploaded image, then right-click the image and select 'Copy Image Address'. 3) Select the image button in the editor below. 4) Paste the link to your image in the dialogue box. 5) Adjust the width/height as needed and add text descriptions (optional). 6) Select the blue 'insert' button. Tip: to easily center your image, create a bullet point in the editor and upload your image next to the bullet. We also suggest a width/height of ~200px">
                      <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem' }}/>
                    </Tooltip>
                    <h4>Want to upload an image?</h4>
                  </div>
                  <InputWrapper
                    component={<Editor />}
                    name={`${key}-en`}
                    form={form}
                    value={fields[`${key}-en`]}
                    contentStyle={{ height: 200 }}
                    tools={[
                      [ Bold, Italic, Underline ],
                      [ OrderedList, UnorderedList ],
                      FontSize, FormatBlock,
                      InsertImage,
                      [ Undo, Redo ],
                      [ Link, Unlink ],
                      [ AlignLeft, AlignCenter, AlignRight, AlignJustify ],
                    ]}
                    onChange={event => {
                      setValue(key, 'en', event.html);
                      customTextTrigger(key, event.html);
                      onChange && onChange();
                    }}
                  />
                  <Button onClick={(event) => {
                    event.preventDefault();
                    form.setValue(`${key}-en`, thisString['en']);
                    customTextTrigger(key, thisString['en']);
                    setValue(key, 'en', thisString['en']);
                  }}>
                    Set to Default
                  </Button>
                </Panel>

              </Collapse>
            );
          default:
            return (
              <Collapse key={key} className={style.full_width} expandIconPosition={'right'} style={{ margin: '10px 0' }} onChange={() => customTextTrigger(key, thisString['en'])}>
                <Panel
                  header={<b>{thisString.name || thisString.key}</b>}
                  onChange={event => {
                    console.log('[StringsForm]', 'Editor onChange', key, event.html);
                    setValue(key, 'en', event.html);
                    onChange && onChange();
                  }}>
                  <Tooltip trigger='click' title={tooltipText[key]} placement='right'>
                    <Icon type="question-circle" style={{ color: 'blue', fontSize: '1.5rem', marginRight: '.5rem' }}/>
                  </Tooltip>
                  <InputWrapper
                    component={<Input />}
                    name={`${key}-en`}
                    label={`${key}-en`}
                    form={form}
                    defaultValue={fields[`${key}-en`]}
                    onChange={(event) => {
                      customTextTrigger(key, event.value);
                      setValue(key, 'en', event.value);
                      onChange && onChange();
                    }}
                    style={{ width: '100%' }}
                  />
                  <Button onClick={(event) => {
                    event.preventDefault();
                    customTextTrigger(key, thisString['en']);
                    form.setValue(`${key}-en`, thisString['en']);
                    setValue(key, 'en', thisString['en']);
                  }}>
                  Set to Default
                  </Button>
                </Panel>
              </Collapse>
            );
        }
      })}
    </div>
  );
};

export default StringsForm;
